import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { Response } from 'src/app/models/api.dto';
import { IncidentsService } from 'src/app/services/incidents.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { DriversService } from 'src/app/services/drivers.service';
import { CarRegistrationService } from 'src/app/services/car-registration.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.css']
})
export class CreateTicketComponent implements OnInit {

  //Ticket to be submitted
  ticket: { data } = { data: {} };

  //Create Ticket Details
  carRegistrationNumber;
  driverPunchId;
  hub;
  selectedIncidentCategorySubmit
  challanDetails;
  selectedMissingItems;
  selectedNotWorkingProperly;
  accidentLocation;
  accidentDate;
  accidentTime;

  driverList;
  carList;

  selectedIncidentCategory;

  hubList: SelectItem[];
  incidents;
  incidentCategories;
  missing_items;
  not_working_properly;

  selectedMissingOthersText;
  selectedMissingOthers;
  selectedNotWorkingProperlyOthersText;
  selectedNotWorkingProperlyOthers;

  constructor(private incidentService: IncidentsService,
    private carRegistrationService: CarRegistrationService,
    private ticketService: TicketsService,
    private messageService: MessageService,
    private driverService: DriversService,
    private cookieService: CookieService ) {

    this.hubList = [
      { label: 'AIHP', value: '1' },
      { label: 'ANSAL', value: '2' },
      { label: 'SPAZE', value: '3' },
      { label: 'FULCRUM', value: '4' }
    ];


    this.driverService.getAllDrivers().subscribe((res: Response<[]>) => {
      let drivers = res.response;
      this.driverList = [];
      for (let item in res.response) {
        if (drivers[item]['stop'] == undefined) {
          this.driverList.push({ label: drivers[item]['name'] + " - " + drivers[item]['punchId'], value: drivers[item]['name'] + " - " + drivers[item]['punchId'] });
        }
      }
    });

    this.carRegistrationService.getAllCars().subscribe((res: Response<[]>) => {
      let cars = res.response;
      this.carList = [];
      for (let item in res.response) {
        if (cars[item]['is_active'] == true) {
          this.carList.push({ label: cars[item]['vehicle_number'], value: cars[item]['vehicle_number'] });
        }
      }
    });

    this.incidentService.getAllIncidents().subscribe((res: Response<[]>) => {
      this.incidents = res.response;
      this.incidentCategories = [];
      for (let item in this.incidents) {
        this.incidentCategories.push({ label: this.incidents[item].displayName, value: this.incidents[item].category });
        if (this.incidents[item].category == "Missing_Items") {
          this.missing_items = [];
          for (let missingItem in this.incidents[item].metadata.missing_items_type) {
            this.missing_items.push({ label: this.incidents[item].metadata.missing_items_type[missingItem], value: this.incidents[item].metadata.missing_items_type[missingItem] });
          }
        }
        else if (this.incidents[item].category == "Not_Working_Properly") {
          this.not_working_properly = [];
          for (let notWorkingProperly in this.incidents[item].metadata.not_working_properly_list) {
            this.not_working_properly.push({ label: this.incidents[item].metadata.not_working_properly_list[notWorkingProperly], value: this.incidents[item].metadata.not_working_properly_list[notWorkingProperly] });
          }
        }
      }
    });
  }

  selectedCategory(data) {
    this.resetDynamic();
    this.selectedIncidentCategory = data.value;
  }

  submit() {
    if (this.carRegistrationNumber) {
      if (this.hub) {
        if (this.selectedIncidentCategorySubmit) {
          if (this.selectedIncidentCategory == 'Challan') {
            if (this.challanDetails) {
              this.saveInDB(this.carRegistrationNumber,
                this.hub,
                this.driverPunchId,
                this.selectedIncidentCategorySubmit,
                this.challanDetails,
                this.selectedMissingItems,
                this.selectedNotWorkingProperly,
                this.accidentLocation,
                this.accidentDate,
                this.accidentTime)
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter Challan Details' });
            }
          }
          else if (this.selectedIncidentCategory == 'Missing_Items') {
            if (this.selectedMissingItems) {
              this.saveInDB(this.carRegistrationNumber,
                this.hub,
                this.driverPunchId,
                this.selectedIncidentCategorySubmit,
                this.challanDetails,
                this.selectedMissingItems,
                this.selectedNotWorkingProperly,
                this.accidentLocation,
                this.accidentDate,
                this.accidentTime)
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select Missing Items' });
            }
          }
          else if (this.selectedIncidentCategory == 'Not_Working_Properly') {
            if (this.selectedNotWorkingProperly) {
              this.saveInDB(this.carRegistrationNumber,
                this.hub,
                this.driverPunchId,
                this.selectedIncidentCategorySubmit,
                this.challanDetails,
                this.selectedMissingItems,
                this.selectedNotWorkingProperly,
                this.accidentLocation,
                this.accidentDate,
                this.accidentTime)
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select Not Working Items' });
            }
          }
          else if (this.selectedIncidentCategory == 'Accident') {
            if (this.accidentLocation && this.accidentDate && this.accidentTime) {
              this.saveInDB(this.carRegistrationNumber,
                this.hub,
                this.driverPunchId,
                this.selectedIncidentCategorySubmit,
                this.challanDetails,
                this.selectedMissingItems,
                this.selectedNotWorkingProperly,
                this.accidentLocation,
                this.accidentDate,
                this.accidentTime)
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select all Accident Detials' });
            }

          }
        } else { this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select Incident Type' }); }
      }
      else { this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select Hub' }); }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter Car Registration Number' });
    }

  }

  saveInDB(carRegistrationNumber,
    hub,
    driverPunchId,
    selectedIncidentCategorySubmit,
    challanDetails,
    selectedMissingItems,
    selectedNotWorkingProperly,
    accidentLocation,
    accidentDate,
    accidentTime) {

    this.ticket.data.carRegistrationNumber = carRegistrationNumber.value;
    if (driverPunchId != undefined)
      this.ticket.data.driverPunchId = driverPunchId.value;
    this.ticket.data.hub = hub.value;
    this.ticket['category'] = selectedIncidentCategorySubmit.value;
    const userName = this.cookieService.get('userEmail');
    this.ticket['createdBy'] = userName;
    this.ticket['updatedBy'] = userName;

    if (selectedIncidentCategorySubmit.value == 'Challan') {
      this.ticket.data.description = 'Challan';
      this.ticket.data.challanDetails = challanDetails;
    }

    else if (selectedIncidentCategorySubmit.value == 'Missing_Items') {
      this.ticket.data.missingItems = [];
      this.ticket.data.description = 'Missing Items';
      for (let item in selectedMissingItems)
        this.ticket.data.missingItems.push(this.selectedMissingItems[item].value);
      if (this.selectedMissingOthersText) {
        this.ticket.data.missingItems.splice(this.ticket.data.missingItems.indexOf('Others'), 1);
        this.ticket.data.missingItems.push('Others(' + this.selectedMissingOthersText + ')');
      }
    }

    else if (selectedIncidentCategorySubmit.value == 'Not_Working_Properly') {
      this.ticket.data.notWorkingProperlyItems = [];
      this.ticket.data.description = 'Not Working Properly';
      for (let item in selectedNotWorkingProperly)
        this.ticket.data.notWorkingProperlyItems.push(this.selectedNotWorkingProperly[item].value);
      if (this.selectedNotWorkingProperlyOthersText) {
        this.ticket.data.notWorkingProperlyItems.splice(this.ticket.data.notWorkingProperlyItems.indexOf('Others'), 1);
        this.ticket.data.notWorkingProperlyItems.push('Others(' + this.selectedNotWorkingProperlyOthersText + ')');
      }
    }

    else if (selectedIncidentCategorySubmit.value == 'Accident') {
      this.ticket.data.description = 'Accident';
      this.ticket.data.accidentLocation = accidentLocation;
      this.ticket.data.accidentDate = accidentDate;
      this.ticket.data.accidentTime = accidentTime;
    }

    //Saving in DB
    this.ticketService.saveTicket(this.ticket).subscribe((res: Response<[]>) => {
      if (res.errorCode === 200) {
        this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Issue has been submitted' });
        this.reset();
      } else {
        console.log(res);
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: res.errorMessage });
      }
    });
  }

  reset() {
    this.carRegistrationNumber = '';
    this.driverPunchId = '';
    this.hub = '';
    this.selectedIncidentCategory = '';
    this.selectedIncidentCategorySubmit = '';
    this.challanDetails = '';
    this.selectedMissingItems = '';
    this.selectedNotWorkingProperly = '';
    this.accidentLocation = '';
    this.accidentDate = '';
    this.accidentTime = '';
    this.selectedMissingOthers = '';
    this.selectedMissingOthersText = '';
    this.selectedNotWorkingProperlyOthers = '';
    this.selectedNotWorkingProperlyOthersText = '';
  }

  resetDynamic() {
    this.challanDetails = '';
    this.selectedMissingItems = '';
    this.selectedNotWorkingProperly = '';
    this.accidentLocation = '';
    this.accidentDate = '';
    this.accidentTime = '';
    this.selectedMissingOthers = '';
    this.selectedMissingOthersText = '';
    this.selectedNotWorkingProperlyOthers = '';
    this.selectedNotWorkingProperlyOthersText = '';
  }

  missingItemsOthers(data) {
    if (data.value.map(value => value.value).indexOf('Others') != -1) {
      this.selectedMissingOthers = 'Others';
    }
    else {
      this.selectedMissingOthers = '';
    }
  }

  notWorkingProperlyOthers(data) {
    if (data.value.map(value => value.value).indexOf('Others') != -1) {
      this.selectedNotWorkingProperlyOthers = 'Others';
    }
    else {
      this.selectedNotWorkingProperlyOthers = '';
    }
  }

  ngOnInit() {
  }

}
