export const environment = {
  production: true,
  ticket: 'https://incident.tracking.blucgn.com/ticket-management-system',
  hub: 'https://hub.tracking.blucgn.com',
  axleWebUrl: 'axle.blucgn.com',
  hubWebUrl: 'hub.blucgn.com',
  altiliumWebUrl: 'altilium.blucgn.com',
  cookieURL: 'blucgn.com',
  cookieSuffix: 'Prod'
};
