<div class="tickets-container">
    <div class="panel-row">
        <div class="panel" style="width:24%">
            <div style="height: 10%;color: #fff; background: #272626; padding: 17px; text-align: center;">
                <b>Categories</b>
            </div>
            <div class="category" [ngClass]="{active: activeCategory===category['value']}"
                *ngFor="let category of incidentCategories;" (click)="selectTicketsByCategory(category['value'])">
                <div style="display: inline-flex;width: 100%;margin: 6px 0px;">
                    <span style="width: 95%;"><b>{{category['label']}}</b></span>
                    <span class="count"><b>{{ticketsByCategoriesActive[category['value']].length}}</b>
                    </span>
                </div>
            </div>
        </div>
        <div class="panel" style="width:74%">
            <div style="height: 10%;color: #fff;background: #272626;padding: 17px;text-align: center;width: 100%;">
                <b>Open Issues</b>
            </div>
            <p-accordion *ngFor="let ticket of ticketsActive;" [styleClass]="getStyle(ticket)">
                <p-accordionTab *ngIf="ticket.isActive">
                    <p-header class="issues" style="display: inline-flex;width: 100%;margin: 0px 0px;">
                        <span style="width: 15%;">{{ticket['ticketId']}}</span>
                        <span style="width: 12%;">{{ticket['data']['carRegistrationNumber']}}</span>
                        <span style="width: 22%;">{{ticket['data']['description']}}</span>
                        <div style="width: 23%;display: flex;margin:0px 3px"><span class="state"
                                style="margin:auto">{{ticket['currentStage']}}</span></div>
                        <span style="width: 18%;">{{ticket['createdAt'] | amTimeAgo: timezone}}</span>
                        <span style="width: 10%;">{{ticket['assignedToName']}}</span>
                    </p-header>
                    <div style="width: 100%;margin-top: 1%;">
                        <span *ngIf="ticket.data.carRegistrationNumber">Car Registration Number :
                            <b>{{ticket.data.carRegistrationNumber}}</b><br /></span>
                        <span *ngIf="ticket.data.driverPunchId">Driver Punch ID :
                            <b>{{ticket.data.driverPunchId}}</b><br /></span>
                        <span *ngIf="ticket.data.hub">Hub : <b>{{ticket.data.hub}}</b><br /></span>
                        <span *ngIf="ticket.category">Incident Category :
                            <b>{{ticket.data.description}}</b><br /></span>
                        <span *ngIf="ticket.data.missingItems">Missing Items :
                            <b>{{ticket.data.missingItems}}</b><br /></span>
                        <span *ngIf="ticket.data.notWorkingProperlyItems">Items Not Working Properly :
                            <b>{{ticket.data.notWorkingProperlyItems}}</b><br /></span>
                        <span *ngIf="ticket.data.accidentLocation">Accident Location :
                            <b>{{ticket.data.accidentLocation}}</b><br /></span>
                        <span *ngIf="ticket.data.accidentDate">Accident Date :
                            <b>{{ticket.data.accidentDate | date:'mediumDate'}}</b><br /></span>
                        <span *ngIf="ticket.data.accidentTime"> Accident Time :
                            <b>{{ticket.data.accidentTime | date:'shortTime'}}</b><br /></span>
                        <span *ngIf="ticket.data.paidAmount"> Paid Amount : <b>₹
                                {{ticket.data.paidAmount}}/-</b><br /></span>
                        <span *ngIf="ticket.category">Created By :
                            <b>{{ticket.createdBy}}</b><br /></span>
                        <span *ngIf="ticket.data.photoURL">
                            <p-lightbox [images]="ticket.photoDisplay"></p-lightbox>
                        </span>

                    </div>
                    <div style="width: 100%;text-align: right;">
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Challan Paid')">
                            <button pButton class=" ui-button-info" type="button" label="Challan Paid"
                                (click)="showPaidDialog(ticket._id,'Challan Paid',ticket)"></button>
                        </div>
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Received Car')">
                            <button pButton class=" ui-button-info" type="button" label="Received Car"
                                (click)="showPaidDialog(ticket._id,'Received Car',ticket)"></button>
                        </div>
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Received Car(Without Payment)')">
                            <button pButton class=" ui-button-info" type="button" label="Received Car(Without Payment)"
                                (click)="showConfirmDialog(ticket._id,'Received Car(Without Payment)',ticket)"></button>
                        </div>
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Car sent to Workshop')">
                            <button pButton class="ui-button-info" type="button" label="Car sent to Workshop"
                                (click)="showConfirmDialog(ticket._id,'Car sent to Workshop',ticket)"></button>
                        </div>
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Waiting for Payment')">
                            <button pButton class="ui-button-info" type="button" label="Waiting for Payment"
                                (click)="showConfirmDialog(ticket._id,'Waiting for Payment',ticket)"></button>
                        </div>
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Repaired at Hub')">
                            <button pButton class="ui-button-info" type="button" label="Repaired at Hub"
                                (click)="showPaidDialog(ticket._id,'Repaired at Hub',ticket)"></button>
                        </div>
                        <div style="padding-left: 2%;display: inline-block;"
                            *ngIf="stagesCondition(ticket['nextStage'],ticket['currentStage'],'Item Replaced')">
                            <button pButton class="ui-button-info" type="button" label="Item Replaced"
                                (click)="showPaidDialog(ticket._id,'Item Replaced',ticket)"></button>
                        </div>
                    </div>

                    <div style="width: 100%;margin-top: 1%; " *ngIf="ticket.history && ticket.history.length>0">
                        <p-table [value]="ticket.history">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Action Performed</th>
                                    <th>Updated Date</th>
                                    <th>Updated Time</th>
                                    <th>Amount</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-histoyItem>
                                <tr>
                                    <td>{{histoyItem.currentStage}}</td>
                                    <td>{{histoyItem.updatedAt | date:'mediumDate'}}</td>
                                    <td>{{histoyItem.updatedAt | date:'shortTime'}}</td>
                                    <td>{{histoyItem.data.paidAmount}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <div style="height: 10%;color: #fff; background: #272626;padding: 17px;text-align: center; width: 100%;">
                <b>Closed Issues</b>
            </div>
            <p-accordion *ngFor="let ticket of ticketsInactive;let i = index" [styleClass]="getStyle(ticket)">
                <p-accordionTab *ngIf="!ticket.isActive">
                    <p-header class="issues" style="display: inline-flex;width: 100%;margin: 0px 0px;">
                        <span style="width: 15%;">{{ticket['ticketId']}}</span>
                        <span style="width: 12%;">{{ticket['data']['carRegistrationNumber']}}</span>
                        <span style="width: 22%;">{{ticket['data']['description']}}</span>
                        <div style="width: 23%;display: flex;margin:0px 3px"><span class="state"
                                style="margin:auto">{{ticket['currentStage']}}</span></div>
                        <span style="width: 18%;">{{ticket['createdAt'] | amTimeAgo: timezone}}</span>
                        <span style="width: 10%;">{{ticket['assignedToName']}}</span>
                    </p-header>
                    <div style="width: 100%;margin-top: 1%;">
                        <span *ngIf="ticket.data.carRegistrationNumber">Car Registration Number :
                            <b>{{ticket.data.carRegistrationNumber}}</b><br /></span>
                        <span *ngIf="ticket.data.driverPunchId">Driver Punch ID :
                            <b>{{ticket.data.driverPunchId}}</b><br /></span>
                        <span *ngIf="ticket.data.hub">Hub : <b>{{ticket.data.hub}}</b><br /></span>
                        <span *ngIf="ticket.category">Incident Category :
                            <b>{{ticket.data.description}}</b><br /></span>
                        <span *ngIf="ticket.data.missingItems">Missing Items :
                            <b>{{ticket.data.missingItems}}</b><br /></span>
                        <span *ngIf="ticket.data.notWorkingProperlyItems">Items Not Working Properly :
                            <b>{{ticket.data.notWorkingProperlyItems}}</b><br /></span>
                        <span *ngIf="ticket.data.accidentLocation">Accident Location :
                            <b>{{ticket.data.accidentLocation}}</b><br /></span>
                        <span *ngIf="ticket.data.accidentDate">Accident Date :
                            <b>{{ticket.data.accidentDate | date:'mediumDate'}}</b><br /></span>
                        <span *ngIf="ticket.data.accidentTime"> Accident Time :
                            <b>{{ticket.data.accidentTime | date:'shortTime'}}</b><br /></span>
                        <span *ngIf="ticket.data.paidAmount"> Paid Amount : <b>
                                {{ticket.data.paidAmount}}/-</b><br /></span>
                        <span *ngIf="ticket.category">Created By :
                            <b>{{ticket.createdBy}}</b><br /></span>
                        <span *ngIf="ticket.data.photoURL">
                            <p-lightbox [images]="ticket.photoDisplay"></p-lightbox>
                        </span>
                    </div>
                    <div style="width: 100%;margin-top: 1%;" *ngIf="ticket.history && ticket.history.length>0">
                        <p-table [value]="ticket.history">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Action Performed</th>
                                    <th>Updated Date</th>
                                    <th>Updated Time</th>
                                    <th>Amount</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-histoyItem>
                                <tr>
                                    <td>{{histoyItem.currentStage}}</td>
                                    <td>{{histoyItem.updatedAt | date:'mediumDate'}}</td>
                                    <td>{{histoyItem.updatedAt | date:'shortTime'}}</td>
                                    <td>{{histoyItem.data.paidAmount}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{maxWidth:'30%',maxHeight:'30%'}" header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast [style]="{marginTop: '40px'}"></p-toast>

<p-dialog header="Enter Amount Paid" [(visible)]="displayPaid" [modal]="true" [responsive]="true"
    [style]="{maxWidth:'30%',maxHeight:'12%'}" [minY]="70" [baseZIndex]="10000">
    <input type="number" pInputText [(ngModel)]="paidAmount" placeholder="Amount" class="inputField" required>
    <p-footer>
        <button pButton class="ui-button-info" type="button" label="Paid" (click)="submitStagePaid()"></button>
    </p-footer>
</p-dialog>