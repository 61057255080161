
    <div
      [class]="config.className ? config.className : 'dynamicForm dateTime'"
      [formGroup]="group"
    >
      <label [for]="config.htmlId" *ngIf="config.label"
        >{{ config.label }}
        <span
          *ngIf="config.validations && config.validations.required"
          class="required_field"
          >*</span
        >
      </label>
      <ng-container *ngIf="config.htmlType === 'datetime'">
        <p-calendar
          [formControlName]="config.name"
          hourFormat="12"
          [showTime]="true"
          [showIcon]="true"
          [maxDate]="maxDate"
          [inputId]="config.htmlId"
          appendTo="body"
        ></p-calendar>
      </ng-container>
      <ng-container *ngIf="config.htmlType === 'date'">
        <p-calendar
          [formControlName]="config.name"
          [showIcon]="true"
          [maxDate]="maxDate"
          [inputId]="config.htmlId"
          appendTo="body"
        ></p-calendar>
      </ng-container>
      <ng-container *ngIf="config.htmlType === 'time'">
        <p-calendar
          [formControlName]="config.name"
          hourFormat="12"
          [timeOnly]="true"
          [showIcon]="true"
          [inputId]="config.htmlId"
          appendTo="body"
        ></p-calendar>
      </ng-container>
    </div>
  