import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  DoCheck,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { FieldConfig } from "../model/field-config.interface";
@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  styleUrls: ["dynamic-form.component.css"],
  template: `
    <form
      class="dynamic-form"
      [formGroup]="form"
      (submit)="handleSubmit($event)"
    >
      <div *ngFor="let field of config" class="mt_1">
        <!--
        <div
          *ngFor="let field of config;"
          [pDraggable]="areWeUpForUpdate ? 'dd': null"
          [pDroppable]="areWeUpForUpdate ? 'dd': null"
          (onDragEnd) = "onDropFieldElementHandler($event)"
        >
       -->
        <ng-container dynamicField [config]="field" [group]="form">
        </ng-container>
        <ng-container *ngIf="areWeUpForUpdate">
          <button (click)="updateFieldElementHandler(field)">Update</button>
          <button (click)="deleteFieldElementHandler(field)">Delete</button>
        </ng-container>
      </div>
    </form>
  `,
})
export class DynamicFormComponent implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];
  @Input()
  areWeUpForUpdate = false;
  @Output()
  updateElementHandler: EventEmitter<any> = new EventEmitter<any>();
  updateFieldElementHandler(fieldConfig) {
    this.updateElementHandler.emit(fieldConfig);
  }
  @Output()
  deleteElementHandler: EventEmitter<any> = new EventEmitter<any>();
  deleteFieldElementHandler(fieldConfig) {
    this.deleteElementHandler.emit(fieldConfig);
  }
  @Output()
  dropElementHandler: EventEmitter<any> = new EventEmitter<any>();
  onDropFieldElementHandler(fieldConfig) {
    console.log("onDropFieldElementHandler", fieldConfig);
    this.dropElementHandler.emit(fieldConfig);
  }
  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  get controls() {
    return this.config.filter(({ type }) => type !== "button");
  }
  get changes() {
    return this.form.valueChanges;
  }
  get valid() {
    return this.form.valid;
  }
  get value() {
    return this.form.value;
  }
  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    this.form = this.createGroup();
    this.form.valueChanges.subscribe((value) => {});
  }
  ngOnChanges() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item) => item.name);
      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));
      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.form.addControl(name, this.createControl(config));
        });
    }
  }
  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach((control) =>
      group.addControl(control.name, this.createControl(control))
    );
    return group;
  }
  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }
  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.value);
  }
  setDisabled(name: string, disable: boolean) {
    setTimeout(() => {
      if (this.form.controls[name]) {
        const method = disable ? "disable" : "enable";
        this.form.controls[name][method]();
        return;
      }
      this.config = this.config.map((item) => {
        if (item.name === name) {
          item.disabled = disable;
        }
        return item;
      });
    }, 0);
  }
  setValue(name: string, value: any) {
    setTimeout(() => {
      this.form.controls[name].setValue(value, { emitEvent: true });
    }, 0);
  }
}
