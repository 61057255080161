import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ServiceTicketService {
    constructor(http) {
        this.http = http;
    }
    //get service ticket config
    getAllServiceTicketConfig() {
        return this.http.get(Urls.APP_URL + '/api/v1/serviceTicket/config/all', { headers: Headers.headers });
    }
    //get service ticket config
    updateServiceTicketConfiguration(updatedData) {
        return this.http.put(Urls.APP_URL + '/api/v1/serviceTicket/config/update', updatedData, { headers: Headers.headers });
    }
    //create new  service ticket config
    createServiceTicketConfiguration(updatedData) {
        return this.http.post(Urls.APP_URL + '/api/v1/serviceTicket/config/create', updatedData, { headers: Headers.headers });
    }
    //delete service ticket config
    deleteServiceTicketConfiguration(id) {
        return this.http.delete(Urls.APP_URL + '/api/v1/serviceTicket/config/delete/' + id, { headers: Headers.headers });
    }
    //get service ticket calander events
    getServiceTicketEvents(date) {
        return this.http.get(Urls.APP_URL + '/api/v1/serviceTicket/config/events/' + date, { headers: Headers.headers });
    }
    //get service ticket calander events For Driver TMS
    getServiceTicketEventsForDriverTMS(date) {
        return this.http.get(Urls.APP_URL + '/api/v1/serviceTicketForDriverTMS/config/events/' + date, { headers: Headers.headers });
    }
}
ServiceTicketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceTicketService_Factory() { return new ServiceTicketService(i0.ɵɵinject(i1.HttpClient)); }, token: ServiceTicketService, providedIn: "root" });
