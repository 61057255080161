<div class="box" *ngIf="!isDetailViewVisible">
    <!-- <button (click)="resetData()">reset</button> -->
    <div class="spinner-wrapper" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>

  <div class="data-wrapper">
    <div class="header">
        <div>
            <div class="header-title font">Rider Support</div>
            <div class="header-count font">{{ showRefundStatus ? 'Open' : 'Closed' }} Tickets : {{queryCount}}</div>
        </div>
        <div class="header-items">
            <div class="flex">
                <span class="flex">
                    <i class="fa fa-search"></i>
                    <input id="float-input" [(ngModel)]="search" pInputText placeholder="{{searchPlaceHolder}}"
                        (input)="searchInput()" /></span>
                <p-dropdown class="drop" [options]="searchDropdown" optionLabel="name"
                    (onChange)="onSelectSearchType($event.value)" [(ngModel)]="selectedSearchType"></p-dropdown>
            </div>
            <div>
                <span class="flex">
                    <i class="fa fa-filter"></i>
                    <p-dropdown class="ticketType" [options]="ticketTypeDropdown" optionLabel="name"
                        (onChange)="onSelectTicketType($event.value)" [(ngModel)]="ticketType"></p-dropdown>
                </span>
            </div>

            <div>
                <p-calendar class="calendar" selectionMode="range" placeholder="Select Dates" appendTo="body"
                    [(ngModel)]="date" (onSelect)="dateSelectClickHandler()" [showIcon]="true"
                    [maxDate]="today"></p-calendar>
            </div>

            <div *ngIf="resetActive" (click)="resetData()">
                <button type="text" class="csv-button">
                    <ng-container>
                        <span class="gap">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M7.79518 5.88096L11.5 1.5H3.41422L7.79518 5.88096ZM7.50001 8.41421L5.50001 6.41421V9.5L7.50001 10.5V8.41421Z"
                                    fill="#D8D8D8" />
                                <path d="M1 1L10.5 11" stroke="#D8D8D8" stroke-linecap="round" />
                            </svg>Reset all Filters</span>
                    </ng-container>

                </button>
            </div>
            <div *ngIf="resetActive" style="border: 1px solid #999;"></div>
            <div>
                <button (click)="sendCSV()" type="text" class="csv-button">
                    <ng-container *ngIf="showCsvText">
                        <span class="gap">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i> Send as CSV</span>
                    </ng-container>
                    <ng-container *ngIf="showCsvLoader">
                        <span class="gap">
                            <div class="spinner-border" style="height: 13px; width: 13px; color: black;" role="status">
                            </div>
                            <span>Loading</span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="showCsvSuccess">
                        <span class="gap ">
                            <i class="fa fa-check-circle"
                                style="color: green; background: #353434; font-size: 13px;"></i>
                            <span>Sent to email</span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="showCsvFailed">
                        <span class="gap ">
                            <i class="fa fa-times-circle"
                                style="color: #ff3b30; background: #353434; font-size: 14px;"></i>
                            <span>Technical error</span>
                        </span>

                    </ng-container>
                </button>
            </div>
        </div>
    </div>
    <div class="table">
        <p-table #dt1 [value]="riderSupportData" [scrollable]="true" scrollHeight="71.5vh">
            <ng-template pTemplate="header" let-columns>
                <tr >
                    <th>Ticket ID</th>
                    <th>Rider ID</th>
                    <th>Ride ID</th>
                    <th>
                        <div class="gap filter-gap"> <span>Ticket Status</span>
                            <span> <p-multiSelect [ngClass]="{'filter1':selectedRefund &&  selectedRefund.length >= 1}"
                                    appendTo="body" [options]="refundTypeList" optionLabel="name"
                                    [(ngModel)]="selectedRefund" [dropdownIcon]="'fa fa-filter'"
                                    (onChange)="changePage()">
                                </p-multiSelect></span>
                        </div>
                    </th>
                    <th>Ride Amount</th>
                    <th>Refund Amount</th>
                    <th>Action taken by</th>
                    <th>
                        <div class="gap filter-gap"> <span>Refund Status</span>
                            <span *ngIf="!showRefundStatus"> <p-multiSelect
                                    [ngClass]="{'filter2':selectedStatus &&  selectedStatus.length >= 1}"
                                    appendTo="body" [options]="refundStatusList" optionLabel="name"
                                    [(ngModel)]="selectedStatus" [dropdownIcon]="'fa fa-filter'"
                                    (onChange)="changePage()">
                                </p-multiSelect></span>
                        </div>

                    </th>
                    <th>Last Updated at</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-columns="columns">
                <tr class="tr" (click)="gototicketDetails(data.ticketId)" style="cursor: pointer;">
                    <td>{{ data?.ticketId ? data?.ticketId :'-' }}</td>
                    <td>{{ data?.formData ? data?.formData?.rider?.riderId :'-' }}</td>

                    <td>{{ data?.formData ? data?.formData?.rideId : '-' }}</td>

                    <td>{{ data?.stageData ? getStageName(data?.stageData) : '-'}}</td>

                    <td>{{ data?.formData && data.formData?.paymentDetails ? data.formData?.paymentDetails[0]?.amount
                        : '-' }}</td>
                    <td>
                        {{ data?.stageData ? calculateRefundAmount(data) : '-' }}
                    </td>
                    <td>{{data?.latestActionTakenByName ? data?.latestActionTakenByName : '-'}}</td>
                    <td>
                        <span *ngIf="data?.refundStatus == 'Accepted'" class="approved gap">
                            <i class="fa fa-circle" aria-hidden="true"></i>
                            <span>{{data?.refundStatus ? data?.refundStatus :'-'}}</span>
                        </span>
                        <span *ngIf="data?.refundStatus == 'Rejected'" class="rejected gap">
                            <i class="fa fa-circle" aria-hidden="true"></i>
                            <span>{{data?.refundStatus ?data?.refundStatus:'-'}}</span> </span>
                        <span *ngIf="data?.refundStatus == 'Pending'" class="initiated gap">
                            <i class="fa fa-circle" aria-hidden="true"></i>
                            <span>{{ data?.refundStatus ?data?.refundStatus:'-'}}</span> </span>
                    </td>
                    <td>{{ data?.updatedAt ? (data?.updatedAt | amTimeAgo: timezone) : '-' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="tr">
                    <td colspan="7">No data found.</td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator (onPageChange)="onPageChange($event)" #paginator [first]="0" [rows]="limit"
            [totalRecords]="queryCount"></p-paginator>
    </div>
</div>
</div>

<app-detail-ticket-view [ticketId]="ticketId" (backToTicket)="isDetailViewVisible = false;fetchRiderTickets()"
    *ngIf="isDetailViewVisible">
</app-detail-ticket-view>