import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HubService {
    constructor(http) {
        this.http = http;
    }
    getAllHubs() {
        return this.http.get(Urls.HUB_URL + '/api/v1/hub/all', { headers: Headers.headers });
    }
    getAllAuthorizedHubs(userSsoId) {
        return this.http.get(Urls.HUB_URL + '/api/v1/authorized/hub/all?ssoId=' + userSsoId, { headers: Headers.headers });
    }
    getHubListBasedOnZone(ssoId, zoneId) {
        return this.http.get(Urls.HUB_URL + '/api/v1/hub/permission/' + ssoId + '/' + zoneId, { headers: Headers.headers });
    }
}
HubService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HubService_Factory() { return new HubService(i0.ɵɵinject(i1.HttpClient)); }, token: HubService, providedIn: "root" });
