/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-ticket.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ticket-calendar/ticket-calendar.component.ngfactory";
import * as i4 from "./ticket-calendar/ticket-calendar.component";
import * as i5 from "../../services/service-ticket.service";
import * as i6 from "ngx-spinner";
import * as i7 from "../../services/local.service";
import * as i8 from "./ticket-settings/ticket-settings.component.ngfactory";
import * as i9 from "./ticket-settings/ticket-settings.component";
import * as i10 from "primeng/components/common/messageservice";
import * as i11 from "primeng/components/common/confirmationservice";
import * as i12 from "./service-ticket.component";
import * as i13 from "ngx-cookie-service";
import * as i14 from "@angular/router";
var styles_ServiceTicketComponent = [i0.styles];
var RenderType_ServiceTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceTicketComponent, data: {} });
export { RenderType_ServiceTicketComponent as RenderType_ServiceTicketComponent };
function View_ServiceTicketComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tabs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTab("CALENDAR") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected-tab": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Calendar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tabs"; var currVal_1 = _ck(_v, 3, 0, (_co.isTabSelected("CALENDAR") === true)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ServiceTicketComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tabs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTab("TICKET_SETTINGS") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected-tab": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Configuration"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tabs"; var currVal_1 = _ck(_v, 3, 0, (_co.isTabSelected("TICKET_SETTINGS") === true)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ServiceTicketComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-calendar", [], null, null, null, i3.View_TicketCalendarComponent_0, i3.RenderType_TicketCalendarComponent)), i1.ɵdid(1, 114688, null, 0, i4.TicketCalendarComponent, [i5.ServiceTicketService, i6.NgxSpinnerService, i7.LocalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ServiceTicketComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-settings", [], null, null, null, i8.View_TicketSettingsComponent_0, i8.RenderType_TicketSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i9.TicketSettingsComponent, [i5.ServiceTicketService, i10.MessageService, i11.ConfirmationService, i6.NgxSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ServiceTicketComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "serviceticket-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "tabs-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceTicketComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceTicketComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceTicketComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceTicketComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkPermission("SERVICE_TICKET_CALANDER"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.checkPermission("SERVICE_TICKET_CONFIG"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isTabSelected("CALENDAR"); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isTabSelected("TICKET_SETTINGS"); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_ServiceTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-ticket", [], null, null, null, View_ServiceTicketComponent_0, RenderType_ServiceTicketComponent)), i1.ɵdid(1, 245760, null, 0, i12.ServiceTicketComponent, [i13.CookieService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServiceTicketComponentNgFactory = i1.ɵccf("app-service-ticket", i12.ServiceTicketComponent, View_ServiceTicketComponent_Host_0, {}, {}, []);
export { ServiceTicketComponentNgFactory as ServiceTicketComponentNgFactory };
