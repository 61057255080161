import { Urls } from '../models/api.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DbUsersService {
    constructor(http) {
        this.http = http;
    }
    getAllUsers() {
        return this.http.get(Urls.APP_URL + '/api/v1/user/all');
    }
}
DbUsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DbUsersService_Factory() { return new DbUsersService(i0.ɵɵinject(i1.HttpClient)); }, token: DbUsersService, providedIn: "root" });
