/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./form.component";
import * as i2 from "@angular/common";
import * as i3 from "./form-service.service";
import * as i4 from "./form.component.css.shim.ngstyle";
import * as i5 from "./dynamic-forms/container/dynamic-form.component.ngfactory";
import * as i6 from "./dynamic-forms/container/dynamic-form.component";
import * as i7 from "@angular/forms";
var styles_FormRendererComponent = [];
var RenderType_FormRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormRendererComponent, data: {} });
export { RenderType_FormRendererComponent as RenderType_FormRendererComponent };
function View_FormRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "form-component", [], null, [[null, "formSubmitHandler"], [null, "updateElementHandler"], [null, "deleteElementHandler"], [null, "addElementHandler"], [null, "dropElementHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmitHandler" === en)) {
        var pd_0 = (_co.formSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("updateElementHandler" === en)) {
        var pd_1 = (_co.updateFieldElementHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("deleteElementHandler" === en)) {
        var pd_2 = (_co.deleteFieldElementHandler($event) !== false);
        ad = (pd_2 && ad);
    } if (("addElementHandler" === en)) {
        var pd_3 = (_co.addFieldElementHandler($event) !== false);
        ad = (pd_3 && ad);
    } if (("dropElementHandler" === en)) {
        var pd_4 = (_co.dropFieldElementHandler($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_FormComponent_0, RenderType_FormComponent)), i0.ɵdid(2, 4308992, null, 0, i1.FormComponent, [], { areWeUpForUpdate: [0, "areWeUpForUpdate"], formElementConfigArray: [1, "formElementConfigArray"], formConfig: [2, "formConfig"] }, { addElementHandler: "addElementHandler", updateElementHandler: "updateElementHandler", deleteElementHandler: "deleteElementHandler", dropElementHandler: "dropElementHandler", formSubmitHandler: "formSubmitHandler" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.areWeUpForUpdate; var currVal_1 = _co.formElementConfigArray; var currVal_2 = _co.formConfig; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FormRendererComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["style", "margin-bottom: 40px"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormRendererComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormRendererComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoadComplete; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoadComplete; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FormRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-renderer", [], null, null, null, View_FormRendererComponent_0, RenderType_FormRendererComponent)), i0.ɵdid(1, 638976, null, 0, i1.FormRendererComponent, [i3.FormServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormRendererComponentNgFactory = i0.ɵccf("app-form-renderer", i1.FormRendererComponent, View_FormRendererComponent_Host_0, { areWeUpForUpdate: "areWeUpForUpdate", tokenAuth: "tokenAuth", formConfig: "formConfig" }, { addElementHandler: "addElementHandler", updateElementHandler: "updateElementHandler", deleteElementHandler: "deleteElementHandler", dropElementHandler: "dropElementHandler", formSubmitHandler: "formSubmitHandler" }, []);
export { FormRendererComponentNgFactory as FormRendererComponentNgFactory };
var styles_FormComponent = [i4.styles];
var RenderType_FormComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FormComponent, data: {} });
export { RenderType_FormComponent as RenderType_FormComponent };
function View_FormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addFieldElementHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Add elements"]))], null, null); }
export function View_FormComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { form: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dynamic-form", [], null, [[null, "submit"], [null, "updateElementHandler"], [null, "deleteElementHandler"], [null, "dropElementHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.submit($event) !== false);
        ad = (pd_0 && ad);
    } if (("updateElementHandler" === en)) {
        var pd_1 = (_co.updateFieldElementHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("deleteElementHandler" === en)) {
        var pd_2 = (_co.deleteFieldElementHandler($event) !== false);
        ad = (pd_2 && ad);
    } if (("dropElementHandler" === en)) {
        var pd_3 = (_co.dropFieldElementHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_DynamicFormComponent_0, i5.RenderType_DynamicFormComponent)), i0.ɵdid(3, 638976, [[1, 4], ["form", 4]], 0, i6.DynamicFormComponent, [i7.FormBuilder], { config: [0, "config"], areWeUpForUpdate: [1, "areWeUpForUpdate"] }, { updateElementHandler: "updateElementHandler", deleteElementHandler: "deleteElementHandler", dropElementHandler: "dropElementHandler", submit: "submit" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formElementsConfig; var currVal_1 = _co.areWeUpForUpdate; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.areWeUpForUpdate; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "form-component", [], null, null, null, View_FormComponent_0, RenderType_FormComponent)), i0.ɵdid(1, 4308992, null, 0, i1.FormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormComponentNgFactory = i0.ɵccf("form-component", i1.FormComponent, View_FormComponent_Host_0, { areWeUpForUpdate: "areWeUpForUpdate", formElementConfigArray: "formElementConfigArray", formConfig: "formConfig" }, { addElementHandler: "addElementHandler", updateElementHandler: "updateElementHandler", deleteElementHandler: "deleteElementHandler", dropElementHandler: "dropElementHandler", formSubmitHandler: "formSubmitHandler" }, []);
export { FormComponentNgFactory as FormComponentNgFactory };
