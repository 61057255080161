
    <form
      class="dynamic-form"
      [formGroup]="form"
      (submit)="handleSubmit($event)"
    >
      <div *ngFor="let field of config" class="mt_1">
        <!--
        <div
          *ngFor="let field of config;"
          [pDraggable]="areWeUpForUpdate ? 'dd': null"
          [pDroppable]="areWeUpForUpdate ? 'dd': null"
          (onDragEnd) = "onDropFieldElementHandler($event)"
        >
       -->
        <ng-container dynamicField [config]="field" [group]="form">
        </ng-container>
        <ng-container *ngIf="areWeUpForUpdate">
          <button (click)="updateFieldElementHandler(field)">Update</button>
          <button (click)="deleteFieldElementHandler(field)">Delete</button>
        </ng-container>
      </div>
    </form>
  