
    <div
      [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <button
        class="nextSubmit"
        pButton
        [disabled]="config.disabled"
        label="{{ config.label }}"
        type="submit"
      ></button>
    </div>
  