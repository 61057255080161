<div class="active-section">
    <div class="spinner-wrapper" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="data-wrapper" [ngClass]="{'loading': loading}">
        <div class="header">
            <span class="sub-header">Issue & Sub issue wise open tickets with their current age</span>
            <div class="filters">
                <span>
                    <p-multiSelect [options]="issues" [(ngModel)]="selectedIssues" placeholder="Issues"
                        defaultLabel="Issues" (onChange)="getData()"></p-multiSelect>
                </span>
                <span> <p-multiSelect [options]="hubs" [(ngModel)]="selectedHubs" placeholder="hubs" defaultLabel="Hubs"
                        (onChange)="getData()"></p-multiSelect>
                </span>
                <span>
                    <p-multiSelect [options]="workshops" [(ngModel)]="selectedWorkshops" placeholder="Workshops"
                        defaultLabel="Worshops" (onChange)="getData()"></p-multiSelect>

                </span>
                <span> <p-multiSelect [options]="categories" [(ngModel)]="selectedCategories" placeholder="Category"
                        defaultLabel="Category" (onChange)="getData()"></p-multiSelect>
                </span>
                <!-- <span>
            <div class="dropdown">
                <i *ngIf="!hideFlag" class="fa fa-flag flag"></i>
                <p-dropdown [options]="colours" placeholder="Colour tag" (onChange)="getData()"
                    [(ngModel)]="selectedColours" class="dropdown-label">
                    <ng-template let-item pTemplate="selectedItem">
                        <i class="fa fa-flag" [ngStyle]="changeStyle(item.value)" aria-hidden="true"></i>
                        {{ item.label }}
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <i class="fa fa-flag" [ngStyle]="changeStyle(item.value)" aria-hidden="true"></i>
                        {{ item.label }}
                    </ng-template>
                </p-dropdown>
            </div>

        </span> -->
                <button (click)="sendAsCSV()" class="csv-button">
                    <ng-container *ngIf="showCsvText">
                        <span class="flex csv"> <i class="fa fa-paper-plane" aria-hidden="true"></i> Send as
                            CSV</span>
                    </ng-container>
                    <ng-container *ngIf="showCsvLoader">
                        <span class="flex loader">
                            <div class="spinner-border" style="height: 12px; width: 12px; color: rgb(74, 74, 74);"
                                role="status">
                            </div> Loading
                        </span>
                    </ng-container>
                    <ng-container *ngIf="showCsvSuccess">
                        <span class="flex loader">
                            <i class="fa fa-check-circle" style="color: green; font-size: 14px;"></i>
                            Sent to email
                        </span>
                    </ng-container>
                    <ng-container *ngIf="showCsvFailed">
                        <span class="flex loader">
                            <i class="fa fa-times-circle" style="color: #ff3b30;font-size: 14px;"></i>
                            Technical error</span>
                    </ng-container>
                </button>
            </div>
        </div>

        <div class="table-header row">
            <div class="col-sm">Issues list</div>
            <div class="col-sm">Age: 1 - 3 days</div>
            <div class="col-sm">4 - 7 days</div>
            <div class="col-sm">7 - 10 days</div>
            <div class="col-sm">> 10 days</div>
        </div>


        <div style="height: 550px; overflow: auto;">
            <div *ngIf="data.length">
                <div *ngFor="let item of data; let i = index">
                    <div class="table-row">
                        <div class="row" >
                            <div class="col-sm" style="border-right: 1px solid #F5F5F5;">
                                <div class="flex">
                                    <span>{{ item.title }} </span>
                                    <!-- <i class="fa fa-flag" [ngStyle]="{'color': item.color}"></i> -->
                                </div>
                                <div class="flex mt-2">
                                    <i class="fa fa-ticket"></i>
                                    <span class="percentage">{{ item.tickets }}</span>
                                </div>
                            </div>
                            <div class="col-sm center">
                                <div style="width: 100%;">
                                    <p-progressBar [value]="item.onetothree"
                                        class="onetothree progress-bar"></p-progressBar>
                                    <span class="percentage">{{ item.onetothree }} %</span>
                                </div>
                            </div>
                            <div class="col-sm center">
                                <div style="width: 100%;">
                                    <p-progressBar [value]="item.fourtoseven"
                                        class="fourtoseven progress-bar"></p-progressBar>
                                    <span class="percentage">{{ item.fourtoseven }} %</span>
                                </div>
                            </div>
                            <div class="col-sm center">
                                <div style="width: 100%;">
                                    <p-progressBar [value]="item.seventoten"
                                        class="seventoten progress-bar"></p-progressBar>
                                    <span class="percentage">{{ item.seventoten }} %</span>
                                </div>
                            </div>
                            <div class="col-sm center">
                                <div style="width: 100%;">
                                    <p-progressBar [value]="item.aboveten"
                                        class="aboveten progress-bar"></p-progressBar>
                                    <span class="percentage">{{ item.aboveten }} %</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 flex expand"
                            (click)="expandedIndex != i ? getSubIssues(item,i) : closeExpanded(i)">
                            <span>{{ expandedIndex === i ? 'Close sub issues' : 'View sub issues' }}</span>
                            <i class="fa"
                                [ngClass]="{'fa-chevron-down': expandedIndex !== i, 'fa-chevron-up': expandedIndex === i}"></i>
                        </div>

                        <div *ngIf="expandedIndex === i" class="expanded-data">
                            <div *ngFor="let item of subData">
                                <div class="row sub-data-row">
                                    <div class="col-sm">
                                        <div class="flex">
                                            <span>{{ item.title }} </span>
                                            <i class="fa fa-flag" [ngStyle]="{'color': item.color}"></i>
                                        </div>
                                        <div class="flex mt-2" style="gap: 16px;">
                                            <span class="flex">
                                                <i class="fa fa-ticket"></i>
                                                <span>{{ item.tickets }}</span>
                                            </span>
                                            <span class="flex">
                                                <i class="fa fa-clock-o"></i>
                                                <span>{{getHoursAndDays(item.days)}}</span>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col-sm center">
                                        <div style="width: 100%;">
                                            <p-progressBar [value]="item.onetothree"
                                                class="onetothree progress-bar"></p-progressBar>
                                            <span>{{ item.onetothree }} %</span>
                                        </div>
                                    </div>
                                    <div class="col-sm center">
                                        <div style="width: 100%;">
                                            <p-progressBar [value]="item.fourtoseven"
                                                class="fourtoseven progress-bar"></p-progressBar>
                                            <span>{{ item.fourtoseven }} %</span>
                                        </div>
                                    </div>
                                    <div class="col-sm center">
                                        <div style="width: 100%;">
                                            <p-progressBar [value]="item.seventoten"
                                                class="seventoten progress-bar"></p-progressBar>
                                            <span>{{ item.seventoten }} %</span>
                                        </div>
                                    </div>
                                    <div class="col-sm center">
                                        <div style="width: 100%;">
                                            <p-progressBar [value]="item.aboveten"
                                                class="aboveten progress-bar"></p-progressBar>
                                            <span>{{ item.aboveten }} %</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

            </div>
            <div *ngIf="!data.length" class="no-data">
                No data found
            </div>
        </div>
    </div>
</div>