import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { sanitizeHtmlInputDateTime } from '../dynamic-form-config.utils';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.css']
})
export class DateTimeComponent implements OnInit {
  optionsForSelectedFields: any[] = [];
  dynamicFieldCurrentLabel = '';
  dynamicFieldCurrentValue = '';

  @Input() inputElementData: any;
  @Output() outputElementData: EventEmitter<any> = new EventEmitter<any>();
  selectedFieldOptionValues: string[] = [];
  dataElement: any = {};
  htmlType =  null;
  constructor() { }
  ngOnInit() {
    if (this.inputElementData) {
      console.log(this.inputElementData, "inputData");
      
      this.dataElement = this.inputElementData;
      if (this.dataElement.disabled) {
        this.selectedFieldOptionValues.push('disabled');
      }
      if (this.dataElement.validations && this.dataElement.validations.required) {
        this.selectedFieldOptionValues.push('required');
      }

      if (this.dataElement.options) {
        this.optionsForSelectedFields =
        this.dataElement.options.map((o) => ( {...o, isSaved: true} ));
        console.log('options', this.optionsForSelectedFields);
      }
      this.htmlType = this.dataElement.htmlType;
      this.dataElement.optionsForSelectedFields = this.optionsForSelectedFields;
    }
  }
  configElementInputDropdownSubmitHandler(form: NgForm) {
    const { value } = form;
    if (value && Object.keys(value).length > 0 ) {
      const element: any = {
        html: 'dateTime',
        fieldOptions: this.selectedFieldOptionValues,
        ...value,
        isConfigActive: true,
      };
      console.log(this.optionsForSelectedFields);
      
      if ( this.optionsForSelectedFields && this.optionsForSelectedFields.length > 0 ) {
        element.options = this.optionsForSelectedFields;
      }
      console.log('VALUE_ELEMENT\n',value, element);
      this.outputElementData.emit({...sanitizeHtmlInputDateTime(element),  isConfigActive: true});
    }
  }

  addOption() {
    if (!this.dynamicFieldCurrentLabel || !this.dynamicFieldCurrentValue) {
      return;
    }
    const el = {
      label: this.dynamicFieldCurrentLabel,
      value: this.dynamicFieldCurrentValue,
      isSaved: true,
      isEditing: false,
    };
    this.optionsForSelectedFields.push(el);
  }

  deleteThisOption(i) {
    this.optionsForSelectedFields.splice(i, 1);
  }

  editThisOption(i) {
    this.optionsForSelectedFields[i].isEditing = true;
    this.optionsForSelectedFields[i].isSaved = false;
  }

  saveThisOption(i) {
    this.optionsForSelectedFields[i].isSaved = true;
    this.optionsForSelectedFields[i].isEditing = false;
  }

  labelChangeHandler(e, i) {
    const value = e.target.value;
    this.optionsForSelectedFields[i].label = value;
  }

  valueChangeHandler(e, i) {
    this.optionsForSelectedFields[i].value = e && e.target ? e.target.value : e.value;
  }
}
