import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';

@Injectable({
  providedIn: 'root'
})
export class DbUsersService {

  constructor(private http: HttpClient) { }

  getAllUsers(): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/user/all');
  }

}
