<!-- <div class="box">
    <div class="box-header">
        <div (click)="selectSection('current')" class="box-header-text"
            [ngClass]="{'selected': selectedSection === 'current'}">
            Currently open ticket TATs
        </div>
        <div (click)="selectSection('history')" class="box-header-text"
            [ngClass]="{'selected': selectedSection === 'history'}">
            Historical ticket TATs
        </div>
    </div>
    <div *ngIf="showCurrentTickets" class="active-section">
       <app-current-tickets></app-current-tickets>
    </div>
    <div *ngIf="showHistoricalTickets">
        history
    </div>
</div> -->

<div class="box">
    <div class="active-section">
        <app-current-tickets></app-current-tickets>
    </div>
</div>