<div class="dynamic-form-container">
  <div class="dynamic-form-configuration">
    <div class="properties-container">
      <div class="global-parameters">
        <div class="justify_space_bw ">
          <label for="global-project">Form Project</label>
          <input id="global-project" type="text" style="width:100%" [(ngModel)]="formProject" pInputText disabled>
        </div>
        <div class="justify_space_bw ">
          <label for="global-catefory">Form Category</label>
          <input id="global-catefory" type="text" style="width:100%" [(ngModel)]="formCategory" pInputText disabled>
        </div>
        <div class="justify_space_bw ">
          <label for="global-name">Form Name &nbsp; *</label>
          <input id="global-name" type="text" style="width:100%" [(ngModel)]="formName" pInputText>
        </div>
        <div class="justify_space_bw " style="width:100%;min-height:30px" *ngIf="formCategory=='endPointElement'">
          <label for="global-name">User action</label>
          <p-multiSelect [options]="usersList" [style]="{'width':'100%'}" [(ngModel)]="selectedUsersToMapTickets"
            placeholder="Users" defaultLabel="Select Users for Action"></p-multiSelect>
        </div>
        <div class="justify_space_bw " style="width:100%;min-height:30px" *ngIf="formCategory=='endPointElement'">
          <label for="global-name">User view</label>
          <p-multiSelect [options]="usersList" [style]="{'width':'100%'}" [(ngModel)]="selectedViewUsersToMapTickets"
            placeholder="Users" defaultLabel="Select Users for View"></p-multiSelect>
        </div>
        <div class="justify_space_bw " style="width:100%;min-height:30px" *ngIf="formCategory=='endPointElement'">
          <label for="global-name">User edit</label>
          <p-multiSelect [options]="usersList" [style]="{'width':'100%'}" [(ngModel)]="selectedEditUsersToMapTickets"
            placeholder="Users" defaultLabel="Select Users for edit"></p-multiSelect>
        </div>
        <div class="justify_space_bw " style="width:100%" *ngIf="formCategory=='endPointElement'">
          <label for="global-sla">Stage Mapping &nbsp; *</label>
          <p-dropdown [options]="stagesList" [style]="{'width':'100%'}" [(ngModel)]="selectedStageId"
            placeholder="Stages" defaultLabel="Select Stages for Action" required></p-dropdown>
        </div>

        <div class="justify_space_bw" *ngIf="formCategory=='endPointElement'">
          <label for="global-sla">Team Name&nbsp; </label>
          <input id="global-sla" type="text" style="width:100%" [(ngModel)]="team" pInputText >
        </div>
        <div class="justify_space_bw " *ngIf="formCategory=='endPointElement'">
          <label for="global-sla">SLA (in days) &nbsp; *</label>
          <input id="global-sla" type="number" style="width:100%" [(ngModel)]="sla" pInputText required>
        </div>
      </div>
      <div class="accordian scrollable-container">
        <p-accordion [(activeIndex)]="activeAccordianIndex">
          <p-accordionTab *ngFor="let formElement of formElements;let i=index">
            <p-header class="element-header">
              <span *ngIf="formElement.html=='input'">Input</span>
              <span *ngIf="formElement.html=='inputGroup' && formElement.htmlType=='checkbox'">Checkbox Group</span>
              <span *ngIf="formElement.html=='inputGroup' && formElement.htmlType=='radio'">Radio Group</span>
              <ng-container *ngIf="formElement.html=='select'">
                <span *ngIf="formCategory === 'endPointElement' || formCategory === 'stageElement'">Dropdown</span>
                <span *ngIf=" formCategory === 'hierarchyElement'  ">Hierarchy</span>

              </ng-container>
              <span *ngIf="formElement.html=='select' && formElement.htmlType=='multiselect'">Multiselect</span>
              <span *ngIf="formElement.html=='inputFile'">File</span>
              <span *ngIf="formElement.html=='dateTime'">Date & Time</span>

            </p-header>
            <div style="margin-bottom: 2%;">
              <p-toolbar>
                <div style="display: inline-flex;" class="ui-toolbar-group-left">
                  <div style="padding-top:5%;">Sort / Delete Element</div>
                </div>
                <div style="display: inline-flex;" class="ui-toolbar-group-right">
                  <div style="margin-right: 3%;">
                    <p-button icon="pi pi-chevron-up" (click)="accordionTabUp(i)"></p-button>
                  </div>
                  <div style="margin-right: 3%;">
                    <p-button icon="pi pi-chevron-down" (click)="accordionTabDown(i)"></p-button>
                  </div>
                  <div style="margin-right: 3%;">
                    <button type="button" pButton icon="pi pi-check" label="Delete" class="ui-button-danger"
                      (click)="deleteAccordionTab(i)"></button>
                  </div>
                </div>
              </p-toolbar>
            </div>
            <app-input-element (outputElementData)="insertElementToForm($event,i)" *ngIf="formElement.html=='input'"
              [inputElementData]="formElement">
            </app-input-element>
            <app-checkbox-element (outputElementData)="insertElementToForm($event,i)" [inputElementData]="formElement"
              *ngIf="formElement.html=='inputGroup' && formElement.htmlType=='checkbox'">
            </app-checkbox-element>
            <app-radio-element (outputElementData)="insertElementToForm($event,i)" [inputElementData]="formElement"
              *ngIf="formElement.htmlType=='radio'">
            </app-radio-element>

            <app-file-element (outputElementData)="insertElementToForm($event,i)" [inputElementData]="formElement"
              *ngIf=" formElement.html === 'inputFile' ">
            </app-file-element>

            <ng-container *ngIf="formElement.html=='select'">
              <app-dropdown-element (outputElementData)="insertElementToForm($event,i)" [inputElementData]="formElement"
                *ngIf=" formCategory === 'endPointElement' || formCategory === 'stageElement' ">
              </app-dropdown-element>

              <app-heirarchy-dropdown-element [inputElementData]="formElement"
                [formProject]="formProject"
                (outputElementData)="insertElementToForm($event,i)" *ngIf=" formCategory === 'hierarchyElement' ">
              </app-heirarchy-dropdown-element>
            </ng-container>
            <app-date-time *ngIf="formElement.html === 'dateTime' " [inputElementData]="formElement"
              (outputElementData)="insertElementToForm($event,i)"></app-date-time>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <div class="configurationFormActions">
      <div class="left">
        <button pButton type="button" label="Submit Form" class="ui-button-success" (click)="saveFormToDB()"></button>
        <button pButton type="button" label="Delete" class="ui-button-danger" (click)="deleteFormHandler()"></button>
      </div>
      <div class="right">
        <button *ngIf="formCategory !=='hierarchyElement' " class="add-elements"
          (click)="addElementsDialog=true">+</button>
      </div>
    </div>

  </div>
  <div class="dynamic-form-rendering">
    <app-form-renderer *ngIf="isFormVisible" [formConfig]="formConfig"></app-form-renderer>
  </div>
</div>
<p-dialog header="Elements" [(visible)]="addElementsDialog" [baseZIndex]="10000" [modal]="true">
  <div class="ui-g" style="width:250px;margin-bottom:10px">
    <div class="ui-g-12">
      <p-radioButton name="group1" value="input" label="Input" [(ngModel)]="selectedFormElement"></p-radioButton>
    </div>
    <div class="ui-g-12">
      <p-radioButton name="group1" value="inputGroupCheckbox" label="CheckBox" [(ngModel)]="selectedFormElement">
      </p-radioButton>
    </div>
    <div class="ui-g-12">
      <p-radioButton name="group1" value="inputGroupRadio" label="Radio" [(ngModel)]="selectedFormElement">
      </p-radioButton>
    </div>
    <div class="ui-g-12">
      <p-radioButton name="group1" value="selectDropdown" label="Dropdown" [(ngModel)]="selectedFormElement">
      </p-radioButton>
    </div>
    <div class="ui-g-12">
      <p-radioButton name="group1" value="dateTime" label="Date & Time" [(ngModel)]="selectedFormElement">
      </p-radioButton>
    </div>
    <!-- <div class="ui-g-12">
      <p-radioButton name="group1" value="selectMultiselect" label="Multi-Select" [(ngModel)]="selectedFormElement">
      </p-radioButton>
    </div> -->
    <div class="ui-g-12">
      <p-radioButton name="group1" value="inputFile" label="File" [(ngModel)]="selectedFormElement"></p-radioButton>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="addSelectedElementToForm()" label="Add Element"></button>
    <button type="button" pButton icon="pi pi-times" (click)="addElementsDialog=false" label="Cancel"
      class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
