import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { sanitizeHtmlInputSelectDropdown } from '../dynamic-form-config.utils';


@Component({
  selector: 'app-dropdown-element',
  templateUrl: './dropdown-element.component.html',
  styleUrls: ['./dropdown-element.component.css']
})

export class DropdownElementComponent implements OnInit {

  @Output() outputElementData: EventEmitter<any> = new EventEmitter<any>();
  urlOrOptions = '';
  URL_OPTIONS = {
    URL: 'URL',
    OPTIONS: 'OPTIONS'
  };
  selectedFieldOptionValues: string[] = [];
  optionsForSelectedFields: any[] = [];
  dynamicFieldCurrentLabel = '';
  dynamicFieldCurrentValue = '';

  constructor() {
  }
  @Input() inputElementData: any;
  dataElement: any = {};

  ngOnInit() {
    if (this.inputElementData) {
      this.dataElement = this.inputElementData;
      if (this.dataElement.disabled) {
        this.selectedFieldOptionValues.push('disabled');
      }
      if (this.dataElement.validations && this.dataElement.validations.required) {
        this.selectedFieldOptionValues.push('required');
      }
      if (this.dataElement.htmlType === 'multiselect') {
        this.selectedFieldOptionValues.push('multiple');
      }
      console.log(this.dataElement);
      if (this.dataElement.url) {
        this.urlOrOptions = this.URL_OPTIONS.URL;
      } else {
        if (this.dataElement.options) {
          console.log('here');
          this.urlOrOptions = this.URL_OPTIONS.OPTIONS;
          this.optionsForSelectedFields =
          this.dataElement.options.map((o) => ( {...o, isSaved: true} ));
          console.log('options', this.optionsForSelectedFields);
        }
      }
    }
     this.dataElement.optionsForSelectedFields = this.optionsForSelectedFields;
  }
  selectUrlVersion() {
    delete this.dataElement.options;
  }
  selectOptionsVersion() {
    delete this.dataElement.url;
    delete this.dataElement.optionFieldLabel;
    delete this.dataElement.optionFieldValue;
  }
  configElementInputDropdownSubmitHandler(form: NgForm) {
    const { value } = form;
    if ( value && Object.keys(value).length > 0) {
      const element: any = {
        fieldOptions: this.selectedFieldOptionValues,
        ...value,
        isConfigActive: true
      };
      if ( this.optionsForSelectedFields && this.optionsForSelectedFields.length > 0 ) {
        element.options = this.optionsForSelectedFields;
      }
      if (element.url) {
        delete element.options;
      }
      this.outputElementData.emit(sanitizeHtmlInputSelectDropdown(element));
    }
  }

  addOption() {
    if (!this.dynamicFieldCurrentLabel || !this.dynamicFieldCurrentValue) {
      return;
    }
    const el = {
      label: this.dynamicFieldCurrentLabel,
      value: this.dynamicFieldCurrentValue,
      isSaved: true,
      isEditing: false,
    };
    this.optionsForSelectedFields.push(el);
  }

  deleteThisOption(i) {
    this.optionsForSelectedFields.splice(i, 1);
  }

  editThisOption(i) {
    this.optionsForSelectedFields[i].isEditing = true;
    this.optionsForSelectedFields[i].isSaved = false;
  }

  saveThisOption(i) {
    this.optionsForSelectedFields[i].isSaved = true;
    this.optionsForSelectedFields[i].isEditing = false;
  }

  labelChangeHandler(e, i) {
    const value = e.target.value;
    this.optionsForSelectedFields[i].label = value;
  }

  valueChangeHandler(e, i) {
    this.optionsForSelectedFields[i].value = e && e.target ? e.target.value : e.value;
  }
}
