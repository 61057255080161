import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TatIssuesService } from 'src/app/services/tat-issues.service';

@Component({
  selector: 'app-issues-tat',
  templateUrl: './issues-tat.component.html',
  styleUrls: ['./issues-tat.component.css']
})
export class IssuesTatComponent implements OnInit {

  showCurrentTickets = true;
  showHistoricalTickets = false;
  selectedSection = 'current';
  

  constructor(private tatIssuesService: TatIssuesService, private _cookieService: CookieService) { }

  ngOnInit() {
   
  }



  selectSection(type: string) {
    if (type === 'current') {
      this.showCurrentTickets = true;
      this.showHistoricalTickets = false;
    } else {
      this.showCurrentTickets = false;
      this.showHistoricalTickets = true;
    }
    this.selectedSection = type;
  }


}
