<div class="container">
  <div class="fixed-content">
    <p-breadcrumb
      [model]="segregatedCategory"
      [home]="home"
      (click)="backToAllTicket()"
    ></p-breadcrumb>
  </div>
  <div class="content" *ngIf="ticket">
    <p-panel
      header="Ticket Details"
      [toggleable]="true"
      *ngIf="ticket.project == 'driverPartnerTMS'"
    >
      <div class="horizontal-allignment">
        <div class="ticket-details">
          <p-card header="Issue details">
            <span
              class="category-icon"
              (click)="openEditCategoryModal()"
              *ngIf="
                ticket.currentStage == 'Ticket Created' &&
                ticket.project == 'driverPartnerTMS'
              "
              >Category
              <i
                class="fa fa-edit"
                style="position: relative; top: 2px; left: 2px"
                aria-hidden="true"
              ></i
            ></span>
            <table style="width: 100%">
              <tr style="width: 100%">
                <td style="width: 40%"><b>Category</b></td>
                <td style="width: 60%">{{ ticket.category }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Type</b></td>
                <td style="width: 60%">{{ ticket["formData"].type }}</td>
              </tr>

              <tr style="width: 100%" *ngIf="ticket['formData'].file">
                <td style="width: 40%"><b>Image</b></td>
                <td
                  class="hyperlink"
                  style="width: 60%"
                  (click)="viewImage('DriverIssue')"
                >
                  View Attached File
                </td>
              </tr>
              <tr
                style="width: 100%"
                *ngIf="ticket['formData'].incidentTimestamp"
              >
                <td style="width: 40%"><b>Incident Timestamp</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket["formData"].incidentTimestamp) | date : "medium" }}
                </td>
              </tr>
              <tr style="width: 100%" *ngIf="ticket['formData'].description">
                <td style="width: 40%"><b>Description</b></td>
                <td style="width: 60%">{{ ticket["formData"].description }}</td>
              </tr>
              <tr style="width: 100%" *ngIf="ticket['formData'].rideId">
                <td style="width: 40%"><b>Ride Id</b></td>
                <td style="width: 60%">{{ ticket["formData"].rideId }}</td>
              </tr>
              <tr style="width: 100%" *ngIf="ticket['formData'].vehicleNumber">
                <td style="width: 40%"><b>Vehicle Number</b></td>
                <td style="width: 60%">
                  {{ ticket["formData"].vehicleNumber }}
                </td>
              </tr>
              <tr
                style="width: 100%"
                *ngFor="
                  let issue of ticket['formData']['fieldsData'] | keyvalue
                "
              >
                <td
                  style="width: 40%"
                  *ngIf="
                    issue.key != 'typeId' &&
                    issue.key != 'type' &&
                    issue.key != 'driverId' &&
                    issue.key != 'team' &&
                    issue.key != 'driverName' &&
                    issue.key != 'fieldsData'
                  "
                >
                  <b>{{ convertToNormalCase(issue.key) }}</b>
                </td>
                <td
                  *ngIf="
                    isObject(issue.value) == 'PRIMITIVE' &&
                    issue.key != 'files' &&
                    issue.key != 'file' &&
                    issue.key != 'typeId' &&
                    (issue.key == 'Date of Service' ||
                      issue.key == 'Date Of Issue') &&
                    issue.key != 'description'
                  "
                  style="width: 60%"
                >
                  {{ getLocalDate(issue.value) | date : "medium" }}
                </td>
                <td
                  *ngIf="
                    isObject(issue.value) == 'PRIMITIVE' &&
                    issue.key != 'file' &&
                    issue.key != 'files' &&
                    issue.key != 'typeId' &&
                    issue.key != 'Date of Service' &&
                    issue.key != 'Date Of Issue' &&
                    issue.key != 'type' &&
                    issue.key != 'driverId' &&
                    issue.key != 'team' &&
                    issue.key != 'driverName'
                  "
                  style="width: 60%"
                >
                  {{ isDate(issue.value) }}
                </td>
                <td
                  *ngIf="
                    (isObject(issue.value) == 'PRIMITIVE' &&
                      issue.key == 'file') ||
                    issue.key == 'files'
                  "
                  class="hyperlink"
                  style="width: 60%"
                  (click)="viewImage('DriverIssue')"
                >
                  View Attached File
                </td>

                <!--             <td *ngIf="issue.value && isObject(issue.value) == 'OBJECT'" style="width: 60%;">
                  <div style="display: flex;flex-direction: column;">
                    <div *ngFor="let key of object.keys(issue.value)" style="display: flex;">
                      <div *ngIf="issue.value[key]" style="flex-direction:row;display:flex;">
                        <div style="display: flex;margin:0px"><b>{{convertToNormalCase(key)}}</b> - </div>
                        <div style="display: flex;margin:0px 5px"> {{issue.value[key]}}</div>
                      </div>
                    </div>
                  </div>
                </td> -->
                <!--    <td *ngIf="issue.value && isObject(issue.value) == 'ARRAY_OF_OBJECT'" style="width: 60%;">
                  <div style="display: flex;flex-direction: row;">
                    <table style="width: 100%;border:1px solid white">
                      <tr style="width: 100%;">
                        <th *ngFor="let headers of getHeaders(issue.value)" style="border:1px solid white">
                          {{convertToNormalCase(headers)}}
                        </th>
                      </tr>
                      <tr style="width: 100%;" *ngFor="let obj of issue.value">
                        <td *ngFor="let key of getHeaders(issue.value)" style="border:1px solid white">
                          {{obj[key]}}
                        </td>
                      </tr>
                     <div *ngFor="let key of object.keys(issue.value)" style="display: flex;">
                      <div *ngIf="issue.value[key]" style="flex-direction:row;display:flex;">
                        <div style="display: flex;margin:0px"><b>{{convertToNormalCase(key)}} </b> - </div>
                        <div style="display: flex;margin:0px 5px"> {{issue.value[key]}}</div>
                      </div>
                    </div>
                    </table>
                  </div>
                </td>-->
              </tr>
            </table>
          </p-card>
        </div>
        <div class="ticket-details">
          <p-card header="Ticket details">
            <table style="width: 100%">
              <tr style="width: 100%">
                <td style="width: 40%"><b>Ticket ID</b></td>
                <td style="width: 60%">{{ ticket.ticketId }}</td>
              </tr>
              <!-- <tr style="width: 100%;">
                <td style="width: 40%;"><b>Category</b></td>
                <td style="width: 60%">{{ticket.category}}
                  <i class="fa fa-pencil" *ngIf="ticket.currentStage == 'Ticket Created'" aria-hidden="true" (click)="openEditCategoryModal()" style="position: relative;left: 10px;cursor: pointer;"></i>
                </td>
              </tr>
              <tr style="width: 100%;">
                <td style="width: 40%;"><b>Hub</b></td>
                <td style="width: 60%">{{ticket.hubName}}</td>
              </tr> -->
              <tr style="width: 100%">
                <td style="width: 40%"><b>Current Stage</b></td>
                <td style="width: 60%">{{ ticket.currentStage }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Due By</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket.dueDate) | date : "medium" }}
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Created By</b></td>
                <td style="width: 60%">{{ ticket.createdByName }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Created Date</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket.createdAt) | date : "medium" }}
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Last Updated By</b></td>
                <td style="width: 60%">{{ ticket.updatedByName }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Last Updated Date</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket.updatedAt) | date : "medium" }}
                </td>
              </tr>
            </table>
          </p-card>
        </div>
        <div class="ticket-details">
          <p-card header="Basic details">
            <table style="width: 100%">
              <tr style="width: 100%">
                <td style="width: 40%"><b>Driver ID</b></td>
                <td style="width: 60%">{{ ticket["formData"].driverId }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Driver Name</b></td>
                <td style="width: 60%">{{ ticket["formData"].driverName }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Team</b></td>
                <td style="width: 60%">{{ ticket["formData"].team }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Hub</b></td>
                <td style="width: 60%">{{ ticket.hubName }}</td>
              </tr>
            </table>
          </p-card>
        </div>
      </div>
    </p-panel>

    <p-panel header="Penalty Details" [toggleable]="true" *ngIf= "ticket && penaltyType" styleClass = "penalty-details">
    <div style="margin-bottom: 20px">
        <p style="margin: 8px 0 12px 0;">{{penaltyName}}</p>
        <p-card>
        <div class="penalty-details">
           <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Driver name</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.driverName }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Driver partner ID</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.driverId }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Penalty Date</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.penaltyDate) | date : "dd/MM/yyyy"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Penalty Slot</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>Slot-{{ driverPenaltyDetails.penaltySlot }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Daily Stat ID</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.dailyStatsId }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Check In Hub</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.checkInHub }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Check Out Hub</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.checkOutHub }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Late Duty Start Reason</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.lateDutyStartReason }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Official Duty Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.officialDutyStartTime)
                        | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style = "width: 100%">
                  <td style="width: 60%" ><b>Auto switch</b></td>
                  <td style="width: 40%" ><b>{{ driverPenaltyDetails?.isAutoSwitched ? 'Yes' : 'No' }}</b></td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Allowed Duty Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.allowedDutyStartTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Actual Duty Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.actualDutyStartTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Official Duty End Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.officialDutyEndTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Allowed Duty End Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.allowedDutyEndTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Actual Duty End Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.actualDutyEndTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>RTH Ride Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.rthRideStartTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Estimated RTH End Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.estimatedRTHEndTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>RTH Ride End Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.rthRideEndTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>RTH Trip Distance(km)</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.rthTripDistance }}</b>
                  </td>
                </tr>
                <tr *ngIf="driverPenaltyDetails?.lowSocRideStartTime"  style="width: 100%">
                  <td style="width: 60%"><b>Low SOC Ride Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails"><b>{{ getDate24Hours(driverPenaltyDetails?.lowSocRideStartTime) | date : "HH:mm"}}</b></td>
                </tr>
                <tr *ngIf="driverPenaltyDetails?.lowSocRideEstimatedEndTime"  style="width: 100%">
                  <td style="width: 60%"><b>Estimated LOW SOC End Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails"><b>{{ getDate24Hours(driverPenaltyDetails?.lowSocRideEstimatedEndTime) | date : "HH:mm"}}</b></td>
                </tr>
                <tr *ngIf="driverPenaltyDetails?.lowSocRideEndTime " style="width: 100%">
                  <td style="width: 60%"><b>Low SOC Ride End Time</b></td>
                  <td style="width: 40%" ><b>{{ getDate24Hours(driverPenaltyDetails?.lowSocRideEndTime) | date : "HH:mm"}}</b></td>
                </tr>
                <tr *ngIf="driverPenaltyDetails?.lowSocTripDistance"  style="width: 100%">
                  <td style="width: 60%"><b>Low SOC Trip Distance(km)</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails"><b>{{driverPenaltyDetails?.lowSocTripDistance}} Km</b></td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Key Handover Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.actualDutyEndTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Estimated Hub Reach Time</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{
                      getDate24Hours(driverPenaltyDetails.expectedHubReachTime) | date : "HH:mm"
                    }}</b>
                  </td>
                </tr>
                <tr style="width: 100%" *ngIf="penaltyType">
                  <td style="width: 60%;"><b>Penalty Name</b></td>
                  <td style="width: 40%; font-size: 9px; text-align: center;" *ngIf="driverPenaltyDetails">
                    <b>{{driverPenaltyDetails.penaltyName}}</b>
                    <br/>
                    <button *ngIf="hasPenaltyName()" type="button" class="slab" (click)="showSlab()">
                      <i class="pi pi-info-circle"></i>
                      Check Penalty Slab
                    </button>
                  </td>

                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>No. of Penalty</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails"><b>{{driverPenaltyDetails.penaltyCategory}}</b></td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Penalty Amount</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.penaltyAmount }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Credited Amount</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.creditedAmount }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Penalty Duration</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.penaltyDuration }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Reverse Penlty Duration</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.reversePenaltyDuration }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Credited by</b></td>
                  <td style="width: 40%" *ngIf="driverPenaltyDetails">
                    <b>{{ driverPenaltyDetails.creditedBy }}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Action</b></td>
                  <span *ngIf="driverPenaltyDetails" class="ui-float-label"
                    ><button
                      [disabled]="isDisabled()"
                      pButton
                      class="submit"
                      label="Reverse"
                      (click)="returnAmountToDriver()"
                    ></button
                  ></span>
                </tr>
              </table>
            </div>
          </div>
        </p-card>
      </div>
    </p-panel>

    <p-panel header="Lease Details" styleClass="lease-details" [toggleable]="true" *ngIf="ticket && absenteeismPenaltyType">
      <div style="margin-bottom: 20px">
        <p-card>
          <div class="penalty-details">
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Incident Date</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.recordDate">
                    <b>{{driverAbsenteeismDetails.recordDate}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Attendance State</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.attendanceState}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Shift</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.slot">
                
                    <div class="toggleswitch">
                      <div class="switches-toggleswitch">
                        <input type="radio" id="switchslot1" name="switchPlan" value="slot1" 
                               checked="checked" (click)="getAbsenteeismDetailsOnSlotSwitch(1)" />
                        <input *ngIf="!singleSlot" type="radio" id="switchslot2" name="switchPlan" 
                               value="slot2" (click)="getAbsenteeismDetailsOnSlotSwitch(2)"/>
                        <label for="switchslot1">S-1</label>
                        <label for="switchslot2">S-2</label>
                        <div class="switch-wrapper">
                          <div class="switch">
                            <div>S-1</div>
                            <div>S-2</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button *ngIf="singleSlot" type="button" class="absenteeism-slab">
                      <i class="pi pi-info-circle"></i>
                      Data not present for S-2
                    </button>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Auto Switch</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.hasOverlappingHappen}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Incident Date Checkin Timestamp</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.checkInTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.checkInTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Official Duty Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.officialDutyStartTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.officialDutyStartTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Official Duty End Time</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.officialDutyEndTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.officialDutyEndTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Duty Start Time</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.dutyStartTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.dutyStartTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Duty End Time</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.dutyEndTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.dutyEndTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Official Duty End Time - Incident Date - 1</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.officialDutyEndTimestampOne">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.officialDutyEndTimestampOne) | date : "medium"}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Duty End Time - Incident Date - 1</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.dutyEndTimestampOne">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.dutyEndTimestampOne) | date : "medium"}}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Lease Cost Penalty</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.leaseCostPenalty}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Minimum Guarantee</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.actualMinimumGuarantee}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Minimum Guarantee Penalty</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.minimumGuaranteePenalty}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Absent Penalty Reversed</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.absentPenaltyReversed}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Action</b></td>
                  <span *ngIf="driverAbsenteeismDetails" class="ui-float-label">
                    <button [disabled]="isAbsenteeismReversalBtnDisabled()" pButton class="submit"
                      label="Reverse" (click)="reverseAbsenteeismPenaltyConfirmation()"></button></span>
                  <button *ngIf="(driverAbsenteeismDetails && !driverAbsenteeismDetails.absentPenaltyReversed &&
                                  driverAbsenteeismDetails.attendanceState === 'ABSENT' &&
                                 (driverAbsenteeismDetails.leaseCostPenalty === null ||
                                  driverAbsenteeismDetails.leaseCostPenalty <= 0) &&
                                (driverAbsenteeismDetails.minimumGuaranteePenalty === null || 
                                  driverAbsenteeismDetails.minimumGuaranteePenalty <= 0))" 
                       type="button" class="absenteeism-slab" style="margin: 15px;">
                    ** Deactivated in case of Suspended/Deactivated/New DP for the absent day
                  </button>
                </tr>
                
              </table>
              <div class="checkbox-box">
                <div class="checkbox-box" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.minimumGuaranteePenalty && driverAbsenteeismDetails.minimumGuaranteePenalty > 0">
                   <p-checkbox name="reverseMbg" [binary]="true" value="" [(ngModel)]="reverseMbgCheckbox">
                   </p-checkbox>
                  <div class="checkbox-text">
                     MBG
                  </div>
                </div>
    
                <div class="checkbox-box">
                 <p-checkbox name="reverseMbg" [binary]="true" value="" [(ngModel)]="reverseSEBCheckbox">
                  </p-checkbox>
                   <div class="checkbox-text">
                      SEB/Referral/Safety Bonus
                   </div>
                </div>
                
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </p-panel>
    
    <p-panel header="Ride Details" styleClass="absent-ride-details" [toggleable]="true" *ngIf="ticket && absenteeismPenaltyType">
      <div style="margin-bottom: 20px">
        <p-card>
          <div class="penalty-details">
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Last Ride ID</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails"><b>{{driverAbsenteeismDetails.lastRideId}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Created Timestamp</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.lastRideCreatedTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.lastRideCreatedTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>State</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails"><b>{{driverAbsenteeismDetails.lastRideState}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Type</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails"><b>{{driverAbsenteeismDetails.lastRideType}}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Driver Arrived Time</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.lastRideArrivedTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.lastRideArrivedTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Drop Time</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.lastRideDropTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.lastRideDropTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </p-card>
      </div>
    </p-panel>
    
    <p-panel header="Breakdown Details" styleClass="breakdown-details" [toggleable]="true"
      *ngIf="ticket && absenteeismPenaltyType">
      <div style="margin-bottom: 20px">
        <p-card>
          <div class="penalty-details">
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Breakdown Created Timestamp</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails && driverAbsenteeismDetails.breakDownCreatedTimestamp">
                    <b>{{ getDate24Hours(driverAbsenteeismDetails.breakDownCreatedTimestamp) | date : "medium"}}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Is On Ride</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails"><b>{{driverAbsenteeismDetails.isOnRide}}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="penalty-data">
              <table style="width: 100%; border-spacing: 8px;">
                <tr style="width: 100%">
                  <td style="width: 60%"><b>Marked Breakdown</b></td>
                  <td style="width: 40%" *ngIf="driverAbsenteeismDetails">
                    <b>{{driverAbsenteeismDetails.markedBreakDown}}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </p-card>
      </div>
    </p-panel>
    
    <div *ngFor="let dutyDenied of dutyDeniedPenaltyDetails; index as i">
      <p-panel header="Ride Details_{{i+1}}" styleClass="ride-details" 
      [toggleable]="true"
      [collapsed]="true" 
      *ngIf="dutyDenied">
        <div style="margin-bottom: 8px">
          <p style="margin: 8px 0 12px 0;">Driver Denied Duty</p>
          <p-card>
            <div class="penalty-details">
              <div class="penalty-data">
                <table style="width: 100%; border-spacing: 8px;">
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>Ride Id</b></td>
                    <td style="width: 40%" *ngIf="dutyDenied"><b>{{dutyDenied.rideId}}</b></td>
                  </tr>
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>SOC</b></td>
                    <td style="width: 40%" *ngIf="dutyDenied"><b>{{dutyDenied.soc}}</b></td>
                  </tr>
                </table>
              </div>
              <div class="penalty-data">
                <table style="width: 100%; border-spacing: 8px;">
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>Ride status</b></td>
                    <td style="width: 40%" *ngIf="dutyDenied"><b>{{dutyDenied.rideStatus}}</b></td>
                  </tr>
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>Next driver assignment time</b></td>
                    <td style="width: 40%" *ngIf="dutyDenied"><b>{{ getLocalDate(dutyDenied.nextDriverAssignmentTime) | date :
                        "medium"}}</b></td>
                  </tr>
                </table>
              </div>
              <div class="penalty-data">
                <table style="width: 100%; border-spacing: 8px;">
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>Official duty end time</b></td>
                    <td style="width: 40%" *ngIf="dutyDenied"><b>{{ getLocalDate(dutyDenied.officialDutyEndTime) | date :
                    "medium"}}</b></td>
                  </tr>
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>Driver assigned time</b></td>
                    <td style="width: 40%" *ngIf="dutyDenied"><b>{{ getLocalDate(dutyDenied.driverAssignedTime) | date :
                    "medium"}}</b>
                    </td>
                  </tr>
                  <tr style="width: 100%">
                    <td style="width: 60%"><b>Action</b></td>
                    <span *ngIf="dutyDenied" class="ui-float-label"><button
                        [disabled]="isDutyDeniedPenaltyBtnDisabled(dutyDenied)" pButton class="submit" label="Reverse"
                        (click)="returnDutyDeniedAmount(dutyDenied)"></button></span>
                  </tr>
                </table>
              </div>
            </div>
          </p-card>
        </div>
        <p-panel
            styleClass="rfs-details" 
            [toggleable]="true"
            [collapsed]="true"
            *ngIf="dutyDenied">
          <p-header>
            <span>
              No. of RFS 
              <span class="dutyDeniedRfsNo" *ngIf="dutyDenied && dutyDenied.noOfRequestForSupport">
               {{dutyDenied.noOfRequestForSupport}}</span>
            </span>
          </p-header> 
          <div *ngFor="let driverRfsDetails of nofOfRfsNumber(dutyDenied)">
            <div style="margin-bottom: 4px">
              <p-card *ngIf="driverRfsDetails">
                <div class="penalty-details">
                  <div class="penalty-data">
                    <table style="width: 100%; border-spacing: 8px;">
                      <tr style="width: 100%">
                        <td style="width: 60%"><b>Request Type</b></td>
                        <td style="width: 40%" *ngIf="driverRfsDetails"><b>{{driverRfsDetails.requestType}}</b></td>
                      </tr>
                      <tr style="width: 100%">
                        <td style="width: 60%"><b>Request Status</b></td>
                        <td style="width: 40%" *ngIf="driverRfsDetails"><b>{{driverRfsDetails.requestStatus}}</b></td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="penalty-details">
                  <div class="penalty-data">
                    <table style="width: 100%; border-spacing: 8px;">
                      <tr style="width: 100%">
                        <td style="width: 60%"><b>RFS updated timestamp</b></td>
                        <td style="width: 40%" *ngIf="driverRfsDetails"><b>{{ getLocalDate(driverRfsDetails.rfsUpdatedTimestamp) | date :
                        "medium"}}</b></td>
                      </tr>
                      <tr style="width: 100%">
                        <td style="width: 60%"><b>RFS created timestamp</b></td>
                        <td style="width: 40%" *ngIf="driverRfsDetails"><b>{{ getLocalDate(driverRfsDetails.rfsCreatedTimestamp) | date :
                        "medium"}}</b></td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="penalty-details">
                  <div class="penalty-data">
                    <table style="width: 100%; border-spacing: 8px;">
                      <tr style="width: 100%">
                        <td style="width: 60%"><b>Rejected reason</b></td>
                        <td style="width: 40%" *ngIf="driverRfsDetails"><b>{{driverRfsDetails.rejectedReason}}</b></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </p-card>
            </div>
          </div>
        </p-panel>
        <p-panel header="Drivers call" styleClass="rfs-details" 
        [toggleable]="true" 
        [collapsed]="true"
        *ngIf="dutyDenied">
          <p-table class="call-log-items" [value]="dutyDenied.driverCallLogDetailResponses" [columns]="driverRfsCallLogCols"
            [scrollable]="true">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width:20%; background-color: #353434; border-color: #030303" *ngFor="let col of columns">
                  <div class="header-items">
                    {{ col.header }}
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex" let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <div class="body-items" *ngIf="col.field === 'player' && data[col.field] !== 'Missed'">
                      <button pButton class="submit" label="Play" (click)="playAudio(data[col.field])"></button>
                  </div>
                  <div class="body-items"
                    *ngIf="col.field === 'player' && data[col.field] === 'Missed'">
                    <div>Missed</div>
                  </div>
                  <div class="body-items" *ngIf="col.field !== 'player'">
                    {{ data[col.field] }}
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </p-panel>
    </div>

    <p-panel
      header="Ticket Details"
      [toggleable]="true"
      *ngIf="ticket.project != 'driverPartnerTMS'"
    >
      <div class="horizontal-allignment">
        <div class="ticket-details">
          <p-card header="Basic Details">
            <table style="width: 100%">
              <tr style="width: 100%">
                <td style="width: 40%"><b>Ticket ID </b></td>
                <td style="width: 60%">
                  {{ ticket.ticketId }}
                  <i
                    *ngIf="ticket.colour && ticket.colour != ''"
                    [ngStyle]="{ color: ticket.colour }"
                    class="fa fa-flag"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Category</b></td>
                <td style="width: 60%">
                  {{ ticket.category }}
                  <!--                   <i class="fa fa-pencil" *ngIf="ticket.currentStage == 'Ticket Created'" aria-hidden="true" (click)="openEditCategoryModal()" style="position: relative;left: 10px;cursor: pointer;"></i> -->
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Hub</b></td>
                <td style="width: 60%">{{ ticket.hubName }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Current Stage</b></td>
                <td style="width: 60%">{{ ticket.currentStage }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Due By</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket.dueDate) | date : "medium" }}
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Created By</b></td>
                <td style="width: 60%">{{ ticket.createdByName }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Created Date</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket.createdAt) | date : "medium" }}
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Last Updated By</b></td>
                <td style="width: 60%">{{ ticket.updatedByName }}</td>
              </tr>
              <tr style="width: 100%">
                <td style="width: 40%"><b>Last Updated Date</b></td>
                <td style="width: 60%">
                  {{ getLocalDate(ticket.updatedAt) | date : "medium" }}
                </td>
              </tr>
            </table>
          </p-card>
        </div>
        <div class="ticket-details">
          <p-card header="Issue Details">
            <table style="width: 100%">
              <tr
                style="width: 100%"
                *ngFor="let issue of ticket['formData'] | keyvalue"
              >
                <td
                  style="width: 40%"
                  *ngIf="issue.key != 'typeId' && issue.key != 'team'"
                >
                  <b>{{ convertToNormalCase(issue.key) }}</b>
                </td>
                <td
                  *ngIf="
                    isObject(issue.value) == 'PRIMITIVE' &&
                    issue.key != 'file' &&
                    issue.key != 'typeId' &&
                    issue.key != 'Date of Service' &&
                    issue.key != 'Date Of Issue' &&
                    issue.key != 'team'
                  "
                  style="width: 60%"
                >
                  {{ issue.value }}
                </td>
                <td
                  *ngIf="
                    isObject(issue.value) == 'PRIMITIVE' &&
                    issue.key != 'file' &&
                    issue.key != 'typeId' &&
                    (issue.key == 'Date of Service' ||
                      issue.key == 'Date Of Issue')
                  "
                  style="width: 60%"
                >
                  {{ getLocalDate(issue.value) | date : "medium" }}
                </td>
                <!--                 <td *ngIf="isObject(issue.value) == 'PRIMITIVE' && issue.key != 'file' && issue.key != 'typeId' && issue.key != 'Date of Service' && issue.key != 'Date Of Issue'" style="width: 60%;">Repair : {{issues}}</td> -->
                <!--                 <td *ngIf="isObject(issue.value) == 'PRIMITIVE' && issue.key != 'file' && issue.key != 'typeId' && issue.key != 'Date of Service' && issue.key != 'Date Of Issue'" style="width: 60%;"> Sub-Issue :{{subIssues}}</td> -->
                <td
                  *ngIf="
                    isObject(issue.value) == 'PRIMITIVE' && issue.key === 'file'
                  "
                  class="hyperlink"
                  style="width: 60%"
                  (click)="viewImage('DriverIssue')"
                >
                  View Attached File
                </td>

                <td
                  *ngIf="issue.value && isObject(issue.value) == 'OBJECT'"
                  style="width: 60%"
                >
                  <div style="display: flex; flex-direction: column">
                    <div
                      *ngFor="let key of object.keys(issue.value)"
                      style="display: flex"
                    >
                      <div
                        *ngIf="issue.value[key]"
                        style="flex-direction: row; display: flex"
                      >
                        <div style="display: flex; margin: 0px">
                          <b>{{ convertToNormalCase(key) }}</b> -
                        </div>
                        <div style="display: flex; margin: 0px 5px">
                          {{ issue.value[key] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  *ngIf="
                    issue.value && isObject(issue.value) == 'ARRAY_OF_OBJECT'
                  "
                  style="width: 60%"
                >


                  <div style="display: flex; flex-direction: row">
                    <table style="width: 100%; border: 1px solid white">
                      <!-- <tr style="width: 100%;">
                        <th *ngFor="let headers of getHeaders(issue.value)" style="border:1px solid white">
                          {{convertToNormalCase(headers)}}
                        </th>
                      </tr>
                     <tr style="width: 100%;" *ngFor="let obj of issue.value">
                        <td *ngFor="let key of getHeaders(issue.value)" style="border:1px solid white">
                          {{obj[key]}}
                        </td>
                      </tr>   -->
                      <!-- <div *ngFor="let key of object.keys(issue.value)" style="display: flex;">
                      <div *ngIf="issue.value[key]" style="flex-direction:row;display:flex;">
                        <div style="display: flex;margin:0px"><b>{{convertToNormalCase(key)}} </b> - </div>
                        <div style="display: flex;margin:0px 5px"> {{issue.value[key]}}</div>
                      </div>
                    </div> -->
                    </table>
                  </div>
                </td>
              </tr>
              <tr *ngIf="ticket.project == 'operationsTMS'">
                <b>Vehicle Status</b>
                <td>
                  {{vehicleStatus}} | Checked out hub - {{checkedOutHub  ?
                    hubList[checkedOutHub]["name"] : ""
                    }}
                </td>
              </tr>
            </table>
          </p-card>
        </div>
      </div>
    </p-panel>

    <p-panel
      header="Files and resources"
      [toggleable]="true"
      *ngIf="ticket.files && ticket.files.length"
    >
      <div class="FileResource scroll">
        <div *ngFor="let file of ticket.files">
          <ng-container>
            <ng-container [ngSwitch]="file.mimetype.split('/')[0]">
              <ng-container *ngSwitchCase="'image'">
                <a [href]="file.url" [download]="file.name">
                  <img [src]="file.url" [alt]="file.name" />
                </a>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="other-file">
                  <a [href]="file.url" [download]="file.name">
                    <i
                      style="font-size: 3em"
                      [class]="
                        'pi ' +
                        (file.mimetype.includes('audio') ||
                        file.mimetype.includes('video')
                          ? 'pi-play'
                          : 'pi-file')
                      "
                    >
                    </i>
                    <span>
                      {{
                        file.mimetype.includes("audio") ||
                        file.mimetype.includes("video")
                          ? file.mimetype.split("/")[0]
                          : file.mimetype.split("/")[1]
                      }}
                    </span>
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </p-panel>

    <p-panel
      *ngIf="showWorkItemDetails && WorkItemDetails?.length"
      header="Work item details"
      [toggleable]="true"
    >
      <div class="container">
        <div
          class="row"
          *ngFor="let row of WorkItemDetails; let i = index"
          [hidden]="i % 2 !== 0"
        >
          <div
            class="col-sm work-item"
            *ngIf="WorkItemDetails[i] !== undefined"
          >
            <div>
              <span>{{ i + 1 }}. Workgroup - </span>
              <span class="work-item-name">
                {{ WorkItemDetails[i].workgroupName }}</span
              >
            </div>

            <div>
              <span class="work-item-list">Work item - </span>
              <span class="work-item-name">{{
                WorkItemDetails[i].workitemName.join(", ")
              }}</span>
            </div>
          </div>
          <div
            class="col-sm work-item"
            *ngIf="WorkItemDetails[i + 1] !== undefined"
          >
            <div>
              <span>{{ i + 2 }}. Workgroup - </span>
              <span class="work-item-name">
                {{ WorkItemDetails[i + 1].workgroupName }}</span
              >
            </div>

            <div>
              <span class="work-item-list">Work item - </span>
              <span class="work-item-name">{{
                WorkItemDetails[i + 1].workitemName
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </p-panel>

    <p-panel
      header="Action Items"
      [toggleable]="true"
      *ngIf="
        (currentStageData &&
          currentStageData.stageName != 'Ticket Closed' &&
          currentStageData.stageName != 'Closed' &&
          canUserTakeAction) ||
        (ticket['category'].includes('Preventive Services') &&
          PMSStages.includes(ticket.currentStage) &&
          canUserTakeAction)
      "
    >
      <p-toolbar
        *ngIf="
          currentStageData &&
          currentStageData.stageName != 'Ticket Closed' &&
          canUserTakeAction
        "
      >
        <div
          class="ui-toolbar-group-right"
          *ngIf="allowedToEdit && ticket.project != 'driverPartnerTMS'"
        >
          <!--<button pButton type="button" label="File Edit" (click)="fileUpdateDialog()"></button>-->
          <!--               <button pButton type="button" *ngIf="ticket.currentStage=='Ticket Created' && roleName=='Shift Incharge'"  label="Edit" (click)="editTicketForm()"></button> -->
        </div>
        <div
          class="ui-toolbar-group-right"
          *ngIf="currentStageData['stageNote']"
        >
          <span style="font-weight: 700; color: #ff5656"
            >Note to Hub Manager:</span
          >
          {{ currentStageData["stageNote"] }}
        </div>
        <div style="display: flex; justify-content: space-between">
          <div
            class="workshop-details"
            *ngIf="((ticket.project == 'operationsTMS') && (ticket.currentStage == 'Workshop pickup driver assigned' || ticket.currentStage == 'MD arrived at workshop' || ticket.currentStage == 'Ride canceled by MD'))"
          >
            <span>
              <strong>{{
                ticket.centerLocation.pickUpDriverName ? "Pickup Driver: " : ""
              }}</strong>
              {{
                ticket.centerLocation.pickUpDriverName
                  ? ticket.centerLocation.pickUpDriverName
                  : ""
              }}

              <strong>
                {{
                  ticket.centerLocation.returningScheduleTimestamp
                    ? "| Date & Time: "
                    : ""
                }}
              </strong>
              {{
                ticket.centerLocation.returningScheduleTimestamp
                  ? checkTime(ticket.centerLocation.returningScheduleTimestamp)
                  : ""
              }}
            </span>

            <span
              *ngIf="roleName == 'MSE' && (ticket.centerLocation.pickUpDriverId)"
              class="edit-details"
              (click)="
                editPickupDriver(ticket.centerLocation, ticket.currentStage)
              "
            >
              <i class="pi pi-pencil"></i> Edit
            </span>
          </div>
          <div
            class="workshop-details"
            *ngIf="
              (ticket.currentStage == 'Car sent to workshop' ||
              ticket.currentStage == 'Hub pickup driver assigned') && 
              country !== 'AE'
            "
          >
            <span class="workshop-info">
              <strong>{{
                ticket.centerLocation.workshopName ? "Workshop: " : ""
              }}</strong
              >{{
                ticket.centerLocation.workshopName
                  ? ticket.centerLocation.workshopName
                  : ""
              }}

              <strong>
                {{ ticket.centerLocation.driverName ? "| Hub driver: " : "" }}
              </strong>
              {{
                ticket.centerLocation.driverName
                  ? ticket.centerLocation.driverName
                  : ""
              }}

              <strong>
                {{
                  ticket.centerLocation.scheduleTimestamp
                    ? "| Date & time: "
                    : ""
                }}
              </strong>
              {{
                ticket.centerLocation.scheduleTimestamp
                  ? checkTime(ticket.centerLocation.scheduleTimestamp)
                  : ""
              }}
            </span>

            <span
              *ngIf="roleName == 'MSE'"
              class="edit-details"
              (click)="
                editWorkshopDialog(ticket.centerLocation, ticket.currentStage)
              "
            >
              <i class="pi pi-pencil"></i> Edit
            </span>
          </div>

          <div
            *ngIf="
              ticket.project == 'driverPartnerTMS' &&
              (ticket.currentStage == 'In-Progress' ||
                ticket.currentStage == 'Reopen' ||
                ticket.currentStage == 'Ticket Created')
            "
          >
            <span *ngIf="!isFormExist">
              <p-dropdown
                appendTo="body"
                [options]="reasonListData"
                (onChange)="changeReason(selectedCategory)"
                [(ngModel)]="selectedCategory"
                optionLabel="label"
                placeholder="Choose reason to decline"
              >
              </p-dropdown>
            </span>
            <button
              *ngIf="!isFormExist"
              pButton
              type="button"
              label="Discard"
              (click)="openStageForm('Discard', true)"
            ></button>
          </div>
          <div *ngIf="!hideData">
            <div *ngIf="ticket.project != 'operationsTMS'">
              <div
                class="ui-toolbar-group-right"
                *ngFor="let stage of currentStageData['children']"
              >
                <button
                  *ngIf="
                    stage != 'Driver Assigned' &&
                    stage != 'Ticket Created' &&
                    !isFormExist
                  "
                  style="border-radius: 5px"
                  pButton
                  type="button"
                  label="{{ getStageName(stage) }}"
                  (click)="openStageForm(stage, true)"
                ></button>
              </div>
            </div>
            <div
              *ngIf="ticket.project == 'operationsTMS'"
              style="display: flex"
            >
              <!-- *ngIf="roleName == currentStageData['role'][0]" -->

              <div
                style="display: flex"
                *ngIf="roleName == currentStageData['role'][0]"
              >
                <p
                  *ngIf="
                    ticket.currentStage == 'Due for hub review' && TiReason
                  "
                  style="
                    border: 1px white solid;
                    font-size: 11px;
                    padding: 6px;
                    height: auto;
                    border-radius: 7px;
                    width: 250px;
                  "
                >
                  {{ TiReason }}
                </p>
                <div
                  *ngIf="showViewImage"
                  (click)="showViewImageAttachments()"
                  class="view-image"
                >
                  <i class="fa fa-image mr-1"></i>
                  <span>View image</span>
                </div>
              </div>
              <div>
                <div
                  class="ui-toolbar-group-right"
                  *ngFor="let stage of currentStageData['children']"
                >
                  <div
                    *ngIf="
                      roleName == currentStageData['role'][0] &&
                      stage != 'Assign pickup driver' &&
                      stage != 'Close Ticket'
                    "
                  >
                    <p-button
                      class="assignPickupDriverCTA"
                      type="button"
                      label="{{ getStageName(stage) }}"
                      (click)="openStageForm(stage, true)"
                    ></p-button>
                  </div>
                  <div
                    *ngIf="
                      stage == 'Assign pickup driver' &&
                      roleName == 'MSE' &&
                      ticket &&
                      ticket.centerLocation &&
                      !ticket.centerLocation.pickUpDriverId
                    "
                  >
                    <button
                      style="border-radius: 5px"
                      pButton
                      type="button"
                      label="Assign pickup driver"
                      (click)="openPickUpDialog(ticket.centerLocation, stage)"
                    ></button>
                  </div>
                  <div
                    *ngIf="
                      stage == 'Close Ticket' && roleName == 'Shift Incharge'
                    "
                  >
                    <button
                      style="border-radius: 5px"
                      pButton
                      type="button"
                      label="Close Ticket"
                      (click)="openStageForm(stage, true)"
                    ></button>
                  </div>
                  <!-- <button
                    *ngIf="
                      stage != 'Driver Assigned' && stage != 'Ticket Created'
                    "
                    style="border-radius: 5px"
                    pButton
                    type="button"
                    label="{{ getStageName(stage) }}"
                    (click)="openStageForm(stage)"
                  ></button> -->
                </div>
              </div>
            </div>
          </div>
          <!--
          <div *ngIf="hideData && !showOdometer">
            <span class=".ui-dropdown-label">
              <p-dropdown
                appendTo="body"
                [options]="serviceCenterList"
                [(ngModel)]="selectedServiceCenter"
                optionLabel="label"
                [style]="{ width: '100%' }"
                placeholder="Select workshop"
              >
              </p-dropdown>
            </span>
            <button
              style="
                width: 93px;
                height: 35px;
                border: 1px solid white;
                border-radius: 10px;
                color: white;
                background-color: #116fbf;
              "
              (click)="submitStageForm()"
            >
              Confirm
            </button>
          </div> -->
          <div *ngIf="showOdometer && hideData" style="display: flex">
            <span class="ui-float-label">
              <input
                id="float-input"
                [(ngModel)]="odometerReading"
                type="number"
                value="0"
                style="width: 12rem"
                pInputText
                required
              />
              <label style="color: white" for="float-input"
                >Enter Odometer Reading</label
              >
            </span>
            <button
              style="
                width: 93px;
                height: 35px;
                border: 1px solid white;
                border-radius: 10px;
                color: white;
                background-color: #116fbf;
              "
              (click)="showOdometerPopup()"
            >
              Submit
            </button>
          </div>
        </div>

        <div
          style="display: flex; justify-content: center"
          *ngIf="
            isFormExist &&
            ticket.currentStage != 'Resolved' &&
            ticket.currentStage != 'Closed' &&
            ticket.currentStage != 'Discard'
          "
        >
          <div style="display: flex; justify-content: space-between">
            <button
              pButton
              type="button"
              label="Accept"
              [disabled]="disableButton"
              (click)="ticketAction(ticket, 'Accept')"
            ></button>
            <button
              pButton
              type="button"
              label="Reject"
              [disabled]="disableButton"
              (click)="ticketAction(ticket, 'Reject')"
            ></button>
          </div>
        </div>

        <div
          style="display: flex; justify-content: center"
          *ngIf="
            currentStageData.children.includes('Select Driver') ||
            currentStageData.stageName != 'Ticket Closed'
          "
        >
          <h6 class="message">{{ message }}</h6>
        </div>

        <div
          *ngIf="
          ticket.project == 'operationsTMS' &&
          currentStageData['role'].includes(roleName) &&
          canUserTakeAction && 
          currentStageData">
          <div *ngIf="currentStageData && currentStageData.stageName === 'Sent by crane'" class="sendByCraneBox">
            <div>
              <span class="message">Workshop: {{ticket.centerLocation.workshopName}} | Crane type: {{ticket.centerLocation.craneName}}</span>
              <a class="editCTA" (click)="openEditSendByCrane()" *ngIf="!isStagePresent('RECEIVED_AT_WORKSHOP')">
                <i class="fa fa-pencil-square-o"></i> Edit
              </a>
            </div>

            <div>
              <p-button label="Assign pickup driver" class="assignPickupDriverCTA"
                (click)="openPickUpDialog(ticket.centerLocation, 'Assign pickup driver')"></p-button>
            </div>
          </div>
        </div>
      </p-toolbar>
      <p-toolbar *ngIf="PMSStages.includes(ticket.currentStage)">
        <div
          class="ui-toolbar-group-right"
          *ngIf="allowedToEdit && ticket.currentStage != 'Ticket Closed'"
        >
          <!--<button pButton type="button" label="File Edit" (click)="fileUpdateDialog()"></button>-->
          <button
            pButton
            type="button"
            label="Edit"
            (click)="editTicketForm()"
          ></button>
        </div>
        <div *ngIf="ticket.currentStage == 'Driver Assigned'">
          <div
            class="ui-toolbar-group-right"
            *ngIf="ticket.isRideAssigned == false"
          >
            <span style="padding-right: 2px"
              >Driver Partner: {{ ticket.assignedDriverName }} - ({{
                ticket.driverPunchId
              }})
              <br />
              <span style="font-weight: 700; color: #ff5656"
                >Note to Hub Manager:</span
              >
              Select Serive centre and create a ride
            </span>
            <!-- <p-dropdown [options]="serviceCenterList" [(ngModel)]="selectedServiceCenter" optionLabel="label" placeholder="Select service center"> </p-dropdown> -->
            <button
              pButton
              type="button"
              label="Create Ride"
              (click)="createRide('CREATE_RIDE')"
            ></button>
          </div>
          <div
            class="ui-toolbar-group-right"
            *ngIf="ticket.isRideAssigned == true"
          >
            <span>
              <strong> Driver Partner: </strong>
              {{ ticket.assignedDriverName }} - ({{
                ticket.driverPunchId
              }})</span
            ><br />
            <span style="font-weight: 700; color: #ff5656"
              >Note to Hub Manager:</span
            >
            A dummy Ride will be visible on driver app as soon as the driver
            Select "Go on Hub duty"
          </div>
        </div>
        <!-- <div class="ui-toolbar-group-right" *ngIf="ticket.currentStage == 'Awaiting Job Card Approval'">
              <span *ngIf="!ticket.isReviewJobCardUploaded"><span style="font-weight: 700;color: #ff5656;">Note to Hub
                  Manager:</span> Request the driver partner to upload the image of the job card before giving key</span>
              <button pButton type="button" label="Review Job Card" [disabled]="!ticket.isReviewJobCardUploaded"
                (click)="reviewDialogBox('JOB_CARD')"></button>
            </div> -->
        <div
          class="ui-toolbar-group-right"
          *ngIf="ticket.currentStage == 'Vehicle at workshop'"
        >
          <!-- <span *ngIf="ticket.formData['PMS Flow']=='OLD' || ticket.formData['PMS Flow'] == null">
                <button pButton type="button" label="Job Card" [disabled]="!ticket.approvedJobCard"
                  (click)="viewImage('JOB_CARD')" style="margin-right: 15px;"></button>
              </span> -->
          <button
            *ngIf="ticket.assignedDriverName"
            pButton
            type="button"
            label="Send driver to workshop"
            (click)="createRide('SEND_DRIVER_TO_WORKSHOP')"
          ></button>
        </div>
        <!-- <div class="ui-toolbar-group-right"
              *ngIf="ticket.currentStage == 'Review Service Challan' && (ticket.formData['PMS Flow']=='OLD' || ticket.formData['PMS Flow'] == null)">
              <button pButton type="button" label="Job Card" [disabled]="!ticket.approvedJobCard"
                (click)="viewImage('JOB_CARD')" style="margin-right: 20px;"></button>
              <button pButton type="button" label="Review Service Challan" [disabled]="!ticket.isServiceChallanUploaded"
                (click)="reviewDialogBox('SERVICE_CHALLAN')"></button>
            </div> -->
        <div
          class="ui-toolbar-group-right"
          *ngIf="ticket.currentStage == 'Vehicle Received'"
        >
          <!-- <span *ngIf="ticket.formData['PMS Flow']=='OLD' || ticket.formData['PMS Flow'] == null">
                <button pButton type="button" label="Job Card" [disabled]="!ticket.approvedJobCard"
                  (click)="viewImage('JOB_CARD')" style="margin-right: 20px;"></button>
                <button pButton type="button" label="Service Challan" [disabled]="!ticket.approvedServiceChallan"
                  (click)="viewImage('SERVICE_CHALLAN')" style="margin-right: 20px;"></button>
              </span> -->
          <button
            pButton
            type="button"
            label="Received Car from workshop"
            (click)="receivedCard()"
          ></button>
        </div>
        <!-- <div
              *ngIf="(ticket.currentStage =='Received Car from workshop' || ticket.currentStage =='Ticket Closed') && (ticket.formData['PMS Flow']=='OLD' || ticket.formData['PMS Flow'] == null)">
              <button pButton type="button" label="Job Card" [disabled]="!ticket.approvedJobCard"
                (click)="viewImage('JOB_CARD')" style="margin-right: 20px;"></button>
              <button pButton type="button" label="Service Challan" [disabled]="!ticket.approvedServiceChallan"
                (click)="viewImage('SERVICE_CHALLAN')" style="margin-right: 20px;"></button>
            </div> -->
      </p-toolbar>
    </p-panel>
    <p-panel
      header="Ticket History"
      [toggleable]="true"
      [style]="{ 'margin-bottom': '20px' }"
    >
      <p-table [value]="ticket.stageData">
        <ng-template pTemplate="header">
          <tr>
            <th>Action Performed</th>
            <th>Updated Date</th>
            <th>Updated Time</th>
            <th>Updated By</th>
            <th>Action Data</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="tr">
            <td>{{ data.stageName }}</td>
            <td>{{ getLocalDate(data.createdTime) | date : "mediumDate" }}</td>
            <td>{{ getLocalDate(data.createdTime) | date : "shortTime" }}</td>
            <td *ngIf="data.actionTakenByName != 'undefined'">
              {{ data.actionTakenByName }}
            </td>
            <td *ngIf="data.actionTakenByName == 'undefined'">
              {{ data.actionTakenBy }}
            </td>
            <td style="word-break: break-all">
              <span *ngFor="let keyValuePairs of data['stageActionData']">
                <span *ngFor="let keyValuePair of keyValuePairs | keyvalue"
                  >{{ keyValuePair.key }} -
                  <span
                    *ngIf="
                      keyValuePair.key == 'Refund' ||
                      keyValuePair.key == 'Amount' ||
                      keyValuePair.key == 'Insurance'
                        ? true
                        : false
                    "
                    >&#8377;</span
                  >
                  {{ keyValuePair.value }}
                </span>
              </span>
              <div *ngIf="data.files && data.files.length" >
                <u (click)="attachmentDisplay(data)" class="attachment">
                  Attachment
                </u>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-panel>
  </div>
</div>

<!--Sent by crane dialog-->
<p-dialog
  class="sendByCraneDailog"
  [(visible)]="sendByCraneDailog"
  [model]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [style]="{ 'max-width': '302px' }"
  [resizable]="false"
  [responsive]="true"
  (onHide)="onDialogClose()"
>

  <img src="assets/blue-car.svg" class="img-src" />
  <h4 class="img-text">{{ getSelectedStageTitle() }}</h4>

  <!--loader-->
  <div
    style="display: flex; flex-direction: column; padding: 24px; align-items: center;"
    *ngIf="workshopCraneTypeForm.isLoading"
  >
    <ng-container>
      <div class="spinner-border" style="height: 13px; width: 13px; color: white;"  role="status"></div>
      <span style="font-size: 12px; margin-left: 10px; color: white;">Loading</span>
    </ng-container>
  </div>


  <!--Sent by crane Workshop and Cranetype form-->  
  <div 
    class="img-form-fields"
    *ngIf="!workshopCraneTypeForm.isLoading"
  >
    <div class="form-group">
      <label for="workshop">Workshop <span class="astrick">*</span></label>
      <br />
      <p-dropdown appendTo="body" [options]="workshopCraneTypeForm.workshopOptions"
        [(ngModel)]="workshopCraneTypeForm.workshop" placeholder="Select workshop" [required]="true"
        [style]="{ width: '100%' }">
      </p-dropdown>
    </div>
    <div class="form-group">
      <label for="workshop">Crane Type <span class="astrick">*</span></label>
      <br />
      <p-dropdown appendTo="body" [options]="workshopCraneTypeForm.craneTypeOptions"
        [(ngModel)]="workshopCraneTypeForm.craneType" placeholder="Select crane type" [required]="true"
        [style]="{ width: '100%' }">
      </p-dropdown>
    </div>
    <div class="form-btn">
      <p-button label="Submit" type="button" class="submit"
        [disabled]="!workshopCraneTypeForm.workshop || !workshopCraneTypeForm.craneType"
        (click)="submitWorkshopCraneForm()">
      </p-button>
    </div>
  </div>
</p-dialog>

<p-dialog
  class="submitStage"
  header="Stage Submission"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div *ngIf="selectedStageData">
    <p *ngIf="selectedStageData.elements.length == 0">
      Are you sure you want to continue?
    </p>
    <div *ngIf="selectedStageData.elements.length != 0">
      <p style="padding: 1rem">Please enter the following details and submit</p>
      <div
        *ngIf="selectedStageData.elements.includes('Resolution Notes')"
        style="padding-top: 25px"
      >
        <span class="ui-float-label">
          <input
            id="float-input"
            [(ngModel)]="resolutionNotes"
            type="text"
            value="0"
            style="width: 100%"
            pInputText
            required
          />
          <label style="color: white" for="float-input"
            >Enter Resolution Notes</label
          >
        </span>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Discard Reason')"
        style="padding-top: 25px"
      >
        <!--   <div style="margin-bottom: 20px;">
          <p-dropdown [options]="reasonListData" [style]="pDropdownStyleForIssues" (onChange)="searchWithFilters()" [(ngModel)]="selectedReason"
            placeholder="Choose reason to decline ticket"></p-dropdown>
        </div>  -->

        <span class="ui-float-label" *ngIf="selectedReason == 'Other'">
          <input
            id="float-input"
            [(ngModel)]="discardReason"
            type="text"
            value="0"
            style="width: 100%"
            pInputText
            required
          />
          <label style="color: white" for="float-input"
            >Enter Decline Reason</label
          >
        </span>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Odometer Reading')"
        style="padding-top: 25px"
      >
        <span class="ui-float-label">
          <input
            id="float-input"
            [(ngModel)]="odometerReading"
            type="number"
            value="0"
            style="width: 12rem"
            pInputText
            required
          />
          <label style="color: white" for="float-input"
            >Enter Odometer Reading</label
          >
        </span>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Amount')"
        style="padding-top: 25px"
      >
        <span class="ui-float-label">
          <input
            id="float-input"
            [(ngModel)]="amount"
            type="number"
            value="0"
            style="width: 100%"
            pInputText
            required
          />
          <label style="color: white" for="float-input">Enter Amount</label>
        </span>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Refund')"
        style="padding-top: 25px"
      >
        <span class="ui-float-label">
          <input
            id="float-input"
            [(ngModel)]="refund"
            type="text"
            value="0"
            style="width: 100%"
            pInputText
            required
          />
          <label style="color: white" for="float-input">Enter Refund</label>
        </span>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Remark')"
        style="padding-top: 25px"
      >
        <span
          *ngIf="selectedStageData.elements.includes('Remark')"
          class="ui-float-label"
        >
          <input
            id="float-input"
            [(ngModel)]="remark"
            type="text"
            value="0"
            style="width: 100%"
            pInputText
            required
          />
          <label style="color: white" for="float-input">Enter Remark</label>
        </span>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Service Location')"
        style="padding-left: 30%"
      >
        <span class=".ui-dropdown-label">
          <p-dropdown
            appendTo="body"
            [options]="serviceCenterList"
            [(ngModel)]="selectedServiceCenter"
            optionLabel="label"
            [style]="{ width: '100%' }"
            placeholder="Select service center"
          >
          </p-dropdown>
        </span>
      </div>
      <div
        *ngIf="
          selectedStageData.stageName.includes('Car sent to workshop') ||
          selectedStageData.elements.includes('Driver List')
        "
        style="padding-left: 30%"
      >
        <span class=".ui-dropdown-label">
          <p-dropdown
            appendTo="body"
            [options]="DriverList"
            [(ngModel)]="selectedMaintenanceDriver"
            optionLabel="label"
            [style]="{ width: '100%' }"
            placeholder="Select Maintenance Driver"
          >
          </p-dropdown>
        </span>
      </div>
      <br />
      <div
        *ngIf="
          selectedStageData.stageName.includes('Car sent to workshop') ||
          selectedStageData.elements.includes('Driver List')
        "
        style="padding-left: 30%"
      >
        <p-calendar
          styleClass="my-calendar"
          [(ngModel)]="schedule"
          [minDate]="currentDate"
          showTime="true"
          [hourFormat]="12"
          appendTo="body"
          [hourFormat]="24"
          [style]="{
            height: '400px',
            'max-height': 'none',
            overflow: 'visible',
            'z-index': '110000'
          }"
        ></p-calendar>
      </div>
      <div
        *ngIf="selectedStageData.elements.includes('Insurance')"
        style="padding-top: 25px"
      >
        <span class="ui-float-label">
          <input
            id="float-input"
            [(ngModel)]="insurance"
            type="number"
            value="0"
            style="width: 100%"
            pInputText
            required
          />
          <label style="color: white" for="float-input"
            >Enter Insurance Claim Amount</label
          >
        </span>
      </div>
    </div>
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="submitStageForm()"
      label="Submit"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      (click)="displayModal = false"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  class="submitNotes"
  [(visible)]="resolutionDialog"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div *ngIf="selectedStageData">
    <div
      [ngStyle]="{ padding: showResolution ? '1.5rem' : '2rem 1rem 8rem 1rem' }"
      style="padding: 1.5rem"
    >
      <p>Please enter the following details</p>
      <div
        *ngIf="selectedStageData.elements.includes('Resolution Notes')"
        style="padding-top: 25px"
      >
        <span class="reason-dropdown">
          <span class="reason-label">Select reason code</span>
          <p-dropdown
            appendTo="body"
            styleClass="dropdown-style"
            [options]="reasonCodeList"
            (onChange)="getSelectedCode($event)"
            [(ngModel)]="selectedCode"
            optionLabel="label"
            placeholder="Select"
            [style]="{ width: '100%' }"
          >
          </p-dropdown>
        </span>
        <div *ngIf="showResolution" class="field">
          <span class="reason-label">Resolution Remark</span>
          <textarea
            rows="8"
            cols="30"
            pInputTextarea
            [(ngModel)]="resolutionNotes"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <p-footer class="footer-btn">
    <button
      type="button"
      pButton
      (click)="submitStageForm()"
      [disabled]="isBtnDisable"
      label="Resolve"
    ></button>
    <button
      type="button"
      class="cancel-btn"
      pButton
      (click)="resolutionDialog = false"
      label="Cancel"
    ></button>
  </p-footer>
</p-dialog>
<p-dialog
  class="submitStage"
  [(visible)]="changeRequest"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p>Are you sure you want to continue?</p>

  <p-footer>
    <button
      type="button"
      pButton
      label="Submit"
      (click)="resolveTicket()"
    ></button>
    <button
      type="button"
      pButton
      (click)="changeRequest = false"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>

<p-toast></p-toast>

<p-dialog
  class="editTicket"
  header="Update ticket"
  [modal]="true"
  [(visible)]="displayUpdateTicket"
  (onHide)="restoreStateOfUpdate()"
>
  <div class="editTicket">
    <app-form-renderer
      [formConfig]="dynamicFormBasicConfiguration"
      (formSubmitHandler)="submitCurrentForm($event)"
      *ngIf="dynamicFormBasicConfiguration"
    >
    </app-form-renderer>
  </div>
</p-dialog>

<p-dialog
  header="Update Resources"
  [modal]="true"
  [(visible)]="displayUpdateResources"
  (onHide)="restoreStateOfUpdateResources()"
  [style]="{ width: '50vw', height: '30vh' }"
>
  <div class="Updates">
    <div class="UpdateFileResources scroll">
      <div *ngFor="let file of resources" class="fileContainer">
        <div [ngSwitch]="file.mimetype.split('/')[0]" class="resource">
          <ng-container *ngSwitchCase="'image'">
            <img [src]="file.url" [alt]="file.name" />
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="other-file">
              <i
                style="font-size: 3em"
                [class]="
                  'pi ' +
                  (file.mimetype.includes('audio') ||
                  file.mimetype.includes('video')
                    ? 'pi-play'
                    : 'pi-file')
                "
              >
              </i>
              <span>
                {{
                  file.mimetype.includes("audio") ||
                  file.mimetype.includes("video")
                    ? file.mimetype.split("/")[0]
                    : file.mimetype.split("/")[1]
                }}
              </span>
            </div>
          </ng-container>
        </div>
        <div class="select-remove">
          <p-checkbox
            [(ngModel)]="file.isSelected"
            [binary]="true"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="UpdatesControlls">
      <button (click)="updateFiles()">Update Files</button>
    </div>
  </div>
</p-dialog>
<p-dialog
  header="Review Job Card"
  class="reviewDailog"
  [(visible)]="reviewDialog"
>
  <div style="display: flex; justify-content: center">
    <img
      style="max-width: fit-content; max-height: fit-content"
      [src]="
        reviewType == 'JOB_CARD'
          ? ticket.reviewCardUrl
          : reviewType == 'SERVICE_CHALLAN'
          ? ticket.serviceChallanUrl
          : null
      "
    />
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="review(reviewType, 'Approved')"
      label="Approve"
      style="margin: 10px; background: green !important"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="review(reviewType, 'APPROVED_AND_CLOSE_TICKET')"
      label="Approve And Close Ticket"
      style="margin: 10px; background: green !important"
      *ngIf="reviewType == 'JOB_CARD'"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      (click)="review(reviewType, 'Rejected')"
      label="Reject"
      style="margin: 10px; background: red !important"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog header="View Image" class="reviewDailog" [(visible)]="viewImageFlag">
  <div style="display: flex; justify-content: center">
    <div *ngIf="!imageUrl" style="padding: 20px 0px">No File Attached</div>
    <img
      *ngIf="imageUrl"
      style="max-width: fit-content; max-height: fit-content"
      [src]="imageUrl"
    />
  </div>
</p-dialog>

<p-dialog
  header="Category"
  class="categoryDialog"
  [(visible)]="editCategoryView"
  (onHide)="onCloseCategory()"
>
  <div style="display: flex; justify-content: center; gap: 20px">
    <div>
      <span class=".ui-dropdown-label">
        <p-dropdown
          appendTo="body"
          [options]="categoriesList"
          (onChange)="setType(selectedCategory)"
          [(ngModel)]="selectedCategory"
          optionLabel="label"
          [style]="{ width: '100%' }"
          placeholder="Select Category"
        >
        </p-dropdown>
      </span>
    </div>
    <div>
      <span class=".ui-dropdown-label">
        <p-dropdown
          appendTo="body"
          [options]="typeList"
          [(ngModel)]="selectedType"
          (onChange)="setSelectSlotList()"
          optionLabel="label"
          [style]="{ width: '100%' }"
          placeholder="Select Type"
        >
        </p-dropdown>
      </span>
    </div>
  </div>
  <div *ngIf="showSelectSlot" style="display: flex; gap: 20px; margin: 15px">
    <div *ngIf="showIncidentTimestamp">
      <span>
        <p-calendar
          appendTo="body"
          [(ngModel)]="selectedIncidentDateTime"
          optionLabel="label"
          [maxDate]="todayDate"
          [style]="{ width: '100%' }"
          placeholder="Select Incident Date"
        >
        </p-calendar>
      </span>
    </div>
    <div *ngIf="displaySlotList">
    <span class=".ui-dropdown-label">
      <p-dropdown
        appendTo="body"
        [options]="selectSlotList"
        [(ngModel)]="selectedSlot"
        optionLabel="label"
        [style]="{ width: '100%' }"
        placeholder="Select Slot">
      </p-dropdown>
    </span>
    </div>
  </div>
  <div *ngIf="showIncidentTimestamp && !showSelectSlot" style="display: flex; gap: 20px; margin: 15px;">
    <span>
      <p-calendar appendTo="body" [(ngModel)]="selectedIncidentDateTime" optionLabel="label" [maxDate]="todayDate"
        [style]="{ width: '100%' }" placeholder="Select Incident Date">
      </p-calendar>
    </span>
  </div>
  <button
    type="button"
    [disabled]="enableUpdateCategory()"
    pButton
    (click)="updateCategory()"
    label="Update"
    class="ui-button-secondary"
    style="float: right; margin: 15px 25px"
  ></button>
</p-dialog>
<div style="border: 2px white solid">
  <p-dialog
    style="text-align: center"
    [modal]="true"
    [responsive]="true"
    [style]="{
      maxWidth: '30%',
      maxHeight: '90%',
      border: '2px  white solid',
      borderRadius: '20px'
    }"
    styleClass="dialog-color"
    [(visible)]="viewRejectionReason"
  >
    <div>
      <span class="ui-float-label">
        <p style="padding: 4px; font-size: 15px">Please provide reason:</p>
        <div>
          <textarea
            id="float-input"
            class="rejection"
            [(ngModel)]="rejectionReason"
            type="text"
            placeholder="Enter reason.."
            value="0"
            style="
              height: 100px;
              width: 269px;
              margin-bottom: 28px;
              border: solid 1px white;
              border-radius: 8px;
              color: white;
              background-color: grey;
            "
            required
          >
          </textarea>
        </div>
        <button
          (click)="updateTicket()"
          style="
            background-color: red;
            height: 30px;
            width: 100px;
            border: 1px solid white;
            border-radius: 10px;
            margin-bottom: 13px;
          "
        >
          Reject
        </button>
      </span>
    </div>
  </p-dialog>
</div>

<p-dialog
  style="text-align: center"
  [(visible)]="showOdometerDialog"
  [style]="{
    maxWidth: '25%',
    maxHeight: '90%',
    border: '2px  white solid',
    borderRadius: '20px'
  }"
  [responsive]="true"
>
  <img
    src="assets/odometerSuccess.png"
    style="border: none; margin-left: 70px"
  />
  <p style="font-size: 20px">Issue with Odometer Reading</p>
  <br />
  <p>
    Clicking on proceed will close the ticket as the entered odometer is below
    threshold.
  </p>
  <br />
  <p>You can edit the odometer reading on the previous screen.</p>
  <br />
  <button
    style="
      background-color: #116fbf;
      height: 30px;
      border: 1px solid white;
      border-radius: 10px;
      margin-bottom: 13px;
      color: white;
    "
    (click)="closePopup()"
  >
    Move to previous screen</button
  ><br />
  <button
    style="
      background-color: #116fbf;
      height: 30px;
      border: 1px solid white;
      border-radius: 10px;
      margin-bottom: 13px;
      color: white;
    "
    (click)="closeTicket()"
  >
    Proceed and close ticket
  </button>
</p-dialog>

<p-dialog
  style="text-align: center"
  [(visible)]="closeOdometerDialog"
  [style]="{
    maxWidth: '300px',
    border: '2px  white solid',
    borderRadius: '20px'
  }"
  class="closeOdometerDialog"
  [responsive]="true"
>
  <img
    src="assets/odometerLoading.svg"
    class="ticketUpdationModal"
    style="border: none;"
  />
  <p style="font-size: 15px; padding-top: 32px">{{closeOdometerMessage}}</p>
</p-dialog>

<p-dialog
  style="text-align: center"
  [(visible)]="successBoxDialog"
  [style]="{
    maxWidth: '25%',
    maxHeight: '70%',
    borderRadius: '20px'
  }"
  [responsive]="true"
>
  <div class="img-form">
    <img class="img-src" src="assets/odometerLoading.png" alt="" />
    <div class="success-text">Maintenance DP has been assigned.</div>
  </div>
</p-dialog>

<p-dialog
  style="text-align: center"
  [(visible)]="showDialogCarSentToWorkshop"
  [style]="{
    maxWidth: '25%',
    maxHeight: '70%',
    borderRadius: '20px'
  }"
  [responsive]="true"
>
  <div class="img-form">
    <img class="img-src" src="assets/blue-car.svg" alt="" />
    <div class="img-text">
      {{ showAllFields ? "Send car to workshop" : "Assign workshop at hub" }}
    </div>
    <div>
      <form class="img-form-fields" [formGroup]="workshopForm">
        <div class="form-group">
          <label for="workshop">Workshop <span class="astrick">*</span></label>
          <br />
          <p-dropdown
            formControlName="workshop"
            appendTo="body"
            [options]="workshopList"
            optionLabel="label"
            [style]="{ width: '100%' }"
            dropdownIcon="pi pi-angle-down"
            placeholder="Select Workshop"
          >
          </p-dropdown>
        </div>

        <div class="form-group" *ngIf="showAllFields">
          <label for="maintenanceDp">Maintenance DP</label>
          <br />
          <p-dropdown
            formControlName="maintenanceDp"
            appendTo="body"
            [options]="maintenanceList"
            optionLabel="name"
            (onChange)="selectedMaintenanceDP($event)"
            [style]="{ width: '100%' }"
            dropdownIcon="pi pi-angle-down"
            placeholder="Select Maintenance DP"
          >
            <ng-template let-item pTemplate="selectedItem">
              <span class="dropdown-icon-wrapper">
                <span class="text">{{ item.label }}</span>
                <i
                  (click)="onDropdownIconClick(!maintenanceDp ? '' : 'trash')"
                  [ngClass]="
                    !maintenanceDp ? 'pi pi-angle-down' : 'pi pi-trash'
                  "
                ></i>
              </span>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="form-group" *ngIf="showAllFields">
          <label for="pickupDateTime">Pickup Date Time</label>
          <br />
          <p-calendar
            appendTo="body"
            placeholder="Enter pickup date & time"
            formControlName="pickupDateTime"
            [showIcon]="true"
            [showTime]="true"
            [minDate]="minDate"
            (onSelect)="checkDate($event)"
            [disabled]="calendarDisable"
          ></p-calendar>
        </div>

        <div class="form-btn">
          <p-button
            type="submit"
            class="form-submit-btn"
            [disabled]="!workshopForm.valid"
            label="submit"
            (click)="submitForm()"
          >
          </p-button>
        </div>
      </form>
    </div>
  </div>
</p-dialog>

<p-dialog
  style="text-align: center"
  [(visible)]="assignPickUpDriverDialog"
  [style]="{
    maxWidth: '25%',
    maxHeight: '70%',
    borderRadius: '20px'
  }"
  [responsive]="true"
>
  <div class="img-form">
    <div class="images-form">
      <img class="img-src" src="assets/blue-car.svg" alt="" />
    </div>
    <div class="img-text">Assign pickup driver</div>
    <div>
      <form class="img-form-fields" [formGroup]="pickupDriverForm">
        <div class="form-group">
          <label for="maintenanceDp">Maintenance DP</label>
          <br />
          <p-dropdown
            formControlName="maintenanceDp"
            appendTo="body"
            [options]="maintenanceList"
            optionLabel="name"
            (onChange)="selectedMaintenanceDP($event)"
            [style]="{ width: '100%' }"
            dropdownIcon="pi pi-angle-down"
            placeholder="Select Maintenance DP"
          >
            <ng-template let-item pTemplate="selectedItem">
              <span class="dropdown-icon-wrapper">
                <span class="text">{{ item.label }}</span>
                <i
                  (click)="onDropdownIconClick(!maintenanceDp ? '' : 'trash')"
                  [ngClass]="
                    !maintenanceDp ? 'pi pi-angle-down' : 'pi pi-trash'
                  "
                ></i>
              </span>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="form-group">
          <label for="pickupDateTime">Pickup Date Time</label>
          <br />
          <p-calendar
            appendTo="body"
            placeholder="Enter pickup date & time"
            formControlName="pickupDateTime"
            [showIcon]="true"
            [showTime]="true"
            [minDate]="minDate"
            (onSelect)="checkDate($event)"
            [disabled]="calendarDisable"
          ></p-calendar>
        </div>

        <div class="form-btn">
          <p-button
            type="submit"
            class="form-submit-btn"
            [disabled]="!pickupDriverForm.valid"
            label="Submit"
            (click)="submitPickUpForm()"
          >
          </p-button>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
<div *ngIf="displayDialog" class="modal">
  <p-dialog
    [style]="{
      maxWidth: '30%',
      maxHeight: '65%'
    }"
    styleClass="my-dialog"
    header="Penalty Slab"
    [(visible)]="displayDialog"
  >
    <div class="penaltyBox">
      <div class="penaltyType">{{ penaltyName }}</div>
      <p-table [value]="penaltyData" [scrollable]="true" scrollHeight="300px">
        <ng-template pTemplate="header">
          <tr class="dialog-header">
            <th><span class="slab-heading">Slab</span></th>
            <th style="text-align: right">
              <span class="penaltyAmount">Penalty Amount</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-penalty>
          <tr class="dialog-body">
            <td>{{ penalty.slabRange }}</td>
            <td style="text-align: right">{{ penalty.penaltyAmount }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>
</div>
<div *ngIf="display" class="modal">
  <p-dialog
    [style]="{
      maxWidth: '50%',
      maxHeight: '90%'
    }"
    styleClass="my-reversal-dialog"
    header=""
    [(visible)]="display"
    [modal]="true"
    [maximizable]="false"
    [baseZIndex]="1"
  >
    <div class="reversePenaltyBox">
      <div class="reversal-header mb-2">
        <p style="padding-bottom: 18px">
          Do you really want to refund amount to this driver ? <br />
        </p>
        <p style="font-size: 16px">{{ reversalPenaltyMessage }}</p>
      </div>
      <div class="reversal-input">
        <div *ngIf="penaltyType">
          <div style="padding: 8px 0px; width: 300px">
            <p>
              Removal Penalty Duration<span class="required-field"></span> (In
              Minutes)
            </p>
          </div>
          <input
            class="penalty-duration"
            pInputText
            (keypress)="numberOnly($event)"
            type="text"
            [(ngModel)]="applicablePenaltyDurationInMins"
            (ngModelChange)="onInputChange($event)"
            required
          />
          <div class="penaltyNote">
            {{ penaltyNote }}
          </div>
        </div>
        <div style="padding: 10px 0px">
          <div>Reversal Reason<span class="required-field"></span></div>
          <div
            class="mandatory-field-validation"
            *ngIf="penaltyReasonValidated"
          >
            Minimum 25 words
          </div>
        </div>
        <div>
          <textarea
            class="penalty-reason"
            rows="2"
            cols="7"
            pInputTextarea
            [(ngModel)]="penaltyReason"
            required
          ></textarea>
        </div>
      </div>
      <div class="dialog-buttons">
        <button
          pButton
          style="border-radius: 15px"
          class="submit-reversal"
          [disabled]="checkAmt"
          (click)="refundAmount()"
        >
          Submit
        </button>
        <button
          style="border-radius: 15px; width: 130px"
          class="cancel-reverse"
          pRipple
          type="button"
          (click)="display = false; driverIdToPenalty = null"
        >
          Cancel
        </button>
      </div>
    </div>
  </p-dialog>
</div>

<div *ngIf="displayAudioDialog" class="modal">
 <p-dialog 
    [style]="{
    maxWidth: '35%',
    maxHeight: '60%'
     }" 
    styleClass="my-reversal-dialog" 
    header="" 
    [(visible)]="displayAudioDialog" 
    [modal]="true" 
    [maximizable]="false"
    [baseZIndex]="1"
    (visibleChange)="onHidePlayAudio()" >
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Audio Player</h4>
  </div>
  <div class="modal-body">
    <audio style="margin-left: 70px;margin-top: 20px;" controls="controls">
      <source src="{{dutyDeniedAudioUrl}}" type="audio/ogg" />
    </audio>
  </div>
 </p-dialog>
</div>

<div *ngIf="displayDutyDeniedPenaltyPopup" class="modal">
  <p-dialog [style]="{
    maxWidth: '50%',
    maxHeight: '90%'
  }" styleClass="my-reversal-dialog" header="" [(visible)]="displayDutyDeniedPenaltyPopup" [modal]="true"
    [maximizable]="false" [baseZIndex]="1">
    <div class="reversePenaltyBox">
      <div class="reversal-header mb-2">
        <p style="padding-bottom: 18px;"> Do you really want to refund amount to this driver ? <br></p>
      </div>
      <div class="reversal-input">
        <div style="padding: 10px 0px;">
          <div>Reversal Reason<span class="required-field"></span></div>
          <div class="mandatory-field-validation" *ngIf="dutyDeniedPenaltyReasonValidated">Minimum 25 words</div>
        </div>
        <div>
          <textarea class="duty-denied-penalty-reason" rows="2" cols="7" pInputTextarea [(ngModel)]="dutyDeniedPenaltyReason"
            required></textarea>
        </div>
      </div>
      <div class="dialog-buttons">
        <button pButton style="border-radius: 15px;" class="submit-reversal"
          (click)="refundDutyDeniedPenaltyAmount()">
          Submit
        </button>
        <button style="border-radius: 15px; width: 130px;" class="cancel-reverse" pRipple type="button" 
        (click)="displayDutyDeniedPenaltyPopup = false; dutyDeniedPenaltyReason = null; dutyDeniedPenaltyReasonValidated = false">
          Cancel
        </button>
      </div>
    </div>
  </p-dialog>
</div>

<p-dialog
  [(visible)]="showNoIssue"
  [style]="{
    maxWidth: '35%',
    borderRadius: '20px'
  }"
  [responsive]="true"
>
  <div style="margin-left: 20px">
    <span> Please provide description : </span>
    <textarea
      class="rejection issue-description"
      [(ngModel)]="noIssueDescription"
      type="text"
      placeholder="Description.."
      required
    >
    </textarea>
  </div>
  <div class="grid-container">
    <div
      *ngFor="let item of imagesUrl; let i = index"
      class="grid-item"
      style="display: flex"
    >
      <div>
        <i
          class="fa fa-picture-o"
          style="font-size: 15px"
          aria-hidden="true"
        ></i
        ><u
          style="margin-left: 7px; cursor: pointer"
          (click)="carouselDisplay(i)"
          >Attached image
        </u>
      </div>
      <div class="delete-no-issue" (click)="deleteNoIssueImage(i)">
        <i class="fa fa-trash-o"></i>
        <span style="margin-left: 5px"> Delete </span>
      </div>
    </div>
  </div>
  <div
    *ngIf="showNoIssueSpinner"
    class="d-flex justify-content-center mb-3"
    style="margin-top: -15px"
  >
    <div class="spinner-border"></div>
  </div>
  <div class="addPhoto">
    <div *ngIf="!showNoIssueSpinner" style="color: #c2c2c2">
      <i class="fa fa-camera" style="margin-right: 7px"></i>

      <div class="tooltip-container">
        <u
          style="cursor: pointer"
          (mouseenter)="checkImageLimit()"
          (mouseleave)="showTooltip = false"
          (click)="onUploadClick()"
          >Add photo</u
        >
        <span *ngIf="showTooltip" class="tooltip-text"
          >Maximum 4 images are allowed</span
        >
      </div>
      <input
        #fileInput
        type="file"
        (change)="fileHandler($event)"
        style="display: none"
      />
    </div>

    <button
      style="
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 10px;
        background: #2c66e3 !important;
        border: unset !important;
      "
      pButton
      type="button"
      label="Send for hub review"
      (click)="submitStageForm()"
    ></button>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="showCarousel"
  [style]="{
    maxWidth: '35%'
  }"
>
  <!-- Added extra div to destroy and reset the component and bring it back if showCarousel is true -->
  <div *ngIf="showCarousel">
    <p-carousel
      [value]="finalImagesUrl"
      [showIndicators]="false"
      [numVisible]="1"
      [numScroll]="1"
      #carousel
    >
      <ng-template let-part pTemplate="item">
        <div class="mt-3 mb-4 d-flex justify-content-center">
          <img
            width="260"
            height="170"
            src="{{ part }}"
            class="product-image"
          />
        </div>
      </ng-template>
    </p-carousel>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="showAttachment"
  [style]="{
    maxWidth: '35%'
  }"
>
  <p-carousel
    [value]="attachmentUrls"
    [showIndicators]="false"
    [numVisible]="1"
    [numScroll]="1"
  >
    <ng-template let-part pTemplate="item">
      <div class="mt-3 mb-4 d-flex justify-content-center">
        <img width="260" height="170" src="{{ part }}" class="product-image" />
      </div>
      <div
        class="d-flex justify-content-center"
        style="margin-bottom: 10px; margin-top: -10px"
      >
        <a href="{{ part }}" style="color: white"
          ><i class="fa fa-download mr-1" aria-hidden="true"></i>
          <span>Download image</span></a
        >
      </div>
    </ng-template>
  </p-carousel>
</p-dialog>

<div *ngIf="displayAbsenteeismConfirmationPopup" class="modal">
  <p-dialog [style]="{ maxWidth: '40%', maxHeight: '70%' }" 
            styleClass="my-reversal-dialog" 
            header="" 
            [(visible)]="displayAbsenteeismConfirmationPopup" 
            [modal]="true"
            [maximizable]="false" 
            [baseZIndex]="1">
    <div class="reversePenaltyBox">
      <div *ngIf="reverseSEBCheckbox && !reverseMbgCheckbox" class="reversal-header mb-2">
        <p style="padding-bottom: 2px;  font-size:15px; margin:0px 40px;"> 
          Are you sure you want to keep the DP eligible for SEB/Referral bonus without reversing the MBG 
          <br></p>
          <div class="dialog-buttons" style="padding-left: 100px;">
          <button pButton style="border-radius: 15px;" class="submit-reversal" (click)="returnAbsenteeismPenaltyAmount()">
            Yes
          </button>
          <button pButton style="border-radius: 15px;" class="submit-reversal"
            (click)="displayAbsenteeismConfirmationPopup = false;">
            No
          </button>
          </div>
      </div>
      <div *ngIf="!reverseSEBCheckbox && reverseMbgCheckbox" class="reversal-header mb-2">
        <div>
          <p style="padding-bottom: 6px;"> Note <br></p>
        </div>
        <p style="padding-bottom: 2px; font-size:15px; margin:0px 40px;">
          You cannot reverse just the MBG without keeping the DP eligible for SEB/Referral bonus.
         <br>
      </p>
      </div>
    </div>
  </p-dialog>
</div>

<div *ngIf="displayAbsenteeismPenaltyPopup" class="modal">
  <p-dialog [style]="{
    maxWidth: '50%',
    maxHeight: '90%'
  }" styleClass="my-reversal-dialog" header="" [(visible)]="displayAbsenteeismPenaltyPopup" [modal]="true"
    [maximizable]="false" [baseZIndex]="1">
    <div class="reversePenaltyBox">
      <div class="reversal-header mb-2">
        <p style="padding-bottom: 18px;"> For which slot you want to reverse the penalty ? <br></p>
        <div class="mandatory-field-validation" *ngIf="!selectedSlotValidated">Please select slot to reverse</div>
        <div class="absentReversalSlot">
          <div class="reversalSlot" *ngIf="reverseSlotOne">
            <input type="radio" id="slotOne" name="slotOne" value=true [(ngModel)]="selectedSlotOne" />
            <label style="color: black; font-size: 15px; padding-left: 5px;" for="dewey">Slot 1</label>
          </div>
          <div class="reversalSlot" *ngIf="reverseSlotTwo">
            <input type="radio" id="slotTwo" name="slotTwo" value=true [(ngModel)]="selectedSlotTwo" />
            <label style="color: black; font-size: 15px; padding-left: 5px;" for="dewey">Slot 2</label>
          </div>
          <div class="reversalSlot" *ngIf="reverseBothSlots">
            <input type="radio" id="both" name="both" value=true [(ngModel)]="selectedBothSlots" />
            <label style="color: black; font-size: 15px; padding-left: 5px;" for="dewey">Both</label>
          </div>
        </div>
      </div>
      <div class="reversal-header mb-2">
        <p style="padding-bottom: 18px;"> Do you really want to refund amount to this driver ? <br></p>
      </div>
      <div class="reversal-input">
        <div style="padding: 10px 0px;">
          <div>Reversal Reason<span class="required-field"></span></div>
          <div class="mandatory-field-validation" *ngIf="absenteeismPenaltyReasonValidated">Minimum 25 words</div>
        </div>
        <div>
          <textarea class="duty-denied-penalty-reason" rows="2" cols="7" pInputTextarea
            [(ngModel)]="absenteeismPenaltyReason" required></textarea>
        </div>
      </div>
      <div class="dialog-buttons">
        <button pButton style="border-radius: 15px;" class="submit-reversal" (click)="reverseAbsenteeismPenalty()">
          Submit
        </button>
        <button style="border-radius: 15px; width: 130px;" class="cancel-reverse" pRipple type="button"
          (click)="displayAbsenteeismPenaltyPopup = false; absenteeismPenaltyReason = null; 
          absenteeismPenaltyReasonValidated = false; reverseMbgCheckbox = false; reverseSEBCheckbox = false;
          selectedSlotOne = false; selectedSlotTwo = false; selectedBothSlots = false;
          selectedSlotValidated= false;">
          Cancel
        </button>
      </div>
    </div>
  </p-dialog>
</div>
