import { Urls } from './../../models/api.constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

export class Headers {
    private static HEADERS: HttpHeaders;
    public static get headers() {
        return Headers.HEADERS;
    }
    public static addHeaders(headers) {
        if (!Headers.HEADERS) {
            Headers.HEADERS = new HttpHeaders();
        }
        Headers.HEADERS = Headers.HEADERS.append(headers.key, headers.value);
    }
    public static clearHeaders() {
        Headers.HEADERS = null;
    }
}


@Injectable({
  providedIn: 'root'
})
export class FormServiceService {

  constructor(private http: HttpClient) {}
  requestThisUrl(url: string, token?: string): Promise<{}> {
    const URL = url.includes('https://') || url.includes('http://') ? url : Urls.HUB_URL + url;
    return this.http.get<{}>(URL).toPromise();
  }
  getOdometerReading(vehicleNumber): Observable<any> {
    return this.http.get<any>(
      Urls.APP_URL + "/api/v1/vehicle/odometer/" + vehicleNumber + "/get",
      { headers: Headers.headers }
    );
  }
}
