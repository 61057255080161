import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../models/api.constants';
import { Observable } from 'rxjs';
import { Headers } from '../models/api.headers';

@Injectable({
  providedIn: 'root'
})
export class RiderSupportService {

  constructor(private http: HttpClient) { }
  getAllTickets(obj): Observable<{}> {
    console.log("calliung post api")
    return this.http.post(Urls.APP_URL + '/api/v2/tickets/load', obj, { headers: Headers.headers });
  }

  sendCSV(body) {
    return this.http.post(Urls.APP_URL + '/api/v2/tickets/csv', body, { headers: Headers.headers });
  }

}

