import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  isTokenValid(token): Promise<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/check/token?token=' + token).toPromise();
  }

  authenticate(username: string, password: string, gmailToken: string): Observable<{}> | Promise<any> {
    const res = this.http.post<{}>(Urls.APP_URL + '/api/v1/authenticate', { username, password, gmailToken });
    return res.toPromise();
  }

  logout(token): Promise<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/logout?token=' + token).toPromise();
  }
  getUserRole(ssoId): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL+ '/api/v1/user/role/' + ssoId, { headers: Headers.headers });
  }
  getAuthorizedZones(): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL + '/api/v1/zone/permission', { headers: Headers.headers });
  }
  getAuthorizedZonesWithRole(ssoId): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL + '/api/v2/user/role/'+ssoId, { headers: Headers.headers });
  }
}
