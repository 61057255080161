import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { InputTextareaModule } from "primeng/inputtextarea";
import { RadioButtonModule } from "primeng/radiobutton";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { FileUploadModule } from "primeng/fileupload";
import { CheckboxModule } from "primeng/checkbox";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";

import { DynamicFieldDirective } from "./components/dynamic-field/dynamic-field.directive";
import { DynamicFormComponent } from "./container/dynamic-form.component";
import { DragDropModule } from "primeng/dragdrop";

import {
  FormTextAreaComponent,
  FormSelectComponent,
  FormInputComponent,
  FormButtonComponent,
  FormInputGroupComponent,
  FormInputFileComponent,
  FileUploadComponent,
  FormDateTimeComponent,
} from "./components/form-items/form-items.component";
import { MaintenaceTicketsListComponent } from "../../maintenace-tickets-list/maintenace-tickets-list.component";
import { TooltipModule } from "primeng/tooltip";
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelectModule,
    FileUploadModule,
    CheckboxModule,
    InputTextareaModule,
    RadioButtonModule,
    DragDropModule,
    CalendarModule,
    CardModule,
    TooltipModule,
    FormsModule,
    MomentModule
  ],
  declarations: [
    MaintenaceTicketsListComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormTextAreaComponent,
    FormInputGroupComponent,
    FormInputFileComponent,
    FileUploadComponent,
    FormDateTimeComponent,
  ],
  exports: [DynamicFormComponent],
  entryComponents: [
    MaintenaceTicketsListComponent,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormInputGroupComponent,
    FormTextAreaComponent,
    FormInputFileComponent,
    FileUploadComponent,
    FormDateTimeComponent,
  ],
})
export class DynamicFormModule {}
