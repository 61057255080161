<form #configElementInputGroupRadio="ngForm" (ngSubmit)="
              configElementInputGroupRadioSubmitHandler(
                configElementInputGroupRadio)
            " class="column" autocomplete="off">
  <div class="field">
    <label for="label">Label</label>
    <input type="text" placeholder="Label" id="label" [ngModel]="dataElement.label" required #label="ngModel" name="label" pInputText />
  </div>
  <div class="field">
    <label for="name">Name</label>
    <input type="text" placeholder="Name" id="name" [ngModel]="dataElement.name" required #name="ngModel" name="name" pInputText />
  </div>
  <!-- <div class="field">
    <label for="placeholder">Placeholder</label>
    <input type="text" placeholder="Placeholder" id="placeholder" [ngModel]="dataElement.placeholder ? dataElement.placeholder : dataElement.label" required #placeholder="ngModel"
      name="placeholder" pInputText />
  </div> -->
  <p-fieldset legend="Options">
    <div style="width:100%">
      <div style="padding:2%; text-align:center; width:35%; float:left;">
        <span class="ui-float-label">
          <input id="float-input" style="width:100%" type="text" [(ngModel)]="dynamicFieldCurrentLabel"
            [ngModelOptions]="{ standalone: true }" pInputText>
          <label for="float-input">Label</label>
        </span>
      </div>
      <div style="padding:2%; text-align:center; width:35%; float:left;">
        <span class="ui-float-label">
          <input id="float-input" style="width:100%" type="text" [(ngModel)]="dynamicFieldCurrentValue"
            [ngModelOptions]="{ standalone: true }" pInputText>
          <label for="float-input">Value</label>
        </span>
      </div>
      <div style="padding:2%;width:30%; float:left;">
        <button pButton type="button" style="width:100%;" (click)="addOption()" label="Add Option"></button>
      </div>
    </div>
    <div>
      <ng-container *ngFor="let option of optionsForSelectedFields; index as i">
        <div *ngIf="option.isSaved" style="width: 100%;">
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" [value]="option.label" disabled pInputText />
          </div>
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" [value]="option.value" disabled pInputText />
          </div>
          <div style="padding:2%;width:30%; float:left;">
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-info" icon="pi pi-pencil"
                (click)="editThisOption(i)">
              </button>
            </div>
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-danger" icon="pi pi-times"
                (click)="deleteThisOption(i)">
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="option.isEditing" style="width: 100%;">
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" (change)="labelChangeHandler($event, i)" [value]="option.label"
              pInputText />
          </div>
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" (change)="valueChangeHandler($event, i)" [value]="option.value"
              pInputText />
          </div>
          <div style="padding:2%;width:30%; float:left;">
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-success" icon="pi pi-save"
                (click)="saveThisOption(i)">
              </button>
            </div>
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-danger" icon="pi pi-times"
                (click)="deleteThisOption(i)">
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </p-fieldset>

  <div class="field">
    <label for="value">Attributes</label>
    <div>
      <p-checkbox name="fieldOptions" value="required" [(ngModel)]="selectedFieldOptionValues" label="Required"
        [ngModelOptions]="{ standalone: true }"></p-checkbox>
      <p-checkbox name="fieldOptions" value="disabled" [(ngModel)]="selectedFieldOptionValues" label="Disabled"
        [ngModelOptions]="{ standalone: true }"></p-checkbox>
    </div>
  </div>
  <div class="field submit-btn">
    <button pButton type="button" type="submit" label="Save Element" class="ui-button-warning"
      [disabled]="configElementInputGroupRadio.invalid">
    </button>
  </div>
</form>
