import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class CreateTicketGlobalComponent {
    constructor(cookieService, router, route, messageService, ticketsService) {
        this.cookieService = cookieService;
        this.router = router;
        this.route = route;
        this.messageService = messageService;
        this.ticketsService = ticketsService;
        this.isLoadComplete = false;
        this.isGoingBackPossible = false;
        this.previouslyActiveForm = null;
        this.nextPrevFormRecords = {};
        this.activeIndex = 0;
        this.values = {};
    }
    ngOnInit() {
        this.getHeirarchyRootForm();
    }
    submitHandler() {
        const { usersToAction } = this.currentlyActiveForm;
        let postValues = {
            usersToAction,
        };
        // linkedlist like traversal from root to all linked forms
        const HIERARCHY_ORDER = [];
        let current = this.rootForm.formId;
        const view = new Set();
        const action = new Set();
        while (current) {
            if (!this.values[current]) {
                current = null;
            }
            else {
                for (const key of Object.keys(this.values[current])) {
                    if (key.toUpperCase().includes('HIERARCHY_')) {
                        HIERARCHY_ORDER.push(key.replace('HIERARCHY_', ''));
                    }
                }
                postValues = Object.assign({}, postValues, this.values[current]);
                let data = this.values[current];
                current = this.nextPrevFormRecords[current].next;
            }
        }
        HIERARCHY_ORDER.push(this.currentlyActiveForm['name']);
        this.isLoadComplete = false;
        const ACTIVE_FORMID = this.currentlyActiveForm.formId;
        HIERARCHY_ORDER.shift();
        const _a = this.values[ACTIVE_FORMID], { dataFormData } = _a, rest = tslib_1.__rest(_a, ["dataFormData"]);
        dataFormData.append('formData', JSON.stringify(rest));
        dataFormData.append('formId', ACTIVE_FORMID);
        dataFormData.append('category', JSON.stringify(HIERARCHY_ORDER));
        console.log(Array.from(dataFormData.keys()), Array.from(dataFormData.values()), dataFormData);
        const apiData = {
            formData: this.values[ACTIVE_FORMID],
            formId: ACTIVE_FORMID,
            category: HIERARCHY_ORDER
        };
        this.ticketsService.createTicket(dataFormData).subscribe(({ response, message, code }) => {
            if (code !== 200) {
                return alert(message);
            }
            const { actionMailedList, actionMessageList, ticketId } = response;
            if (ticketId) {
                this.messageService.add({ severity: 'success', summary: `Ticket Created`, detail: `ID: ${ticketId}`, life: 10000 });
            }
            if (Array.isArray(actionMailedList) && actionMailedList.length) {
                this.messageService.add({ severity: 'info', summary: `Email sent to`, data: actionMailedList, life: 10000 });
            }
            if (Array.isArray(actionMessageList) && actionMessageList.length) {
                this.messageService.add({ severity: 'info', summary: `Message sent to`, data: actionMessageList, life: 10000 });
            }
            this.restoreState();
        });
    }
    restoreState() {
        this.isLoadComplete = false;
        this.isGoingBackPossible = false;
        this.currentlyActiveForm = null;
        this.previouslyActiveForm = null;
        this.nextPrevFormRecords = {};
        this.activeIndex = 0;
        this.values = {};
        this.items = [];
        setTimeout(() => {
            this.previouslyActiveForm = null;
            this.currentlyActiveForm = this.rootForm;
            this.nextPrevFormRecorder();
            this.setStepperLength();
            this.isLoadComplete = true;
        }, 0);
    }
    getFormById(nextFormId = null, previousFormValues = null) {
        // console.log('getFormById() \n', nextFormId, previousFormValues);
        this.isLoadComplete = false;
        if (!nextFormId) {
            return;
        }
        this.ticketsService
            .getFormById(nextFormId)
            .subscribe((responseServer) => {
            this.isLoadComplete = true;
            const { errorCode, errorMessage, response } = responseServer;
            if (errorCode !== 200) {
                alert(errorMessage);
            }
            else {
                const currentForm = this.currentlyActiveForm;
                const { formId } = currentForm;
                this.currentlyActiveForm = null;
                setTimeout(() => {
                    {
                        // record current form values
                        this.values[formId] = previousFormValues;
                    }
                    {
                        // setting the prev, next...
                        this.previouslyActiveForm = currentForm;
                        this.currentlyActiveForm = response;
                        this.nextPrevFormRecorder();
                    }
                    // console.log('nextPrevFormRecords', this.nextPrevFormRecords);
                    this.setStepperLength();
                    this.activeIndex++;
                }, 0);
            }
        });
    }
    nextPrevFormRecorder() {
        if (!this.currentlyActiveForm) {
            return;
        }
        {
            this.nextPrevFormRecords[this.currentlyActiveForm.formId] = {
                formId: this.currentlyActiveForm.formId,
                form: this.currentlyActiveForm,
                prev: this.previouslyActiveForm ? this.previouslyActiveForm.formId : null,
                next: null,
            };
        }
        {
            if (this.previouslyActiveForm) {
                this.isGoingBackPossible = true;
                if (this.nextPrevFormRecords[this.previouslyActiveForm.formId]) {
                    this.nextPrevFormRecords[this.previouslyActiveForm.formId].next = this.currentlyActiveForm.formId;
                }
                else {
                    this.nextPrevFormRecords[this.previouslyActiveForm.formId] = {
                        formId: this.previouslyActiveForm.formId,
                        form: this.previouslyActiveForm,
                        prev: null,
                        next: this.currentlyActiveForm.formId,
                    };
                }
            }
        }
    }
    setStepperLength() {
        const { formId: currentFormId, name } = this.currentlyActiveForm;
        const items = [];
        let now = this.rootForm.formId;
        while (now && now !== currentFormId) {
            items.push({
                label: this.nextPrevFormRecords[now].form.name,
            });
            now = this.nextPrevFormRecords[now].next;
        }
        items.push({
            label: name,
        });
        // console.log('setStepperLength', items);
        this.items = items;
    }
    activeIndexChangeHandler(indexToStop) {
        var formActiveRecord = null;
        {
            let now = this.rootForm.formId;
            let i = 0;
            while (i < indexToStop && now) {
                now = this.nextPrevFormRecords[now].next;
                i++;
            }
            formActiveRecord = this.nextPrevFormRecords[now];
        }
        if (!formActiveRecord) {
            return;
        }
        this.previouslyActiveForm = formActiveRecord.prev ? this.nextPrevFormRecords[formActiveRecord.prev].form : null;
        const { form, formId } = formActiveRecord;
        const value = this.values[formId];
        if (value) {
            const { elements } = form;
            form.elements = elements.map((element) => {
                if (value[element.name]) {
                    element.value = value[element.name];
                }
                return element;
            });
        }
        this.currentlyActiveForm = null;
        setTimeout(() => {
            this.currentlyActiveForm = form;
            this.activeIndex = indexToStop;
            this.nextPrevFormRecorder();
            this.setStepperLength();
        }, 0);
    }
    submitCurrentForm({ values, fields, formData }) {
        const { category = 'endPointElement' } = this.currentlyActiveForm;
        const formId = fields.formId;
        {
            // record current form values
            this.values[formId] = Object.assign({}, values, { dataFormData: formData });
        }
        if (category !== 'hierarchyElement') {
            return this.submitHandler();
        }
        else {
            // If there was a hiearchy field and its value was provided
            delete fields.formId;
            const Entries = Object.entries(fields);
            for (const [name, formField] of Entries) {
                const { type, options } = formField;
                if (type === 'select') {
                    const NextFormId = values[name];
                    if (options) {
                        const option = options.filter(op => op.value === NextFormId)[0];
                        this.values[formId][name] = option && option.label ? option.label : option.value;
                    }
                    // console.log(this.values[formId]);
                    return this.getFormById(NextFormId, values);
                }
            }
        }
    }
    getHeirarchyRootForm() {
        this.isLoadComplete = false;
        const rootProject = 'techSupportTMS';
        this.ticketsService.fetchCurrentlyActiveForm(rootProject, 'hierarchyElement', 'Ticket', true)
            .subscribe((responseServer) => {
            this.isLoadComplete = true;
            console.log(responseServer);
            if (responseServer.errorCode !== 200) {
                return alert(responseServer.errorMessage);
            }
            else {
                const { response: rootForm } = responseServer;
                this.isGoingBackPossible = false;
                {
                    // update the root form
                    this.rootForm = rootForm;
                }
                this.currentlyActiveForm = null;
                setTimeout(() => {
                    // prev and next setting
                    this.previouslyActiveForm = null;
                    this.currentlyActiveForm = rootForm;
                    this.nextPrevFormRecorder();
                    this.setStepperLength();
                    this.activeIndex = 0;
                }, 0);
            }
        });
    }
    goBackOneTime() {
        // if going back is not possible then do nothing
        if (!this.isGoingBackPossible || !this.previouslyActiveForm) {
            return;
        }
        if (!confirm('Going a step back will result in loosing all data for current & previous form')) {
            return;
        }
        return this.activeIndexChangeHandler(this.activeIndex - 1);
    }
    onReject(key) {
        this.messageService.clear(key);
    }
}
