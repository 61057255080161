import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DriversService {
    constructor(http) {
        this.http = http;
    }
    getAllDrivers() {
        return this.http.get(Urls.HUB_URL + '/api/v1/drivers/all', { headers: Headers.headers });
    }
}
DriversService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DriversService_Factory() { return new DriversService(i0.ɵɵinject(i1.HttpClient)); }, token: DriversService, providedIn: "root" });
