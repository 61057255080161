import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../models/api.constants';

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {

  constructor(private http: HttpClient) { }

  getAllIncidents(): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/incident');
  }
}
