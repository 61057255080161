import * as tslib_1 from "tslib";
import { DynamicFormComponent } from './dynamic-forms/container/dynamic-form.component';
import { AfterViewInit, OnInit, EventEmitter, OnChanges } from '@angular/core';
import { converter } from './form.utils';
export class FormRendererComponent {
    constructor(_formService) {
        this._formService = _formService;
        this.areWeUpForUpdate = false;
        this.addElementHandler = new EventEmitter();
        this.updateElementHandler = new EventEmitter();
        this.deleteElementHandler = new EventEmitter();
        this.dropElementHandler = new EventEmitter();
        this.isLoadComplete = false;
        this.loadMessage = 'Loading... Please Wait!';
        this.formSubmitHandler = new EventEmitter();
    }
    addFieldElementHandler(e) {
        this.addElementHandler.emit();
    }
    updateFieldElementHandler(fieldConfig) {
        this.updateElementHandler.emit(fieldConfig);
    }
    deleteFieldElementHandler(fieldConfig) {
        this.deleteElementHandler.emit(fieldConfig);
    }
    dropFieldElementHandler(fieldConfig) {
        this.dropElementHandler.emit(fieldConfig);
    }
    formSubmit(form) {
        this.formSubmitHandler.emit(form);
    }
    filterFormElementsBasedOnCategory() {
        //  <!-- NOT REQUIRED HOC SHOULD HANDLE THIS --!>
        // // will ensure if category is available then depending on category element is seleclected.
        // if (this.formConfig.category) {
        //   // iterating over elements [] to filter element acc. to category
        //   return this.formConfig.elements.filter((el) => {
        //       // if element is button allow
        //       if (el.html === 'button' && el.name === 'submit') {
        //         return true;
        //       }
        //       // if category is heiarchy element then allow onl select fileds
        //       // else if endPoint-stage include eleemnt irrecpective
        //       // in all other case ignore
        //       if (this.formConfig.category === 'heirarchyElement' && el.html === 'select') {
        //         return true;
        //       } else if (this.formConfig.category === 'endPointElement' || this.formConfig.category === 'stageElement' ) {
        //         return true;
        //       }
        //       return false;
        //   });
        // }
        return this.formConfig.elements;
    }
    configureFormComponent() {
        this.formElements = this.filterFormElementsBasedOnCategory();
        this.fetchData(this.formElements);
    }
    ngOnInit() {
        this.configureFormComponent();
    }
    ngOnChanges() {
        this.configureFormComponent();
    }
    fetchData(elements) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let el of elements) {
                const { html, url, optionFieldLabel = 'label', optionFieldValue = 'value' } = el;
                if (html === 'select' && url && typeof url === 'string') {
                    try {
                        const data = yield this._formService.requestThisUrl(el.url, this.tokenAuth);
                        if ('errorCode' in data && data.errorCode === 200 ||
                            'statusCode' in data && data.statusCode === 200 ||
                            'code' in data && data.code === 200) {
                            const response = data.response;
                            // el.options = response.map(({ [optionFieldLabel]: label, [optionFieldValue]: value }) => ({ label, value }));
                            el.options = response.map((option) => {
                                const value = optionFieldValue.split(',').map((val) => val && typeof val === 'string' && val.trim().length > 0 && option[val.trim()]).filter(Boolean).join(',');
                                const label = optionFieldValue.split(',').map((val) => val && typeof val === 'string' && val.trim().length > 0 && option[val.trim()]).filter(Boolean).join(',');
                                return {
                                    label,
                                    value,
                                };
                            });
                            console.log(el.options);
                        }
                        // remove -->
                        // const response: any[] = data;
                        // el.options = response.map(({ [optionFieldLabel]: label, [optionFieldValue]: value }) => ({ label, value }));
                        // <--
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            }
            this.isLoadComplete = true;
            this.formElementConfigArray = elements;
        });
    }
}
export class FormComponent {
    constructor() {
        this.areWeUpForUpdate = false;
        this.addElementHandler = new EventEmitter();
        this.updateElementHandler = new EventEmitter();
        this.deleteElementHandler = new EventEmitter();
        this.dropElementHandler = new EventEmitter();
        this.formSubmitHandler = new EventEmitter();
        this.flattenedFormFields = {};
    }
    addFieldElementHandler(e) {
        this.addElementHandler.emit();
    }
    updateFieldElementHandler(fieldConfig) {
        this.updateElementHandler.emit(fieldConfig);
    }
    deleteFieldElementHandler(fieldConfig) {
        this.deleteElementHandler.emit(fieldConfig);
    }
    dropFieldElementHandler(fieldConfig) {
        this.dropElementHandler.emit(fieldConfig);
    }
    flatThisFormFields() {
        const flattenedFormFields = {
            formId: this.formConfig.formId,
        };
        for (let elConfig of this.formElementsConfig) {
            const { name, type, htmlType, elementType = 'endPoint', options } = elConfig;
            const fieldValue = {
                name,
                type,
                htmlType,
                elementType,
            };
            if ('value' in elConfig && elConfig.value) {
                fieldValue.value = elConfig.value;
            }
            if (options) {
                fieldValue.options = options;
            }
            flattenedFormFields[name] = fieldValue;
        }
        this.flattenedFormFields = flattenedFormFields;
    }
    ngOnInit() {
        this.formElementsConfig = converter(this.formElementConfigArray);
        this.flatThisFormFields();
    }
    ngAfterViewInit() {
        let previousValid = this.form.valid;
        this.form.changes.subscribe(() => {
            // previousValid = !this.form.valid;
            // this.form.setDisabled('submit', this.form.valid);
            if (this.form.valid !== previousValid) {
                previousValid = this.form.valid;
                this.form.setDisabled('submit', !previousValid);
            }
        });
        this.formElementsConfig.forEach((config) => {
            if ('value' in config) {
                this.form.setValue(config.name, config.value);
            }
            if (config.validations && config.validations.required) {
                // if filed is required and value is already avialbel dont set disbaled bcz of this filed
                // its value is already there
                // set disabled true for fields which are requried and has a value
                if (!config.value) {
                    this.form.setDisabled('submit', true);
                }
            }
        });
    }
    submit(values) {
        const formData = new FormData();
        for (let key in this.flattenedFormFields) {
            if (values[key]) {
                if (key.toLowerCase().includes('files') && values[key].length > 1) {
                    const ArrayNew = Array.from(values[key]);
                    ArrayNew.forEach((element) => {
                        formData.append(key, element);
                    });
                }
                else {
                    formData.append(key, values[key]);
                }
            }
            else if (this.flattenedFormFields[key].value) {
                values[key] = this.flattenedFormFields[key].value;
                formData.append(key, values[key]);
            } // did this to pick values of any disabled fields may be requried :|
        }
        this.formSubmitHandler.emit({
            values,
            formData,
            fields: this.flattenedFormFields,
        });
    }
}
