import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Response } from 'src/app/models/api.dto';
import { DbUsersService } from 'src/app/services/db-users.service';
import { Header } from 'primeng/components/common/shared';



// const form = {
//   "project": "operationsTMS",
//   "isActive": true,
//   "_id": "5ebcfbbde56b31118fa2c944",
//   "name": "Testing",
//   "isRoot": true,
//   "elements": [
//       {
//           "elementType": "endPoint",
//           "html": "inputGroup",
//           "options": [
//               {
//                   "_id": "5ebcfbbde56b31118fa2c948",
//                   "label": "ui",
//                   "value": "ui"
//               },
//               {
//                   "_id": "5ebcfbbde56b31118fa2c947",
//                   "label": "ui2",
//                   "value": "ui2"
//               }
//           ],
//           "_id": "5ebcfbbde56b31118fa2c946",
//           "htmlType": "checkbox",
//           "label": "ui",
//           "name": "ui"
//       },
//       {
//           "elementType": "endPoint",
//           "html": "button",
//           "options": [],
//           "_id": "5ebcfbbde56b31118fa2c945",
//           "htmlType": "submit",
//           "name": "submit",
//           "label": "Submit"
//       }
//   ],
//   "usersToView": [],
//   "usersToAction": [],
//   "category": "endPointElement",
//   "createdBy": "1669",
//   "formId": "AXqrt5cs",
//   "ct": "2020-05-14T08:05:17.712Z",
//   "ut": "2020-05-14T08:05:17.712Z",
//   "__v": 0
// }



@Component({
  selector: 'app-create-dynamic-form',
  templateUrl: './create-dynamic-form.component.html',
  styleUrls: ['./create-dynamic-form.component.css']
})
export class CreateDynamicFormComponent implements OnInit {
  FORMS = {
    CREATE_FORM: 'createForm',
    UPDATE_FORM: 'updateForm',
  };

  formProject: SelectItem[];
  selectedNewFormProject;
  selectedUpdateFormProject;
  formCategory: SelectItem[];
  selectedNewFormCategory;
  selectedUpdateFormCategory;
  formName: SelectItem[];
  selectedNewFormName;
  selectedUpdateFormName;

  newFormDetails: {};
  updateFormDetails: {};
  dynamicFormBasicConfiguration = null;

  selectFormConfiguration: Boolean;

  activeFormNow = null;
  formNames: any[] = [];
  usersList: any = [];
  constructor(private _formService: DynamicFormService, private dbUsers: DbUsersService) {
    this.formProject = [
      { label: 'Operations Team TMS', value: 'operationsTMS' },
      { label: 'Rider Support TMS', value: 'riderSupportTMS' },
      { label: 'Tech Support TMS', value: 'techSupportTMS' },
      { label: 'Driver Partner TMS', value: 'driverPartnerTMS' }
    ];
    this.formCategory = [
      { label: 'Hierarchy Endpoint Form', value: 'endPointElement' },
      { label: 'Hierarchy Element', value: 'hierarchyElement' },
      { label: 'Stage Submission From', value: 'stageElement' }
    ];
  }
  onChangeDropdown() {
    if (this.selectedUpdateFormCategory && this.selectedUpdateFormProject) {
      this.formNames = [];
      this._formService.fetchAllActiveFormsFromProjectAndCategory(this.selectedUpdateFormProject, this.selectedUpdateFormCategory)
      .subscribe(({ code, message, response }: any) => {
        if (code !== 200) {
          return alert(message);
        } else {
          this.formNames = response;
        }
      });
    }
  }
  ngOnInit() {
    this.selectFormConfiguration = true;
    this.dbUsers.getAllUsers().subscribe((res: Response<[]>) => {
      let userListData = res.response;
      userListData.forEach((userData: any) => {
        this.usersList.push({ label: userData.name, value: userData.ssoId });
      })}
  )
  }
    
  restoreState(e) {
    this.selectedNewFormProject = '';
    this.selectedUpdateFormProject = '';
    this.selectedNewFormCategory = '';
    this.selectedUpdateFormCategory = '';
    this.selectedNewFormName = '';
    this.selectedUpdateFormName = '';
    this.newFormDetails = {};
    this.updateFormDetails = {};
    this.dynamicFormBasicConfiguration = null;
    this.selectFormConfiguration = true;
    this.activeFormNow = null;
    this.formNames = [];
  }
  createNewForm() {
    if (!(this.selectedNewFormProject && this.selectedNewFormProject && this.selectedNewFormName)) {
      alert("All fields are required");
    } else {
      this.newFormDetails = {
        formCategory: this.selectedNewFormCategory,
        category: this.selectedNewFormCategory,

        project: this.selectedNewFormProject,
        formProject: this.selectedNewFormProject,

        name: this.selectedNewFormName,
        formName: this.selectedNewFormName
      };
      this.selectFormConfiguration = false;
      this.dynamicFormBasicConfiguration = this.newFormDetails;
      this.activeFormNow = this.FORMS.CREATE_FORM;

    }
  }

  updateForm() {
    if (!(this.selectedUpdateFormProject && this.selectedUpdateFormCategory && this.selectedUpdateFormName)) {
      alert("All fields are required");
    } else {
      this.updateFormDetails = {};
      this.updateFormDetails['formProject'] = this.selectedUpdateFormProject;
      this.updateFormDetails['formCategory'] = this.selectedUpdateFormCategory;
      this.updateFormDetails['formName'] = this.selectedUpdateFormName;
      // api call then do this....
      console.log(
        'NICE\n',
        this.selectedUpdateFormName.formId,
        );
      this._formService.getFormById(
          this.selectedUpdateFormName.formId,
      )
      .subscribe(({ response: form, code, message, errors }: any) => {
        if (code !== 200) {
          console.log(errors);
          alert(message);
        } else {
          this.selectFormConfiguration = false;
          this.dynamicFormBasicConfiguration = form;
          this.activeFormNow = this.FORMS.UPDATE_FORM;
        }
      });

    }
  }

}
