import { Urls } from '../models/api.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
export class DynamicFormService {
    constructor(http, cookieService) {
        this.http = http;
        this.cookieService = cookieService;
    }
    saveDynamicForm(finalizedForm) {
        return this.http.post(Urls.APP_URL + '/api/v1/form/create-form', finalizedForm);
    }
    fetchCurrentlyActiveForm(project, category, name, isRoot) {
        const query = { project, category, name };
        if (typeof isRoot === 'boolean') {
            query.isRoot = isRoot;
        }
        project == 'techSupportTMS' ? query['isUserPermissionCheck'] = true : null;
        return this.http.post(Urls.APP_URL + '/api/v1/form/get-active-form', query);
    }
    updateDynamicForm(finalizedForm) {
        return this.http.put(Urls.APP_URL + '/api/v1/form/update-replace', finalizedForm);
    }
    getFormById(formId) {
        let body;
        this.cookieService.get('selectedProject') == 'techSupportTMS' ? body = { isUserPermissionCheck: true } : body = {};
        return this.http.get(Urls.APP_URL + '/api/v1/form/get-active-form-by-id?formId=' + formId, body);
    }
    fetchAllActiveFormsFromProject(project) {
        return this.http.post(Urls.APP_URL + '/api/v1/form/list-form', { project, isActive: true });
    }
    fetchAllActiveFormsFromProjectAndCategory(projectName, category) {
        let body = {
            'project': projectName,
            'category': category
        };
        return this.http.post(Urls.APP_URL + '/api/v1/form/list-form', body);
    }
    deactivateCurrentForm(formId) {
        return this.http.delete(Urls.APP_URL + '/api/v1/form/delete-form?formId=' + formId);
    }
}
DynamicFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicFormService_Factory() { return new DynamicFormService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: DynamicFormService, providedIn: "root" });
