import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(http) {
        this.http = http;
    }
    isTokenValid(token) {
        return this.http.get(Urls.APP_URL + '/api/v1/check/token?token=' + token).toPromise();
    }
    authenticate(username, password, gmailToken) {
        const res = this.http.post(Urls.APP_URL + '/api/v1/authenticate', { username, password, gmailToken });
        return res.toPromise();
    }
    logout(token) {
        return this.http.get(Urls.APP_URL + '/api/v1/logout?token=' + token).toPromise();
    }
    getUserRole(ssoId) {
        return this.http.get(Urls.HUB_URL + '/api/v1/user/role/' + ssoId, { headers: Headers.headers });
    }
    getAuthorizedZones() {
        return this.http.get(Urls.HUB_URL + '/api/v1/zone/permission', { headers: Headers.headers });
    }
    getAuthorizedZonesWithRole(ssoId) {
        return this.http.get(Urls.HUB_URL + '/api/v2/user/role/' + ssoId, { headers: Headers.headers });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
