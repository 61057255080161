import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../models/api.constants';
import { Observable } from 'rxjs';
import { Headers } from '../models/api.headers';

@Injectable({
  providedIn: 'root'
})
export class HubService {

  constructor(private http: HttpClient) { }
  getAllHubs(): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL + '/api/v1/hub/all', { headers: Headers.headers });
  }

  getAllAuthorizedHubs(userSsoId):Observable<{}>{
    return this.http.get<{}>(Urls.HUB_URL + '/api/v1/authorized/hub/all?ssoId=' + userSsoId, { headers: Headers.headers });
  }
  getHubListBasedOnZone(ssoId, zoneId): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL + '/api/v1/hub/permission/' + ssoId + '/' + zoneId, { headers: Headers.headers });
  }
}

