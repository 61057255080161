
    <div
      [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <label *ngIf="config.label" [attr.for]="config.htmlId"
        >{{ config.label }}
        <span
          *ngIf="config.validations && config.validations.required"
          class="required_field"
          >*</span
        >
      </label>
      <input
        pInputText
        id="input"
        [attr.id]="config.htmlId"
        [attr.type]="config.htmlType"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name"
      />
    </div>
  