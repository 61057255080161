
    <ng-container *ngIf="isLoadComplete">
      <form-component
        [formElementConfigArray]="formElementConfigArray"
        (formSubmitHandler)="formSubmit($event)"
        [formConfig] = "formConfig"
        [areWeUpForUpdate]="areWeUpForUpdate"
        (updateElementHandler)="updateFieldElementHandler($event)"
        (deleteElementHandler)="deleteFieldElementHandler($event)"
        (addElementHandler)="addFieldElementHandler($event)"
        (dropElementHandler)="dropFieldElementHandler($event)"

      >
      </form-component>
    </ng-container>
    <p *ngIf="!isLoadComplete" style="margin-bottom: 40px">{{loadMessage}}</p>
  