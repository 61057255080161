<div class="tickets-container">

    <div class="data-container">
        <div class="container-fluid">
            <h2>Ticket Settings</h2>
            <br/>
            
        </div>

        <br/>
         <br/>

        <div>
            <p-table  [value]="data">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Manufacturer</th>
                        <th>Model</th>
                        <th>Variant</th>
                        <th>Range</th>
                        <th>Service Kms</th>
                        <th>Daily Avg. Kms</th>
                        <th>Service Type</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dataitem let-rowIndex="rowIndex" let-columns="columns">
                    <tr>
                      
                        <td>{{dataitem.manufacturer}}</td>
                        <td>{{dataitem.model}}</td>
                        <td>{{dataitem.variant}}</td>
                        <td>{{dataitem.range}}</td>
                        <td>{{dataitem.serviceKms }}</td>
                        <td>{{dataitem.dailyAvgKms}}</td>
                        <td>{{dataitem.serviceType}}</td>
                        <td>
                            <button pButton  type="button" icon="pi pi-pencil" class="custom-btn" (click)="editProduct(dataitem,rowIndex)"></button> &nbsp;
                            <button pButton  type="button" icon="pi pi-trash" class="custom-btn" (click)="deleteProduct(dataitem,rowIndex)"></button>
                        </td>
                    </tr>
                    <!-- <tr *ngIf="(dataLength == rowIndex+1) || dataLength==0" >
                        <td colspan="8" style="text-align: center;align-items: center; border-bottom: none;">
                            <button pButton icon="pi pi-plus-circle" type="button" label="Add new record" class="custom-btn" (click)="openNew()"></button>
                        </td>
                    </tr> -->
                </ng-template>
                <ng-template pTemplate="footer" >
                    <tr >
                        <td colspan="8" style="text-align: center;align-items: center; border-bottom: none;" *ngIf="checkPermission('SERVICE_TICKET_CREATE_CONFIG')">
                            <button pButton icon="pi pi-plus-circle" type="button" label="Add new record" class="custom-btn" (click)="openNew()"></button>
                        </td>
                    </tr>
                    
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-toast></p-toast>

<p-dialog [(visible)]="productDialog"  header="Config Details" [modal]="true" >
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus"> Manufacturer</label>
        <p-dropdown [(ngModel)]="selectedData.manufacturer"  #manufacturer inputId="manufacturer" [options]="manufacturerList" placeholder="Select" (onChange)="populateModel(manufacturer.value)">
            <ng-template let-option pTemplate="item">
                {{option.label}}
            </ng-template>
        </p-dropdown>
    </div>
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Model</label>
        <p-dropdown [(ngModel)]="selectedData.model" inputId="model" [disabled]="!selectedData.manufacturer" #model [options]="modelList" placeholder="Select" (onChange)="populateVariant(model.value)">
            <ng-template let-option pTemplate="item">
                {{option.label}}
            </ng-template>
        </p-dropdown>
    </div>
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Variant</label>
        <p-dropdown [(ngModel)]="selectedData.variant" inputId="variant" [disabled]="!selectedData.model"  #variant [options]="variantList" placeholder="Select" (onChange)="populateRange(variant.value)">
            <ng-template let-option pTemplate="item">
                {{option.label}}
            </ng-template>
        </p-dropdown>
    </div>
    <!-- <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Range</label>
        <input type="text" pInputText [(ngModel)]="selectedData.range" inputId="manufacturer"  placeholder="Enter Range" />
           
    </div> -->
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Range</label>
        <p-dropdown [(ngModel)]="selectedData.range" inputId="range" [disabled]="!selectedData.variant" [options]="rangeList" placeholder="Select" >
            <ng-template let-option pTemplate="item">
                {{option.label}}
            </ng-template>
        </p-dropdown>
    </div>
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Service Kms</label>
        <input type="text" pInputText [(ngModel)]="selectedData.serviceKms" inputId="serviceKms"  placeholder="Enter Service Kms" />

    </div>
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Daily Avg Kms</label>
        <input type="text" pInputText [(ngModel)]="selectedData.dailyAvgKms" inputId="dailyAvgKms"  placeholder="Enter Daily Avg Kms" />

    </div>
    <div class="p-field" style="display: flex;justify-content: space-between;">
        <label for="inventoryStatus">Service Type</label>
        <p-dropdown [(ngModel)]="selectedData.serviceType" inputId="serviceType" [options]="serviceTypeList" placeholder="Select">
            <ng-template let-option pTemplate="item">
                {{option.label}}
            </ng-template>
        </p-dropdown>
    </div>

    <br/>
    <br/>
    <div class="p-field" style="display: flex;justify-content:center ;">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> &nbsp; &nbsp; &nbsp;
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </div>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>