import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RiderSupportService {
    constructor(http) {
        this.http = http;
    }
    getAllTickets(obj) {
        console.log("calliung post api");
        return this.http.post(Urls.APP_URL + '/api/v2/tickets/load', obj, { headers: Headers.headers });
    }
    sendCSV(body) {
        return this.http.post(Urls.APP_URL + '/api/v2/tickets/csv', body, { headers: Headers.headers });
    }
}
RiderSupportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RiderSupportService_Factory() { return new RiderSupportService(i0.ɵɵinject(i1.HttpClient)); }, token: RiderSupportService, providedIn: "root" });
