import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalService } from 'src/app/services/local.service';
import { ServiceTicketService } from 'src/app/services/service-ticket.service';



@Component({
  selector: 'app-ticket-calendar',
  templateUrl: './ticket-calendar.component.html',
  styleUrls: ['./ticket-calendar.component.css']
})
export class TicketCalendarComponent implements OnInit {

  events: any[];
  options: any;
  calendarFor: string;
  selectedDate = null;
  lastDate:any;
  dateToLoad:any;
  
  constructor(private _serviceTicketService: ServiceTicketService,private spinner: NgxSpinnerService, public localeService: LocalService) { }

  ngOnInit() {

    this.lastDate = localStorage.getItem("calanderDate");
    if(this.lastDate){
      this.dateToLoad = this.localeService.getDate(this.lastDate)
    }else {
      this.dateToLoad = this.localeService.getDate();
    }
    
    this.options = {
      plugins: [dayGridPlugin, interactionPlugin],
      defaultDate: this.dateToLoad,
      // header: {
      //     // left: 'prev,next',
      //     center: 'title',
      //     // right: 'dayGridMonth,timeGridWeek,timeGridDay'
      // },
     
      dateClick: (e) => {
        if(this.calendarFor != 'driverTMS'){
          let date = this.localeService.getDate(e.dateStr)
          this.selectedDate = date;
          localStorage.setItem("calanderDate",date.toString());
        }
      },
      datesRender: (data)=>{
      this.getCalanderEvents(data.view.calendar.state.currentDate);
      }
      // eventClick:(e) =>{
       
      //   let date = moment(e.event.start).toLocaleString();
      //   this.selectedDate = date;
      //   localStorage.setItem("calanderDate",date);
      // }
    }
  }

  getCalanderEvents(dateSelected){
    let date = this.localeService.getDate(dateSelected).getTime() / 1000;
    this.calendarFor = localStorage.getItem('calendarFor');
    
    if(this.calendarFor && this.calendarFor== 'driverTMS'){
      this._serviceTicketService.getServiceTicketEventsForDriverTMS(date).subscribe(
        (responseServer: any) => {
            this.spinner.hide();
            const { status: code, errorMessage: message, } = responseServer;
            if (code != 200) {
                return alert(message);
            } else {
              this.events =responseServer.response;
            }
        }
    );
    }else{
      this._serviceTicketService.getServiceTicketEvents(date).subscribe(
        (responseServer: any) => {
            this.spinner.hide();
            const { status: code, errorMessage: message, } = responseServer;
            if (code != 200) {
                return alert(message);
            } else {
              this.events =responseServer.response;
            }
        }
    );
    }
  }

  showCalander(event){
    if(event){
      this.selectedDate = null;
      this.ngOnInit();
    }
  }

}
