import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { MenuItem, MessageService } from "primeng/api";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { IncidentHeirarchyService } from "src/app/services/incident-heirarchy.service";
import { TicketViewService } from "src/app/services/ticket-view.service";
import { Response } from "src/app/models/api.dto";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { Renderer2 } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SubIssue } from "src/app/utils/types/global";
import { Form, Issue } from "src/app/utils/types/incident";
import { MultiSelect } from "primeng/multiselect";

@Component({
  selector: "app-incident-heirarchy",
  templateUrl: "./incident-heirarchy.component.html",
  styleUrls: ["./incident-heirarchy.component.css"],
})
export class IncidentHeirarchyComponent implements OnInit {
  @ViewChildren("subIssueMultiSelects")
  subIssueMultiSelects: QueryList<MultiSelect>;
  @ViewChild("divClick", { static: false }) divClick: ElementRef;
  isLoadComplete = false;
  isGoingBackPossible = false;
  ticketCategory = "";
  showPopup = false;
  showSecondPopup = false;
  vehicleList = [];
  issueNgModel = [];
  customIndex: number;
  issueOptionSelected: string = "";
  subIssue: SubIssue[] = [
    {
      issueId: "",
      issueName: "",
      subIssues: [],
    },
  ];
  issueList: Issue[] = [];
  displayDialog: boolean = false;
  @Input() rootForm: Form;

  currentlyActiveForm: Form;
  previouslyActiveForm: Form | null = null;
  @ViewChild("selectDriverForm", { static: false }) selectDriverForm: any;

  nextPrevFormRecords = {};

  activeIndex = 0;
  disabled: boolean = false;

  values = {};
  items: MenuItem[];
  ticketId: any;
  ticketList = [];
  vehicleNumber = "";
  showMessage: string;
  formElementConfigArray: any;
  driverListData = [];
  keysData = "";
  selectedDriver = "";
  private searchString = new Subject<string>();
  constructor(
    private _incidentHeirarchySercive: IncidentHeirarchyService,
    private _dynamicFormService: DynamicFormService,
    private ticketViewService: TicketViewService,
    private messageService: MessageService,
    private _cookieService: CookieService,
    private route: Router,
    private renderer: Renderer2,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  subIssueListMap: { [key: string]: any } = {};

  ngOnInit() {
    this.disabled = false;
    this.displayDialog = false;
    this.getHeirarchyRootForm();
    this.fetchIssues();
    // this.getPaginatedDrivers('')
    this.searchString.pipe(debounceTime(10)).subscribe((searchValue) => {
      this.getPaginatedDrivers(searchValue);
    });
  }

  fetchIssues() {
    this._incidentHeirarchySercive.getIssues().subscribe((response) => {
      try {
        if (response.statusCode !== 200) {
          alert(response.errorMessage);
          return;
        } else {
          this.issueList = response.response.map((issue) => ({
            label: issue.issueName,
            value: issue.issueId,
          }));
          if (this.issueList.length) {
            const zoneId = this._cookieService.get("selectedZone");
            const issueId = this.issueList[0].value;
            this.fetchSubIssues(issueId, zoneId);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  fetchSubIssues(issueId: string | number, zoneId: string | number) {
    this._incidentHeirarchySercive
      .getSubIssusesList(zoneId, issueId)
      .subscribe((response) => {
        try {
          if (response.code !== 200) {
            alert(response.errorMessage);
            return;
          } else {
            const subIssues = response.response.map((subIssue) => ({
              label: subIssue.subIssueName,
              value: subIssue.subIssueId,
            }));
            this.subIssueListMap[issueId] = subIssues;
          }
        } catch (e) {
          console.log(e);
        }
      });
  }

  showData() {
    this.showPopup = true;
  }

  showSecondDialog() {
    this.showSecondPopup = true;
  }

  showTicketDetails() {
    localStorage.setItem("ticketId", this.ticketId);
    this.route.navigate(["/ticket/view"]);
  }

  handleSelectedIssue(
    selectedIssueValue: { label: string; value: string },
    index: number,
    issue: any
  ) {
    issue.subIssues = [];
    const multiSelectArray = this.subIssueMultiSelects.toArray();
    if (multiSelectArray[index]) {
      multiSelectArray[index].updateLabel();
      multiSelectArray[index].writeValue([]);
    }
    this.subIssue[index].issueId = selectedIssueValue.value;
    const selectedIssue = this.issueList.find(
      (issue) => issue.value === selectedIssueValue.value
    );

    const repeatedIssueOption = this.subIssue.some(
      (sub) => sub.issueName === selectedIssueValue.label
    );

    if (!repeatedIssueOption) {
      const zoneId = this._cookieService.get("selectedZone");
      this.subIssue[index].issueName = selectedIssue.label;
      this.fetchSubIssues(selectedIssue.value, zoneId);
    } else {
      this.displayDialog = true;
      this.issueOptionSelected = selectedIssueValue.label;
      this.issueNgModel.splice(index, 1);
      this.subIssue.splice(index, 1);
      setTimeout(() => {
        this.displayDialog = false;
      }, 8000);
      return;
    }
  }

  handleSelectedSubIssues(
    selectedSubIssues: { label: string; value: string }[],
    index: number
  ) {
    const selectedSubIssueValues = selectedSubIssues.map((subIssue) => ({
      subIssueId: subIssue.value,
      subIssueName: subIssue.label,
    }));
    this.subIssue[index].subIssues = selectedSubIssueValues;

    if (!selectedSubIssues.length) {
      this.subIssue[index].subIssues = [];
    }
  }

  addIssue(index) {
    this.subIssue.push({
      issueId: "",
      issueName: "",
      subIssues: [],
    });
    this.issueNgModel[index] = [];
  }

  removeIssue(index: number) {
    this.issueNgModel.splice(index, 1);
    this.subIssue.splice(index, 1);
  }

  submitHandler() {
    if (
      this.currentlyActiveForm.name === "Unplanned Incident Repair" &&
      this.selectDriverForm.controls.selectedDriver.value.value === undefined
    ) {
      alert("Please select the driver");
      return "";
    }
    if (this.disabled == true) {
      return "";
    }

    const { usersToAction } = this.currentlyActiveForm;
    let postValues: any = {
      usersToAction,
    };
    // linkedlist like traversal from root to all linked forms
    const HIERARCHY_ORDER = [];
    let current = this.rootForm.formId;
    const view = new Set();
    const action = new Set();
    while (current) {
      if (!this.values[current]) {
        current = null;
      } else {
        // if (this.nextPrevFormRecords[current].form.usersToView) {
        //   for (const { ssoId } of this.nextPrevFormRecords[current].form.usersToView) {
        //     view.add(ssoId);
        //   }
        // }

        // if (this.nextPrevFormRecords[current].form.usersToAction) {
        //   for (const { ssoId } of this.nextPrevFormRecords[current].form.usersToAction) {
        //     action.add(ssoId);
        //   }
        // }
        for (const key of Object.keys(this.values[current])) {
          if (key.toUpperCase().includes("HIERARCHY_")) {
            HIERARCHY_ORDER.push(key.replace("HIERARCHY_", ""));
          }
        }

        postValues = {
          ...postValues,
          ...this.values[current],
        };
        let data = this.values[current];
        current = this.nextPrevFormRecords[current].next;
      }
    }
    // postValues.HIERARCHY_ORDER = HIERARCHY_ORDER.slice(1);
    // const formData = new FormData();
    // for (const key in postValues) {
    //   if (postValues[key]) {
    //     if (key.toLowerCase().includes('upload') && postValues[key].length > 1) {
    //       const ArrayNew: any = Array.from(postValues[key]);
    //       ArrayNew.forEach(element => {
    //         formData.append(key, element);
    //       });
    //     } else {
    //       formData.append(key, postValues[key]);
    //     }
    //   }
    // }
    HIERARCHY_ORDER.push(this.currentlyActiveForm["name"]);
    this.isLoadComplete = false;
    const ACTIVE_FORMID = this.currentlyActiveForm.formId;
    HIERARCHY_ORDER.shift();

    // const assignedForAction = Array.from(action).filter(Boolean);
    // const assignedForView = Array.from(view).filter(Boolean);
    const { dataFormData, ...rest } = this.values[ACTIVE_FORMID];
    if (
      this.currentlyActiveForm.name === "Unplanned Incident Repair" ||
      this.currentlyActiveForm.name === "Unplanned Non Incident Repair"
    ) {
      rest["Driver"] =
        this.selectDriverForm.controls.selectedDriver.value.value;
      rest["subIssue"] = this.subIssue;
      const allIssuesHaveNames = this.subIssue.every(
        (issue) => issue.issueName
      );
      if (!allIssuesHaveNames) {
        alert("Please select an issue from dropdown");
        return;
      }
    }
    dataFormData.append("formData", JSON.stringify(rest));
    dataFormData.append("formId", ACTIVE_FORMID);
    dataFormData.append("category", JSON.stringify(HIERARCHY_ORDER));
    // console.log(
    //   Array.from(dataFormData.keys()),
    //   Array.from(dataFormData.values()),
    //   dataFormData
    // );
    // may delte other properties except formId, formData, category, files
    const apiData = {
      formData: this.values[ACTIVE_FORMID],
      formId: ACTIVE_FORMID,
      category: HIERARCHY_ORDER,
    };
    this.disabled = true;
    this.ticketViewService
      .createTicket(dataFormData)
      .subscribe(({ response, message, code }: any) => {
        this.disabled = false;
        if (code === 403) {
          this.ticketId = message[0];
          this.ticketCategory =
            "A " +
            apiData.category[1] +
            " ticket " +
            response.ticketData +
            " already exist for " +
            message[1];
          this.vehicleList = response["vehicleNumber"];
          this.ticketList = response.ticketData;
          this.vehicleNumber = message[1];
          if (message[2] != null) {
            this.showMessage =
              "New tickets under " +
              apiData.category[1] +
              " category will be allowed once the existing ticket is closed.";
          } else {
            this.showMessage = "You can go to this ticket and edit the issue.";
          }

          if (response["vehicleNumber"].length > 1) {
            this.ticketCategory =
              "A " +
              apiData.category[1] +
              " ticket already exist for the following vehicles.";
            this.showSecondDialog();
          } else {
            this.ticketCategory = "A " + apiData.category[1] + " ticket";
            this.showData();
          }
          return;
        }
        if (code !== 200) {
          alert(message);
          return;
        }
        this.subIssue = [
          {
            issueId: "",
            issueName: "",
            subIssues: [],
          },
        ];
        this.issueNgModel = [
          {
            issueId: "",
            issueName: "",
            subIssues: [],
          },
        ];
        const { actionMailedList, actionMessageList, ticketId } = response;
        if (ticketId) {
          this.selectedDriver = "";
          this.messageService.add({
            severity: "success",
            summary: `Ticket Created`,
            detail: `ID: ${ticketId}`,
            life: 10000,
          });
          this.showPopup = false;
        }
        if (Array.isArray(actionMailedList) && actionMailedList.length) {
          this.messageService.add({
            severity: "info",
            summary: `Email sent to`,
            data: actionMailedList,
            life: 10000,
          });
        }
        if (Array.isArray(actionMessageList) && actionMessageList.length) {
          this.messageService.add({
            severity: "info",
            summary: `Message sent to`,
            data: actionMessageList,
            life: 10000,
          });
        }
        this.restoreState();
      });
  }
  restoreState() {
    this.isLoadComplete = false;
    this.isGoingBackPossible = false;
    this.currentlyActiveForm = null;
    this.previouslyActiveForm = null;
    this.nextPrevFormRecords = {};
    this.activeIndex = 0;
    this.values = {};
    this.items = [];
    setTimeout(() => {
      this.previouslyActiveForm = null;
      this.currentlyActiveForm = this.rootForm;
      this.nextPrevFormRecorder();
      this.setStepperLength();
      this.isLoadComplete = true;
    }, 0);
  }
  getFormById(nextFormId = null, previousFormValues = null) {
    // console.log('getFormById() \n', nextFormId, previousFormValues);
    this.isLoadComplete = false;
    if (!nextFormId) {
      return;
    }
    this._incidentHeirarchySercive
      .getFormById(nextFormId)
      .subscribe((responseServer) => {
        this.isLoadComplete = true;
        const { errorCode, errorMessage, response } = responseServer;
        if (errorCode !== 200) {
          alert(errorMessage);
        } else {
          const currentForm = this.currentlyActiveForm;
          const { formId } = currentForm;
          this.currentlyActiveForm = null;
          setTimeout(() => {
            {
              // record current form values
              this.values[formId] = previousFormValues;
            }

            {
              // setting the prev, next...
              this.previouslyActiveForm = currentForm;
              this.currentlyActiveForm = response;
              this.nextPrevFormRecorder();
            }
            // console.log('nextPrevFormRecords', this.nextPrevFormRecords);
            this.setStepperLength();
            this.activeIndex++;
          }, 0);
        }
      });
  }
  nextPrevFormRecorder() {
    if (!this.currentlyActiveForm) {
      return;
    }

    {
      this.nextPrevFormRecords[this.currentlyActiveForm.formId] = {
        formId: this.currentlyActiveForm.formId,
        form: this.currentlyActiveForm,
        prev: this.previouslyActiveForm
          ? this.previouslyActiveForm.formId
          : null,
        next: null,
      };
    }
    {
      if (this.previouslyActiveForm) {
        this.isGoingBackPossible = true;

        if (this.nextPrevFormRecords[this.previouslyActiveForm.formId]) {
          this.nextPrevFormRecords[this.previouslyActiveForm.formId].next =
            this.currentlyActiveForm.formId;
        } else {
          this.nextPrevFormRecords[this.previouslyActiveForm.formId] = {
            formId: this.previouslyActiveForm.formId,
            form: this.previouslyActiveForm,
            prev: null,
            next: this.currentlyActiveForm.formId,
          };
        }
      }
      // // console.log('nextPrevFormRecorder', this.nextPrevFormRecords);
    }
  }
  setStepperLength() {
    const { formId: currentFormId, name } = this.currentlyActiveForm;
    const items = [];
    let now = this.rootForm.formId;
    while (now && now !== currentFormId) {
      items.push({
        label: this.nextPrevFormRecords[now].form.name,
      });
      now = this.nextPrevFormRecords[now].next;
    }
    items.push({
      label: name,
    });
    // console.log('setStepperLength', items);
    this.items = items;
  }
  activeIndexChangeHandler(indexToStop) {
    // console.log('indexToStop', indexToStop);
    // if (indexToStop < this.activeIndex) {
    //   if (!confirm('Going back will result loss of data')) {
    //     return;
    //   }
    // }

    if (this.activeIndex === 2) {
      this.subIssue = [
        {
          issueId: "",
          issueName: "",
          subIssues: [],
        },
      ];
      this.issueNgModel = [
        {
          issueId: "",
          issueName: "",
          subIssues: [],
        },
      ];
      this.displayDialog = false;
    }

    var formActiveRecord = null;
    {
      let now = this.rootForm.formId;

      let i = 0;
      while (i < indexToStop && now) {
        now = this.nextPrevFormRecords[now].next;
        i++;
      }
      formActiveRecord = this.nextPrevFormRecords[now];
    }

    if (!formActiveRecord) {
      return;
    }

    this.previouslyActiveForm = formActiveRecord.prev
      ? this.nextPrevFormRecords[formActiveRecord.prev].form
      : null;
    const { form, formId } = formActiveRecord;
    const value = this.values[formId];
    if (value) {
      const { elements } = form;
      form.elements = elements.map((element) => {
        if (value[element.name]) {
          element.value = value[element.name];
        }
        return element;
      });
    }
    this.currentlyActiveForm = null;
    setTimeout(() => {
      this.currentlyActiveForm = form;
      this.activeIndex = indexToStop;
      this.nextPrevFormRecorder();
      this.setStepperLength();
    }, 0);
  }
  submitCurrentForm({ values, fields, formData }) {
    if (
      !values["Service (KMs)"] &&
      values["Vehicle Number"] &&
      (values.odometerReading === 0 ||
        /^0\d*\.?\d*$/.test(values.odometerReading))
    ) {
      alert("Please enter the correct odometer reading.");
      return;
    }

    const rootProject =
      this._cookieService.get("selectedProject") || "operationsTMS";
    const hub = this._cookieService.get("selectedHub");
    if (hub === "0" && rootProject === "operationsTMS") {
      alert("Please select the Hub");
      return;
    }
    const { category = "endPointElement" } = this.currentlyActiveForm;
    const formId = fields.formId;
    {
      // record current form values
      this.values[formId] = {
        ...values,
        dataFormData: formData,
      };
    }
    if (category !== "hierarchyElement") {
      return this.submitHandler();
    } else {
      // If there was a hiearchy field and its value was provided
      delete fields.formId;
      const Entries: any[] = Object.entries(fields);
      for (const [name, formField] of Entries) {
        const { type, options } = formField;
        if (type === "select") {
          const NextFormId = values[name];
          if (options) {
            const option = options.filter((op) => op.value === NextFormId)[0];
            this.values[formId][name] =
              option && option.label ? option.label : option.value;
          }
          // console.log(this.values[formId]);
          return this.getFormById(NextFormId, values);
        }
      }
    }
  }
  getHeirarchyRootForm(): void {
    this.isLoadComplete = false;
    const rootProject =
      this._cookieService.get("selectedProject") || "operationsTMS";
    this._dynamicFormService
      .fetchCurrentlyActiveForm(rootProject, "hierarchyElement", "Ticket", true)
      .subscribe((responseServer) => {
        this.isLoadComplete = true;
        if (responseServer.errorCode !== 200) {
          return alert(responseServer.errorMessage);
        } else {
          const { response: rootForm } = responseServer;
          this.isGoingBackPossible = false;
          {
            // update the root form
            this.rootForm = rootForm;
          }
          this.currentlyActiveForm = null;
          setTimeout(() => {
            // prev and next setting
            this.previouslyActiveForm = null;
            this.currentlyActiveForm = rootForm;
            this.nextPrevFormRecorder();
            this.setStepperLength();
            this.activeIndex = 0;
          }, 0);
        }
      });
  }

  goBackOneTime() {
    // if going back is not possible then do nothing
    if (!this.isGoingBackPossible || !this.previouslyActiveForm) {
      return;
    }
    if (
      !confirm(
        "Going a step back will result in loosing all data for current & previous form"
      )
    ) {
      return;
    }
    return this.activeIndexChangeHandler(this.activeIndex - 1);
  }
  onReject(key) {
    this.messageService.clear(key);
  }

  getPaginatedDrivers(searchKey) {
    this._incidentHeirarchySercive
      .getPaginatedDrivers(searchKey)
      .subscribe((res) => {
        if (res["response"].length > 0) {
          this.driverListData = [];
          this.selectDriverForm.control.markAsPristine();
          for (let i = 0; i < res["response"].length; i++) {
            this.driverListData.push({
              label: res["data"][i].name + "," + res["data"][i].id,
              value: res["data"][i].name + "," + res["data"][i].id,
            });
            this.changeDetectorRefs.detectChanges();
          }
        } else {
          if (this.keysData !== "") {
            this.selectDriverForm.control.markAsDirty();
          }
        }
      });
  }

  getDriver(event) {
    this.selectDriverForm.control.markAsPristine();
    this.keysData = event.query;
    this.searchString.next(this.keysData);
    // this.getPaginatedDrivers(this.keysData)
  }
  removeEvent(event) {
    this.selectDriverForm.control.markAsPristine();
    this.driverListData = [];
    if (event.keyCode == 8) {
      this.driverListData = [];
      this.selectDriverForm.control.markAsPristine();
    }
    if (event.keyCode == 46) {
      this.driverListData = [];
      this.selectDriverForm.control.markAsPristine();
    }
  }
  onSelect() {
    this.divClick.nativeElement.click();
    this.selectDriverForm.control.markAsPristine();
  }
}

/*

@Component({
  selector: 'app-incident-heirarchy',
  templateUrl: './incident-heirarchy.component.html',
  styleUrls: ['./incident-heirarchy.component.css']
})
export class IncidentHeirarchyComponent implements OnInit {
  isLoadComplete = false;
  isGoingBackPossible = false;
  @Input() rootForm: Form;

  currentlyActiveForm: Form;
  previouslyActiveForm: Form | null = null;

  nextPrevFormRecords = {};

  activeIndex = 0;

  values = {};
  items: MenuItem[];
  constructor(private _incidentHeirarchySercive: IncidentHeirarchyService) { }
  setStepperLength() {
    const{ formId: currentFormId, name } = this.currentlyActiveForm;
    const items = [];
    let now = this.rootForm.formId;
    while (
      now && now !== currentFormId
    ) {
      items.push({
        label: this.nextPrevFormRecords[now].form.name,
      });
      now = this.nextPrevFormRecords[now].next;
    }
    items.push({
      label: name,
    });
    // console.log(items);
    // console.log(this.nextPrevFormRecords);
    this.items = items;
  }
  ngOnInit() {
    this.getHeirarchyRootForm();
  }
  activeIndexChangeHandler(indexToStop) {
    if (indexToStop >= 0 ) {
      let i = indexToStop;
      let now = this.currentlyActiveForm.formId;
      let formActiveRecord = null;
      while (i >= 0 && now && this.nextPrevFormRecords[now]) {
        if (i === indexToStop) {
          formActiveRecord = this.nextPrevFormRecords[now];
        }
        now = this.nextPrevFormRecords[now].prev;
        i--;
      }
      if (!formActiveRecord) {
        console.error('Logical error!!');
        return;
      }
      this.previouslyActiveForm = formActiveRecord.prev ? this.nextPrevFormRecords[formActiveRecord.prev] : null;
      const { form, formId } = formActiveRecord;
      const value = this.values[formId];
      if (value) {
        const { elements } = form;
        form.elements = elements.map((element) => {
          if (value[element.name]) {
            element.value = value[element.name];
          }
          return element;
        });
      }
      this.currentlyActiveForm = null;
      setTimeout(() => {
        this.currentlyActiveForm = form;
        this.setStepperLength();
        this.activeIndex = indexToStop;

      }, 0);

    }
  }
  submitHandler() {

    const { usersToAction } = this.currentlyActiveForm;
    let postValues = {
      usersToAction,
    };
    // linkedlist like traversal from root to all linked forms
    let current = this.rootForm.formId;
    while (current) {
      if (!this.values[current]) {
        current = null;
      } else {
        postValues = {
          ...postValues,
          ...this.values[current],
        };
        current = this.nextPrevFormRecords[current].next;
      }
    }
    const formData = new FormData();
    for (const key in postValues) {
      if (postValues[key]) {
        if (key.toLowerCase().includes('upload') && postValues[key].length > 1) {
          const ArrayNew: any = Array.from(postValues[key]);
          ArrayNew.forEach(element => {
            formData.append(key, element);
          });
        } else {
          formData.append(key, postValues[key]);
        }
      }
    }
    // console.log(postValues);
    return;

    this.isLoadComplete = false;



    this
      ._incidentHeirarchySercive
      .submitTheForm(formData)
      .subscribe((responseServer) => {
        this.isLoadComplete = true;
        if (responseServer.errorCode !== 200) {
          alert(responseServer.errorMessage);
        } else {
          alert('Submitted');
        }
      });

  }
  getFormById(nextFormId = null, previousFormValues = null) {
    this.isLoadComplete = false;
    if (!nextFormId) {
      return;
    }
    this._incidentHeirarchySercive
      .getFormById(nextFormId)
      .subscribe((responseServer) => {
        this.isLoadComplete = true;
        const { errorCode, errorMessage, response } = responseServer;
        if (errorCode !== 200) {
          alert(errorMessage);
        } else {
          // console.log('RESPONSE', response);
          const currentForm = this.currentlyActiveForm;
          const { formId } = currentForm;
          this.currentlyActiveForm = null;
          setTimeout(() => {
            // console.log('GET FORM BY ID');
            {
              // record current form values
              this.values[formId] = previousFormValues;
            }

            {
              // setting the prev, next...
              this.previouslyActiveForm = currentForm;
              this.currentlyActiveForm = response;
              this.nextPrevFormRecorder();
            }
            this.setStepperLength();

          }, 0);
        }
      });
  }
  nextPrevFormRecorder() {
    if (!this.currentlyActiveForm) {
      return;
    }

    {
      this.nextPrevFormRecords[this.currentlyActiveForm.formId] = {
        formId: this.currentlyActiveForm.formId,
        form: this.currentlyActiveForm,
        prev: this.previouslyActiveForm ? this.previouslyActiveForm.formId : null,
        next: null,
      };
    }
    {
      if (this.previouslyActiveForm) {
        this.isGoingBackPossible = true;

        if (this.nextPrevFormRecords[this.previouslyActiveForm.formId]) {
          this.nextPrevFormRecords[this.previouslyActiveForm.formId].next = this.currentlyActiveForm.formId;
        } else {
          this.nextPrevFormRecords[this.previouslyActiveForm.formId] = {
            formId: this.previouslyActiveForm.formId,
            form: this.previouslyActiveForm,
            prev: null,
            next: this.currentlyActiveForm.formId,
          };
        }

      }
      // console.log('nextPrevFormRecorder', this.nextPrevFormRecords);
    }


  }
  submitCurrentForm({ values, fields }) {
    const { category = 'endPointElement' } = this.currentlyActiveForm;
    // console.log(category);
    if (category === 'heirarchyElement') {
      // If there was a hiearchy field and its value was provided
      const formId = fields.formId;
      {
        // record current form values
        this.values[formId] = values;
      }
      delete fields.formId;
      const Entries: any[] = Object.entries(fields);
      for (const [name, formField] of Entries) {
        const { type } = formField;
        if (type === 'select') {
          return this.getFormById(values[name], values);
        }
      }
    }

    return this.submitHandler();
  }
  getHeirarchyRootForm(): void {
    this.isLoadComplete = false;
    this._incidentHeirarchySercive.getHeirarchyRootForm('ticket-management-system', 'ticket-config-form')
      .subscribe((responseServer) => {
        this.isLoadComplete = true;
        if (responseServer.errorCode !== 200) {
          return alert(responseServer.errorMessage);
        } else {
          const { response: rootForm } = responseServer;
          this.isGoingBackPossible = false;
          {
            // update the root form
            this.rootForm = rootForm;
          }
          this.currentlyActiveForm = null;
          setTimeout(() => {
            // prev and next setting
            this.previouslyActiveForm = null;
            this.currentlyActiveForm = rootForm;
            this.nextPrevFormRecorder();
            this.setStepperLength();
          }, 0);

        }
      }, (err) => {
        // console.log('ERROR', err);
      });
  }

  goBackOneTime() {

    // if going back is not possible then do nothing
    if (!this.isGoingBackPossible || !this.previouslyActiveForm) {
      return;
    }
    if (!confirm('Going a step back will result in loosing all data for current & previous form')) {
      return;
    }


    this.currentlyActiveForm = null;

    setTimeout(() => {
      const { formId, elements } = this.previouslyActiveForm;
      // if values for previous form values were recorded then form must render with these recorded values
      {
        const previousFormValues = this.values[formId];
        if (previousFormValues) {
          this.previouslyActiveForm.elements = elements.map((element) => {
            const { name } = element;
            if (previousFormValues[name]) {
              element.value = previousFormValues[name];
            }
            return element;
          });
        }
      }
      // at this point previous form is successfully updated form any previously filled value so
      // update current form as prev form
      this.currentlyActiveForm = this.previouslyActiveForm;
      // console.log('SET CURRENTLY AS PREV', this.currentlyActiveForm);
      {
        this.previouslyActiveForm =
          (
            this.nextPrevFormRecords[formId] &&
            this.nextPrevFormRecords[formId].prev
          ) ?
            (
              this.nextPrevFormRecords[this.nextPrevFormRecords[formId].prev].form
            ) : null;

        if (!this.previouslyActiveForm) {
          this.isGoingBackPossible = false;
          this.setStepperLength();
          return;
        }
        this.setStepperLength();
        this.isGoingBackPossible = true;
        return;
      }

    }, 0);

  }

}
*/
