import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Urls } from '../../models/api.constants';
import { PermissionForUsers } from '../../guards/checkRoles';

@Component({
  selector: 'app-service-ticket',
  templateUrl: './service-ticket.component.html',
  styleUrls: ['./service-ticket.component.css']
})
export class ServiceTicketComponent implements OnInit {

  selectedTab;

  constructor(private cookieService: CookieService, private router: Router) {
  }

  ngOnInit() {
    let currentURL =this.router.url;
    this.selectedTab = currentURL.substring(currentURL.lastIndexOf('/')+1,currentURL.length);
    this.cookieService.set('routeLocation', currentURL, null, '/', Urls.COOKIE_URL,null,"Lax");
  }

  ngOnDestroy() {
    this.cookieService.delete('routeLocation', '/', Urls.COOKIE_URL);
  }


  isTabSelected(key) {
    return key == this.selectedTab;
  }

  changeTab(key) {
    this.selectedTab = key;
    this.router.navigateByUrl('/ticket/serviceticket/'+key);
    this.cookieService.set('routeLocation', '/ticket/serviceticket/'+key, null, '/', Urls.COOKIE_URL,null,"Lax");
  }
  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }

}
