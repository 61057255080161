/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-calendar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/fullcalendar/fullcalendar.ngfactory";
import * as i3 from "primeng/components/fullcalendar/fullcalendar";
import * as i4 from "../daywise-tickets/daywise-tickets.component.ngfactory";
import * as i5 from "../daywise-tickets/daywise-tickets.component";
import * as i6 from "../../../services/ticket-view.service";
import * as i7 from "ngx-cookie-service";
import * as i8 from "@angular/router";
import * as i9 from "../../../services/db-users.service";
import * as i10 from "../../../services/hub.service";
import * as i11 from "../../../services/local.service";
import * as i12 from "@angular/common";
import * as i13 from "./ticket-calendar.component";
import * as i14 from "../../../services/service-ticket.service";
import * as i15 from "ngx-spinner";
var styles_TicketCalendarComponent = [i0.styles];
var RenderType_TicketCalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketCalendarComponent, data: {} });
export { RenderType_TicketCalendarComponent as RenderType_TicketCalendarComponent };
function View_TicketCalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Driver Ticket Calendar"]))], null, null); }
function View_TicketCalendarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Service Ticket Calendar"]))], null, null); }
function View_TicketCalendarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-fullCalendar", [], null, null, null, i2.View_FullCalendar_0, i2.RenderType_FullCalendar)), i1.ɵdid(1, 8634368, null, 0, i3.FullCalendar, [i1.ElementRef], { events: [0, "events"], options: [1, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; var currVal_1 = _co.options; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TicketCalendarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-daywise-tickets", [], null, [[null, "showCalander"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showCalander" === en)) {
        var pd_0 = (_co.showCalander($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DaywiseTicketsComponent_0, i4.RenderType_DaywiseTicketsComponent)), i1.ɵdid(1, 245760, null, 0, i5.DaywiseTicketsComponent, [i6.TicketViewService, i7.CookieService, i8.Router, i9.DbUsersService, i10.HubService, i11.LocalService], { selectedDate: [0, "selectedDate"] }, { showCalander: "showCalander" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedDate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TicketCalendarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "calendar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "data-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCalendarComponent_1)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCalendarComponent_2)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "container-fluid custom-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCalendarComponent_3)), i1.ɵdid(13, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCalendarComponent_4)), i1.ɵdid(15, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.calendarFor == "driverTMS"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.calendarFor != "driverTMS"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.selectedDate == null); _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.selectedDate != null); _ck(_v, 15, 0, currVal_3); }, null); }
export function View_TicketCalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-calendar", [], null, null, null, View_TicketCalendarComponent_0, RenderType_TicketCalendarComponent)), i1.ɵdid(1, 114688, null, 0, i13.TicketCalendarComponent, [i14.ServiceTicketService, i15.NgxSpinnerService, i11.LocalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketCalendarComponentNgFactory = i1.ɵccf("app-ticket-calendar", i13.TicketCalendarComponent, View_TicketCalendarComponent_Host_0, {}, {}, []);
export { TicketCalendarComponentNgFactory as TicketCalendarComponentNgFactory };
