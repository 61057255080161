import { environment } from '../../environments/environment';

export class Urls {
  public static APP_URL: string = environment.ticket;
  public static COOKIE_URL: string = environment.cookieURL;
  public static HUB_URL: string = environment.hub;
  public static HUB_WEB_URL: string = environment.hubWebUrl;
  public static AXLE_WEB_URL: string = environment.axleWebUrl;
  public static ALTILIUM_WEB_URL: string = environment.altiliumWebUrl;
  public static COOKIE_SUFFIX: string = environment.cookieSuffix;
}

