import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { sanitizeHtmlInputSelectDropdown } from '../dynamic-form-config.utils';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { Response } from 'src/app/models/api.dto';

@Component({
  selector: 'app-heirarchy-dropdown-element',
  templateUrl: './heirarchy-dropdown-element.component.html',
  styleUrls: ['./heirarchy-dropdown-element.component.css']
})

export class HeirarchyDropdownElementComponent implements OnInit {
  @Input() inputElementData: any;
  // formproject to know which project data forms to link and load
  @Input() formProject: any;


  @Output() outputElementData: EventEmitter<any> = new EventEmitter<any>();

  listOfActiveForms: any[];
  selectedFieldOptionValues: string[] = [];
  optionsForSelectedFields: any[] = [];
  dynamicFieldCurrentLabel = '';
  dynamicFieldCurrentValue = '';

  dataElement: any = {};

  constructor(private dynamicFormService: DynamicFormService) { }
  ngOnInit() {
    if (this.inputElementData) {
      this.dataElement = this.inputElementData;
      if (this.inputElementData.options) {
        this.optionsForSelectedFields = this.inputElementData.options.map(o => o.value);
      }
    }
    this.dynamicFormService.fetchAllActiveFormsFromProject(this.formProject)
    .subscribe((res: any) => {
      if (res.code !== 200) {
        return alert(res.message);
      } else {
        const forms = res.response;
        this.listOfActiveForms = forms.map((form) => {
          return ({
            label: `${form.name}`,
            value: form.formId,
            isActive: form.isActive,
          });
        });
      }
    });

  }
  configElementInputDropdownSubmitHandler(form: NgForm) {
    const { value } = form;
    if (value && Object.keys(value).length > 0 && this.optionsForSelectedFields && this.optionsForSelectedFields.length) {
      const options = this.optionsForSelectedFields.map((formId) => {
        return this.listOfActiveForms.find((op) => op.value === formId);
      });
      const element: any = {
        html: 'select',
        htmlType: 'dropdown',
        fieldOptions: ['required'],
        ...value,
        isConfigActive: true,
        options,
      };
      // !! Warning !!
      // Warning don't remove -->
      element.name = 'HIERARCHY_' + element.name;
      // <--
      this.outputElementData.emit({...sanitizeHtmlInputSelectDropdown(element), htmlTypeAlias: 'hierarchy', isConfigActive: true});
    }
  }
  addOption() {
    if (!this.dynamicFieldCurrentLabel || !this.dynamicFieldCurrentValue) {
      return;
    }
    const el = {
      label: this.dynamicFieldCurrentLabel,
      value: this.dynamicFieldCurrentValue,
      isSaved: true,
      isEditing: false,
    };
    this.optionsForSelectedFields.push(el);
  }
  deleteThisOption(i) {
    this.optionsForSelectedFields.splice(i, 1);
  }
  editThisOption(i) {
    this.optionsForSelectedFields[i].isEditing = true;
    this.optionsForSelectedFields[i].isSaved = false;
  }
  saveThisOption(i) {
    this.optionsForSelectedFields[i].isSaved = true;
    this.optionsForSelectedFields[i].isEditing = false;
  }
  labelChangeHandler(e, i) {
    const value = e.target.value;
    this.optionsForSelectedFields[i].label = value;
  }
  valueChangeHandler(e, i) {
    this.optionsForSelectedFields[i].value = e && e.target ? e.target.value : e.value;
  }
  activeFormChangeHandler({ value }) {
    this.dynamicFieldCurrentValue = value;
  }

}
