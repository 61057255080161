import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CarRegistrationService {
    constructor(http) {
        this.http = http;
    }
    getAllCars() {
        return this.http.get(Urls.HUB_URL + '/api/v1/vehicle/all', { headers: Headers.headers });
    }
}
CarRegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarRegistrationService_Factory() { return new CarRegistrationService(i0.ɵɵinject(i1.HttpClient)); }, token: CarRegistrationService, providedIn: "root" });
