/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-ticket-global.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/steps/steps.ngfactory";
import * as i3 from "primeng/components/steps/steps";
import * as i4 from "../form/form.component.ngfactory";
import * as i5 from "../form/form.component";
import * as i6 from "../form/form-service.service";
import * as i7 from "primeng/components/button/button";
import * as i8 from "../../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory";
import * as i9 from "primeng/components/progressspinner/progressspinner";
import * as i10 from "@angular/common";
import * as i11 from "../../../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i12 from "primeng/components/toast/toast";
import * as i13 from "primeng/components/common/messageservice";
import * as i14 from "primeng/components/common/shared";
import * as i15 from "./create-ticket-global.component";
import * as i16 from "ngx-cookie-service";
import * as i17 from "@angular/router";
import * as i18 from "../../services/tickets.service";
var styles_CreateTicketGlobalComponent = [i0.styles];
var RenderType_CreateTicketGlobalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateTicketGlobalComponent, data: {} });
export { RenderType_CreateTicketGlobalComponent as RenderType_CreateTicketGlobalComponent };
function View_CreateTicketGlobalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"], ["style", "padding: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "steps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = (_co.activeIndexChangeHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Steps_0, i2.RenderType_Steps)), i1.ɵdid(4, 49152, null, 0, i3.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"] }, { activeIndexChange: "activeIndexChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "renderedForm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-form-renderer", [], null, [[null, "formSubmitHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmitHandler" === en)) {
        var pd_0 = (_co.submitCurrentForm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormRendererComponent_0, i4.RenderType_FormRendererComponent)), i1.ɵdid(7, 638976, null, 0, i5.FormRendererComponent, [i6.FormServiceService], { formConfig: [0, "formConfig"] }, { formSubmitHandler: "formSubmitHandler" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "ui-button-secondary backButton"], ["label", "Back"], ["pButton", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeIndexChangeHandler((_co.activeIndex - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 4341760, null, 0, i7.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.currentlyActiveForm; _ck(_v, 7, 0, currVal_3); var currVal_5 = "Back"; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.activeIndex <= 0); _ck(_v, 8, 0, currVal_4); }); }
function View_CreateTicketGlobalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "padding-left: 40%; padding-top: 150px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p-progressSpinner", [], null, null, null, i8.View_ProgressSpinner_0, i8.RenderType_ProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i9.ProgressSpinner, [], null, null)], null, null); }
function View_CreateTicketGlobalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-check"]], null, null, null, null, null))], null, null); }
function View_CreateTicketGlobalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-info-circle"]], null, null, null, null, null))], null, null); }
function View_CreateTicketGlobalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.detail; _ck(_v, 1, 0, currVal_0); }); }
function View_CreateTicketGlobalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_CreateTicketGlobalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_8)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.data; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CreateTicketGlobalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "stack"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_4)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_5)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, [" \u00A0 ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_6)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_7)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.severity == "success"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_v.context.$implicit.severity == "info"); _ck(_v, 6, 0, currVal_1); var currVal_3 = _v.context.$implicit.detail; _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.data; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.summary; _ck(_v, 7, 0, currVal_2); }); }
export function View_CreateTicketGlobalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "heirarchy"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketGlobalComponent_2)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "p-toast", [], null, null, null, i11.View_Toast_0, i11.RenderType_Toast)), i1.ɵdid(6, 1294336, null, 1, i12.Toast, [i13.MessageService], null, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_CreateTicketGlobalComponent_3)), i1.ɵdid(9, 16384, [[1, 4]], 0, i14.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentlyActiveForm; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLoadComplete; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); var currVal_2 = "message"; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_CreateTicketGlobalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-ticket-global", [], null, null, null, View_CreateTicketGlobalComponent_0, RenderType_CreateTicketGlobalComponent)), i1.ɵdid(1, 114688, null, 0, i15.CreateTicketGlobalComponent, [i16.CookieService, i17.Router, i17.ActivatedRoute, i13.MessageService, i18.TicketsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateTicketGlobalComponentNgFactory = i1.ɵccf("app-create-ticket-global", i15.CreateTicketGlobalComponent, View_CreateTicketGlobalComponent_Host_0, {}, {}, []);
export { CreateTicketGlobalComponentNgFactory as CreateTicketGlobalComponentNgFactory };
