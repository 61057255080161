/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./issues-tat.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./current-tickets/current-tickets.component.ngfactory";
import * as i3 from "./current-tickets/current-tickets.component";
import * as i4 from "../../services/tat-issues.service";
import * as i5 from "ngx-cookie-service";
import * as i6 from "../../services/ticket-view.service";
import * as i7 from "./issues-tat.component";
var styles_IssuesTatComponent = [i0.styles];
var RenderType_IssuesTatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IssuesTatComponent, data: {} });
export { RenderType_IssuesTatComponent as RenderType_IssuesTatComponent };
export function View_IssuesTatComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "active-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-current-tickets", [], null, null, null, i2.View_CurrentTicketsComponent_0, i2.RenderType_CurrentTicketsComponent)), i1.ɵdid(3, 114688, null, 0, i3.CurrentTicketsComponent, [i4.TatIssuesService, i5.CookieService, i6.TicketViewService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_IssuesTatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-issues-tat", [], null, null, null, View_IssuesTatComponent_0, RenderType_IssuesTatComponent)), i1.ɵdid(1, 114688, null, 0, i7.IssuesTatComponent, [i4.TatIssuesService, i5.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IssuesTatComponentNgFactory = i1.ɵccf("app-issues-tat", i7.IssuesTatComponent, View_IssuesTatComponent_Host_0, {}, {}, []);
export { IssuesTatComponentNgFactory as IssuesTatComponentNgFactory };
