import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceTicketService {

  constructor(private http: HttpClient) { }
  //get service ticket config
  getAllServiceTicketConfig(): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/serviceTicket/config/all', { headers: Headers.headers });
  }

  //get service ticket config
  updateServiceTicketConfiguration(updatedData): Observable<{}> {
    return this.http.put<{}>(Urls.APP_URL + '/api/v1/serviceTicket/config/update', updatedData, { headers: Headers.headers });
  }

  //create new  service ticket config
  createServiceTicketConfiguration(updatedData): Observable<{}> {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/serviceTicket/config/create', updatedData, { headers: Headers.headers });
  }

  //delete service ticket config
  deleteServiceTicketConfiguration(id): Observable<{}> {
    return this.http.delete<{}>(Urls.APP_URL + '/api/v1/serviceTicket/config/delete/'+id, { headers: Headers.headers });
  }

  //get service ticket calander events
  getServiceTicketEvents(date): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/serviceTicket/config/events/'+date, { headers: Headers.headers });
  }

  //get service ticket calander events For Driver TMS
  getServiceTicketEventsForDriverTMS(date): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/serviceTicketForDriverTMS/config/events/'+date, { headers: Headers.headers });
  }
}

