<app-detail-ticket-view [ticketId]="ticketId" (backToTicket)="isDetailViewVisible = false" *ngIf="isDetailViewVisible">
</app-detail-ticket-view>
<div ><button pButton type="button" icon="pi pi-arrow-left" iconPos="left" label="Back" (click)="showCal()" *ngIf="!isDetailViewVisible"></button></div>

<div class="container" *ngIf="!isDetailViewVisible">
   
  <p-dataView #dv [value]="allTicketsBasicData" [paginator]="true" [rows]="limit" [totalRecords]="totalRecords"
    paginatorPosition="bottom" filterBy="brand" [lazy]="true" (onLazyLoad)="searchWithFilters($event)"
    [loading]="!isLoadComplete">
    <p-header>
      <div class="flex-container">
        <div>
          <p-dropdown [options]="activeState" [(ngModel)]="isActive" placeholder="Select Category"></p-dropdown>
        </div>
        <!-- <div *ngIf="currentUserSsoId === '119'">
          <p-multiSelect [options]="usersList" [(ngModel)]="selectedAssignedForAction"
            defaultLabel="Users with Action">
          </p-multiSelect>
        </div> -->
        <!-- <div *ngIf="currentUserSsoId === '119'">
          <p-multiSelect [options]="usersList" [(ngModel)]="selectedAssignedForView"
            defaultLabel="Users with View">
          </p-multiSelect>
        </div> -->
        <div>
          <input style="width:250px;" type="text" pInputText [(ngModel)]="search" id="searchInput" placeholder="Search..." />
        </div>
        <div>
          <button pButton type="button" label="Search" (click)="searchWithFilters()"></button>
        </div>
      </div>
    </p-header>
    <ng-template let-ticket pTemplate="listItem">
      <div class="ui-g-12" class="clickableDiv align_center green" [ngClass]="{red: ticket.currentStage=='Ticket Created',yellow:(ticket.currentStage=='Odometer Reading'||ticket.currentStage =='Car sent to workshop')}"   (click)="getDetailViewForTicket(ticket.ticketId)" >
        
        <div class="ui-g-2" style="padding-left: 10px; ">
          <b>{{ ticket.ticketId }}</b>
        </div>
        <div class="ui-g-3">
          <span class="ticketImportantDetails"><b>{{ ticket.category }}</b></span>
        </div>
        <div class="ui-g-2">
          <span class="ticketImportantDetails"><b>{{ ticket.currentStage }}</b></span>
        </div>
        <div class="ui-g-1">
          <b>{{ticket.hubId?hubList[ticket.hubId]['name']:''}}</b>
        </div>
        <div class="ui-g-2" style="text-align: center;">
          <b>{{ ticket.createdAt | amTimeAgo: timezone }}</b>
        </div>
        <div class="ui-g-2" style="text-align: center;">
          <span *ngFor="let user of ticket.assignedForActionAlias; let i = index">
            <span *ngIf="user.info != undefined">{{ user.info.name }}</span><br>
          </span>
        </div>

      </div>
    </ng-template>
  </p-dataView>
</div>
