import { Component, OnInit } from '@angular/core';
import { TicketsService } from 'src/app/services/tickets.service';
import { UserService } from '../../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { Response } from 'src/app/models/api.dto';
import { IncidentsService } from 'src/app/services/incidents.service';

import { SelectItem, ConfirmationService, MessageService } from 'primeng/api';
import { Urls } from 'src/app/models/api.constants';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  //Ticket to Be Updated
  ticket: {} = {};
  images: any[];
  userId;

  //Paid Amount
  displayPaid: Boolean = false;
  displayConfirm: Boolean = false;
  ticketId;
  currentStage;
  ticketData;
  paidAmount;

  tickets;
  ticketsActive;
  ticketsInactive;
  ticketsByCategoriesActive;
  ticketsByCategoriesInactive;
  incident;
  incidentStages;
  incidentCategories: SelectItem[];
  activeCategory;
  timezone;

  constructor(private ticketService: TicketsService,
    private incidentService: IncidentsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService: UserService,
    private cookieService: CookieService,
    private localeService: LocalService) {
    this.timezone = localeService.getTimeZone();
    this.activeCategory = 'All';
    this.getTicketsAndAccidents(this.activeCategory);
  }

  ngOnInit() {
  }

  getTicketsAndAccidents(activeCategory) {
    this.incidentService.getAllIncidents().subscribe((res: Response<[]>) => {
      this.incident = res.response;
      this.incidentCategories = [{ label: 'All', value: 'All' }];
      this.incidentStages = {};
      this.ticketsByCategoriesActive = { 'All': [] };
      this.ticketsByCategoriesInactive = { 'All': [] };
      this.ticketsActive = [];
      this.ticketsInactive = [];
      for (let item in this.incident) {
        this.incidentCategories.push({ label: this.incident[item].displayName, value: this.incident[item].category });
        this.ticketsByCategoriesActive[this.incident[item].category] = [];
        this.ticketsByCategoriesInactive[this.incident[item].category] = [];
        this.incidentStages[this.incident[item].category] = this.incident[item].stages;
      }
      console.log(this.incidentCategories);
      this.ticketService.getAllTickets().subscribe((res: Response<[]>) => {
        this.tickets = res.response;
        for (let item in this.tickets) {
          this.tickets[item]['nextStage'] = this.incidentStages[this.tickets[item].category];
          this.tickets[item]['photoDisplay'] = [];
          for (let photo in this.tickets[item]['data']['photoURL']) {
            this.tickets[item]['photoDisplay'].push(
              {
                source: this.tickets[item]['data']['photoURL'][photo],
                thumbnail: this.tickets[item]['data']['photoURL'][photo],
                title: this.tickets[item].category + " " + (parseInt(photo) + 1)
              });
          }
          if (this.tickets[item].isActive) {
            this.ticketsActive.push(this.tickets[item]);
            this.ticketsByCategoriesActive['All'].push(this.tickets[item]);
            this.ticketsByCategoriesActive[this.tickets[item].category].push(this.tickets[item]);
          }
          else {
            this.ticketsInactive.push(this.tickets[item]);
            this.ticketsByCategoriesInactive['All'].push(this.tickets[item]);
            this.ticketsByCategoriesInactive[this.tickets[item].category].push(this.tickets[item]);
          }
        }
        this.selectTicketsByCategory(activeCategory);

        const country = localStorage.getItem('country');
        const accessToken = this.cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);
        this.userService.isTokenValid(accessToken).then((res: Response<{ sso_id }>) => {
          this.userId = res.response.sso_id;
          console.log(this.userId);
          if (this.userId == 1590) {
            this.incidentCategories=[{label:'Missing Items',value:'Missing_Items'}];
            this.selectTicketsByCategory('Missing_Items');
          }
          else if (this.userId == 1581) {
            this.incidentCategories=[{label:'Challan',value:'Challan'},{label:'Accident',value:'Accident'}];
            this.selectTicketsByCategory('Challan');
          }
          else if (this.userId == 1592) {
            this.incidentCategories=[{label:'Accident',value:'Accident'},{label:'Not Working Properly',value:'Not_Working_Properly'}];
            this.selectTicketsByCategory('Accident');
          }
        });
      });
    });

  }

  showPaidDialog(ticketId, currentStage, ticketData) {
    this.ticketId = ticketId;
    this.currentStage = currentStage;
    this.ticketData = ticketData;
    this.displayPaid = true;
  }

  showConfirmDialog(ticketId, currentStage, ticketData) {
    this.ticketId = ticketId;
    this.currentStage = currentStage;
    this.ticketData = ticketData;
    this.confirmationService.confirm({
      message: 'Are you sure to continue?',
      accept: () => {
        this.submitStage();
      }
    });
  }

  submitStagePaid() {
    let currentSelectedCategory = this.activeCategory;
    this.ticket['data'] = this.ticketData.data;
    this.ticket['data']['paidAmount'] = this.paidAmount;
    this.ticket['_id'] = this.ticketId;
    this.ticket['currentStage'] = this.currentStage;
    this.ticketService.updateTicket(this.ticket).subscribe((res: Response<[]>) => {
      if (res.errorCode == 200) {
        this.ticketService.getOneTickets(this.ticketId).subscribe((res: Response<[]>) => {
          if (res.errorCode == 200) {
            if (this.ticketData.nextStage[res.response['currentStage']] == "Close Ticket") {
              this.ticketService.closeTicket(this.ticketId).subscribe((res: Response<[]>) => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ticket Successfully Closed' });
                this.getTicketsAndAccidents(currentSelectedCategory);
              });
            }
            else {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ticket Successfully Updated' });
              this.getTicketsAndAccidents(currentSelectedCategory);
            }
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Some Error Occured' });
          }
        });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Some Error Occured' });
      }
      this.displayPaid = false;
      this.paidAmount = null;
      this.ticket = {};
    });
  }

  submitStage() {
    let currentSelectedCategory = this.activeCategory;
    this.ticket['_id'] = this.ticketId;
    this.ticket['currentStage'] = this.currentStage;
    this.ticketService.updateTicket(this.ticket).subscribe((res: Response<[]>) => {
      if (res.errorCode == 200) {
        this.ticketService.getOneTickets(this.ticketId).subscribe((res: Response<[]>) => {
          if (res.errorCode == 200) {
            if (this.ticketData.nextStage[res.response['currentStage']] == "Close Ticket") {
              this.ticketService.closeTicket(this.ticketId).subscribe((res: Response<[]>) => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ticket Successfully Closed' });
                this.getTicketsAndAccidents(currentSelectedCategory);
              });
            }
            else {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ticket Successfully Updated' });
              this.getTicketsAndAccidents(currentSelectedCategory);
            }
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Some Error Occured' });
          }
        });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Some Error Occured' });
      }
      this.displayPaid = false;
    });
  }

  selectTicketsByCategory(category) {
    this.activeCategory = category;
    this.ticketsActive = this.ticketsByCategoriesActive[category];
    this.ticketsInactive = this.ticketsByCategoriesInactive[category];
  }

  getStyle(ticket) {
    return (ticket['priority'] == 'critical' ? 'critical' : (ticket['priority'] == 'medium' ? 'medium' : 'low'))
  }

  closeTicket(ticketId) {
    let currentSelectedCategory = this.activeCategory;
    this.ticketService.closeTicket(ticketId).subscribe((res: Response<[]>) => {
      this.getTicketsAndAccidents(currentSelectedCategory);
    });
  }

  stagesCondition(nextStage, currentStage, stage) {
    var visibleStage = nextStage[currentStage];
    if (visibleStage) {
      return visibleStage.includes(stage);
    }
    else {
      return false;
    }
  }
}
