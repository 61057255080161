<div class="serviceticket-container">
    <div class="tabs-bar">
        <div class="tabs" [ngClass]="{'selected-tab': isTabSelected('CALENDAR')===true}" (click)="changeTab('CALENDAR')" *ngIf="checkPermission('SERVICE_TICKET_CALANDER')">
            <span>Calendar</span>
        </div>
        <div class="tabs" [ngClass]="{'selected-tab': isTabSelected('TICKET_SETTINGS')===true}" (click)="changeTab('TICKET_SETTINGS')" *ngIf="checkPermission('SERVICE_TICKET_CONFIG')">
            <span>Edit Configuration</span>
        </div>
       
        <!-- <div class="tabs" [ngClass]="{'selected-tab': isTabSelected('MAINTENANCE')===true}"
            (click)="changeTab('MAINTENANCE')">
            <span>Maintenance</span>
        </div> -->
    </div>
    <div class="content">
        <app-ticket-calendar *ngIf="isTabSelected('CALENDAR')"></app-ticket-calendar>
        <app-ticket-settings *ngIf="isTabSelected('TICKET_SETTINGS')"></app-ticket-settings>
                <!-- <app-maintenance *ngIf="isTabSelected('EDIT_CONFIG')"></app-maintenance> -->
    </div>
</div>