import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';

@Injectable({
  providedIn: 'root'
})
export class DriversService {


  constructor(private http: HttpClient) { }

  getAllDrivers(): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL + '/api/v1/drivers/all', { headers: Headers.headers });
  }
}
