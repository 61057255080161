import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
export class StageViewService {
    constructor(http, cookieService) {
        this.http = http;
        this.cookieService = cookieService;
    }
    getStageDataFromStageId(ticketStageId) {
        return this.http.get(Urls.APP_URL + '/api/v1/linked/stages?stageId=' + ticketStageId, { headers: Headers.headers });
    }
    getAllStages() {
        return this.http.get(Urls.APP_URL + '/api/v1/linked/stages/all', { headers: Headers.headers });
    }
    getVehicleSeviceRegionList() {
        return this.http.get(Urls.APP_URL + '/api/v1/vehicle/service/region/' + this.cookieService.get('selectedZone'), { headers: Headers.headers });
    }
}
StageViewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StageViewService_Factory() { return new StageViewService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: StageViewService, providedIn: "root" });
