import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent, FormRendererComponent } from './form.component';
import { FormServiceService } from './form-service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from './dynamic-forms/dynamic-forms.module';


@NgModule({
  declarations: [FormComponent, FormRendererComponent],
  imports: [
    CommonModule,
    FormsModule,
    DynamicFormModule,
    ReactiveFormsModule
  ],
  providers: [FormServiceService],
  exports: [FormRendererComponent],
})
export class FormModule { }
