import { FormServiceService } from './form-service.service';
import { DynamicFormComponent } from './dynamic-forms/container/dynamic-form.component';
import { Component, ViewChild, AfterViewInit, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FieldConfig } from './dynamic-forms/model/field-config.interface';
import { converter } from './form.utils';

@Component({
  selector: 'app-form-renderer',
  template: `
    <ng-container *ngIf="isLoadComplete">
      <form-component
        [formElementConfigArray]="formElementConfigArray"
        (formSubmitHandler)="formSubmit($event)"
        [formConfig] = "formConfig"
        [areWeUpForUpdate]="areWeUpForUpdate"
        (updateElementHandler)="updateFieldElementHandler($event)"
        (deleteElementHandler)="deleteFieldElementHandler($event)"
        (addElementHandler)="addFieldElementHandler($event)"
        (dropElementHandler)="dropFieldElementHandler($event)"

      >
      </form-component>
    </ng-container>
    <p *ngIf="!isLoadComplete" style="margin-bottom: 40px">{{loadMessage}}</p>
  `,
  styleUrls: [],
})
export class FormRendererComponent implements OnInit, OnChanges {
  @Input()
  areWeUpForUpdate = false;
  @Output()
  addElementHandler: EventEmitter<any> = new EventEmitter<any>();
  addFieldElementHandler(e) {
    this.addElementHandler.emit();
  }
  @Output()
  updateElementHandler: EventEmitter<any> = new EventEmitter<any>();
  updateFieldElementHandler(fieldConfig) {
    this.updateElementHandler.emit(fieldConfig);
  }
  @Output()
  deleteElementHandler: EventEmitter<any> = new EventEmitter<any>();
  deleteFieldElementHandler(fieldConfig) {
    this.deleteElementHandler.emit(fieldConfig);
  }
  @Output()
  dropElementHandler: EventEmitter<any> = new EventEmitter<any>();
  dropFieldElementHandler(fieldConfig) {
    this.dropElementHandler.emit(fieldConfig);
  }

  constructor(private _formService: FormServiceService) { }

  isLoadComplete = false;
  loadMessage = 'Loading... Please Wait!';
  // Inpput output

  @Input() tokenAuth: string; // in case no intercepter is being used then this can provide authToken 'Authorization' : 'Bearer <tokenAuth>'
  @Input() formConfig: any;
  formElements: FieldConfig[];
  formElementConfigArray: FieldConfig[];
  @Output() formSubmitHandler = new EventEmitter<any>();

  formSubmit(form) {
    this.formSubmitHandler.emit(form);
  }
  filterFormElementsBasedOnCategory() {
    //  <!-- NOT REQUIRED HOC SHOULD HANDLE THIS --!>
    // // will ensure if category is available then depending on category element is seleclected.
    // if (this.formConfig.category) {
    //   // iterating over elements [] to filter element acc. to category
    //   return this.formConfig.elements.filter((el) => {
    //       // if element is button allow
    //       if (el.html === 'button' && el.name === 'submit') {
    //         return true;
    //       }
    //       // if category is heiarchy element then allow onl select fileds
    //       // else if endPoint-stage include eleemnt irrecpective
    //       // in all other case ignore
    //       if (this.formConfig.category === 'heirarchyElement' && el.html === 'select') {
    //         return true;
    //       } else if (this.formConfig.category === 'endPointElement' || this.formConfig.category === 'stageElement' ) {
    //         return true;
    //       }
    //       return false;
    //   });
    // }
    return this.formConfig.elements;
  }
  configureFormComponent() {
    this.formElements = this.filterFormElementsBasedOnCategory();
    this.fetchData(this.formElements);
  }
  ngOnInit() {
    this.configureFormComponent();
  }
  ngOnChanges() {
    this.configureFormComponent();
  }

  async fetchData(elements) {
    for (let el of elements) {
      const { html, url, optionFieldLabel = 'label', optionFieldValue = 'value' } = el;
      if (html === 'select' && url && typeof url === 'string') {
        try {
          const data: any = await this._formService.requestThisUrl(el.url, this.tokenAuth);
          if (
            'errorCode' in data && data.errorCode === 200 ||
            'statusCode' in data && data.statusCode === 200 ||
            'code' in data && data.code === 200
          ) {
            const response: any[] = data.response;
            // el.options = response.map(({ [optionFieldLabel]: label, [optionFieldValue]: value }) => ({ label, value }));
            el.options = response.map((option) => {
              const value = optionFieldValue.split(',').map((val) => val && typeof val === 'string' && val.trim().length > 0 && option[val.trim()]).filter(Boolean).join(',');
              const label = optionFieldValue.split(',').map((val) => val && typeof val === 'string' && val.trim().length > 0 && option[val.trim()]).filter(Boolean).join(',');
              return {
                label,
                value,
              };
            });
            console.log(el.options);
          }
          // remove -->
            // const response: any[] = data;
            // el.options = response.map(({ [optionFieldLabel]: label, [optionFieldValue]: value }) => ({ label, value }));
          // <--
        } catch (e) {
          console.log(e);
        }
      }
    }
    this.isLoadComplete = true;
    this.formElementConfigArray = elements;
  }
}

@Component({
  selector: 'form-component',
  template: `
    <ng-container>
    <dynamic-form
    [config]="formElementsConfig"
    #form="dynamicForm"
    (submit)="submit($event)"
    [areWeUpForUpdate]="areWeUpForUpdate"
    (updateElementHandler)="updateFieldElementHandler($event)"
    (deleteElementHandler)="deleteFieldElementHandler($event)"
    (dropElementHandler)="dropFieldElementHandler($event)"
    >
    </dynamic-form>
    <ng-container *ngIf="areWeUpForUpdate">
      <button (click)="addFieldElementHandler($event)">Add elements</button>
    </ng-container>
    </ng-container>
  `,
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {

  @Input()
  areWeUpForUpdate = false;
  @Output()
  addElementHandler: EventEmitter<any> = new EventEmitter<any>();
  addFieldElementHandler(e) {
    this.addElementHandler.emit();
  }
  @Output()
  updateElementHandler: EventEmitter<any> = new EventEmitter<any>();
  updateFieldElementHandler(fieldConfig) {
    this.updateElementHandler.emit(fieldConfig);
  }
  @Output()
  deleteElementHandler: EventEmitter<any> = new EventEmitter<any>();
  deleteFieldElementHandler(fieldConfig) {
    this.deleteElementHandler.emit(fieldConfig);
  }
  @Output()
  dropElementHandler: EventEmitter<any> = new EventEmitter<any>();
  dropFieldElementHandler(fieldConfig) {
    this.dropElementHandler.emit(fieldConfig);
  }
  @ViewChild('form', { read: DynamicFormComponent, static: true }) form: DynamicFormComponent;
  @Input() formElementConfigArray: FieldConfig[];
  @Input() formConfig: any;
  @Output() formSubmitHandler = new EventEmitter<any>();
  formElementsConfig: FieldConfig[];
  flattenedFormFields = {};


  flatThisFormFields() {
    const flattenedFormFields: any =  {
      formId: this.formConfig.formId,
    };
    for (let elConfig of this.formElementsConfig) {
      const { name, type, htmlType, elementType = 'endPoint', options } = elConfig;
      const fieldValue: any = {
        name,
        type,
        htmlType,
        elementType,
      };
      if ('value' in elConfig && elConfig.value) {
        fieldValue.value = elConfig.value;
      }
      if (options) {
        fieldValue.options = options;
      }
      flattenedFormFields[name] = fieldValue;

    }
    this.flattenedFormFields = flattenedFormFields;
  }
  ngOnInit() {

    this.formElementsConfig = converter(this.formElementConfigArray);
    this.flatThisFormFields();
  }
  ngAfterViewInit() {
    let previousValid = this.form.valid;
    this.form.changes.subscribe(() => {
      // previousValid = !this.form.valid;
      // this.form.setDisabled('submit', this.form.valid);
      if (this.form.valid !== previousValid) {
        previousValid = this.form.valid;
        this.form.setDisabled('submit', !previousValid);
      }
    });


    this.formElementsConfig.forEach((config) => {
      if ('value' in config) {
        this.form.setValue(config.name, config.value);
      }
      if (config.validations && config.validations.required) {
        // if filed is required and value is already avialbel dont set disbaled bcz of this filed
        // its value is already there
        // set disabled true for fields which are requried and has a value
        if (!config.value) {
          this.form.setDisabled('submit', true);

        }

      }
    });
  }
  submit(values) {

    const formData = new FormData();
    for (let key in this.flattenedFormFields) {
      if ( values[key] ) {
        if ( key.toLowerCase().includes('files') && values[key].length > 1) {
          const ArrayNew: any = Array.from(values[key]);
          ArrayNew.forEach((element) => {
            formData.append(key, element);
          });
        } else {
          formData.append(key, values[key]);
        }
      } else if (this.flattenedFormFields[key].value){
        values[key] = this.flattenedFormFields[key].value;
        formData.append(key, values[key]);
      } // did this to pick values of any disabled fields may be requried :|
    }
    this.formSubmitHandler.emit({
      values,
      formData,
      fields: this.flattenedFormFields,
    });
  }
}


