import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { Response } from 'src/app/models/api.dto';
import { DbUsersService } from 'src/app/services/db-users.service';
import { StageViewService } from 'src/app/services/stage-view.service';

@Component({
  selector: 'app-dynamic-form-elements',
  templateUrl: './dynamic-form-elements.component.html',
  styleUrls: ['./dynamic-form-elements.component.css']
})
export class DynamicFormElementsComponent implements OnInit, OnChanges {
  @Output() restoreState = new EventEmitter<any>();
  @Input() dynamicFormBasicConfiguration: any = null;
  @Input() isUpdateFormActive = false;
  @Input() usersList: SelectItem[];
  selectedFormElement: String = 'input';
  addElementsDialog: Boolean;

  formProject;
  formCategory;
  formName;
  sla;
  team
  formElements = [];
  isFormVisible = false;
  // usersList: SelectItem[];
  usersListView: SelectItem[];
  selectedUsersToMapTickets: any[] = [];
  selectedViewUsersToMapTickets: any[] = [];
  selectedEditUsersToMapTickets: any[] = [];

  activeAccordianIndex;
  formConfig: any;
  selectedStageId:SelectItem[];
  stagesList:SelectItem[];
  selectedStage;
  constructor(private dynamicFormService: DynamicFormService, private dbUsers: DbUsersService, private stageViewService: StageViewService) {}
  ngOnChanges() {
    if (this.dynamicFormBasicConfiguration) {
      this.formProject = this.dynamicFormBasicConfiguration.formProject || this.dynamicFormBasicConfiguration.project;
      this.formCategory = this.dynamicFormBasicConfiguration.formCategory || this.dynamicFormBasicConfiguration.category;
      this.formName = this.dynamicFormBasicConfiguration.formName || this.dynamicFormBasicConfiguration.name;
      this.sla = this.dynamicFormBasicConfiguration.sla || 7;
      this.team=this.dynamicFormBasicConfiguration.team
      this.selectedStageId = this.dynamicFormBasicConfiguration.stageId;
      if (this.dynamicFormBasicConfiguration.elements) {
        this.formElements = this.dynamicFormBasicConfiguration.elements
          .filter((e) => {
            if (e && e.html === 'button') {
              return false;
            }
            return true;
          })
          .map((e) => ({ ...e, isConfigActive: true }));
      }
      if (this.dynamicFormBasicConfiguration.usersToView) {
        this.selectedViewUsersToMapTickets = this.dynamicFormBasicConfiguration.usersToView;
      }
      if (this.dynamicFormBasicConfiguration.usersToAction) {
        this.selectedUsersToMapTickets = this.dynamicFormBasicConfiguration.usersToAction;
      }
      if (this.dynamicFormBasicConfiguration.usersToEdit) {
        this.selectedEditUsersToMapTickets = this.dynamicFormBasicConfiguration.usersToEdit;
      }
    }
  }
  ngOnInit() {
    if (this.dynamicFormBasicConfiguration) {
      this.formProject = this.dynamicFormBasicConfiguration.formProject || this.dynamicFormBasicConfiguration.project;
      this.formCategory = this.dynamicFormBasicConfiguration.formCategory || this.dynamicFormBasicConfiguration.category;
      this.formName = this.dynamicFormBasicConfiguration.formName || this.dynamicFormBasicConfiguration.name;
      this.sla = this.dynamicFormBasicConfiguration.sla || 7;
      this.team=this.dynamicFormBasicConfiguration.team
      this.selectedStageId = this.dynamicFormBasicConfiguration.stageId;
      if (this.dynamicFormBasicConfiguration.elements) {
        this.formElements = this.dynamicFormBasicConfiguration.elements.filter((e) => {
          if (e && e.html === 'button') {
            return false;
          }
          return true;
        }).map((e) => ({ ...e, isConfigActive: true }));
      }
      if (this.dynamicFormBasicConfiguration.usersToView) {
        this.selectedViewUsersToMapTickets = this.dynamicFormBasicConfiguration.usersToView;
      }
      if (this.dynamicFormBasicConfiguration.usersToAction) {
        this.selectedUsersToMapTickets = this.dynamicFormBasicConfiguration.usersToAction;
      }
      if (this.dynamicFormBasicConfiguration.usersToEdit) {
        this.selectedEditUsersToMapTickets = this.dynamicFormBasicConfiguration.usersToEdit;
      }
    }
    this.stageViewService.getAllStages().subscribe((res:Response<[]>)=>{
      let stages = res.response;
      this.stagesList = [];
      stages.forEach(stage => {
        this.stagesList.push({ label: stage['stageConfigurationName'], value: stage['stageId'] })
      });
    })
    if (this.formCategory == "hierarchyElement") {
      this.selectedFormElement = 'hierarchySelectDropdown';
      if (!this.isUpdateFormActive) {
        this.addSelectedElementToForm();
      }
      this.selectedFormElement = null;
    }
    this.activeAccordianIndex = 0;
    this.forceReRender();
  }

  addSelectedElementToForm() {
    if (this.selectedFormElement == 'input') {
      this.formElements.push({ html: 'input', isConfigActive: false });
    }
    else if (this.selectedFormElement == 'inputGroupCheckbox') {
      this.formElements.push({ html: 'inputGroup', htmlType: 'checkbox', isConfigActive: false });
    }
    else if (this.selectedFormElement == 'inputGroupRadio') {
      this.formElements.push({ html: 'inputGroup', htmlType: 'radio', isConfigActive: false });
    }
    else if (this.selectedFormElement == 'selectDropdown') {
      this.formElements.push({ html: 'select', htmlType: 'dropdown', isConfigActive: false });
    }
    else if (this.selectedFormElement == 'selectMultiselect') {
      this.formElements.push({ html: 'select', htmlType: 'multiselect', isConfigActive: false });
    }
    else if (this.selectedFormElement == 'inputFile') {
      this.formElements.push({ html: 'inputFile', isConfigActive: false, name: 'files',  });
    }
    else if (this.selectedFormElement == 'hierarchySelectDropdown') {
      this.formElements.push({ html: 'select', htmlType: 'hierarchy-dropdown', isConfigActive: false });
    }
    else if (this.selectedFormElement == 'dateTime') {
      this.formElements.push({ html: 'dateTime', isConfigActive: false });
    }
    this.addElementsDialog = false;
    this.activeAccordianIndex = (this.formElements.length - 1);
    this.forceReRender();
  }

  insertElementToForm(elementSubmissionData, elementPosition) {
    for (let key in elementSubmissionData) {
      if (key != 'html') {
        if (!(key == 'htmlType' && (elementSubmissionData[key] == 'checkbox' || elementSubmissionData[key] == 'radio'
          || elementSubmissionData[key] == 'dropdown' || elementSubmissionData[key] == 'multiselect'))) {
          this.formElements[elementPosition][key] = elementSubmissionData[key];
        }
      }
    }
    this.forceReRender();
  }
  deleteFormHandler() {
    if (this.isUpdateFormActive) {
      return this.dynamicFormService.deactivateCurrentForm(this.dynamicFormBasicConfiguration.formId)
        .subscribe(({ code, message }: any) => {
          if (code !== 200) {
            return alert(message);
          } else {
            alert(message);
            return this.restoreState.emit();
          }

        });
    } else {
      const sanitizedFormElements = this.fetchValidatedElements();

      const finalizedForm: any = {
        project: this.formProject,
        name: this.formName,
        category: this.formCategory,
        elements: sanitizedFormElements,
        sla: this.sla.toString(),
        team:this.team,
        stage: this.selectedStageId,
        stageId: this.selectedStageId,

        usersToView: this.selectedViewUsersToMapTickets,
        usersToAction: this.selectedUsersToMapTickets,
        usersToEdit: this.selectedEditUsersToMapTickets,
        isActive: false,
      };
      if (this.formCategory !== "hierarchyElement") {
        if (!finalizedForm.sla || !finalizedForm.stageId || !finalizedForm.name) {
          return;
         }
      }
      console.log(finalizedForm);

      if (finalizedForm.elements) {
        finalizedForm.elements = finalizedForm.elements.map((e) => {
          if (e.html === 'select') {
            if ('url' in e && e.url) {
              const enew = {
                ...e,
              };
              delete enew.options;
              return enew;
            }
          }
          return e;
        });
      }


      return this.dynamicFormService.saveDynamicForm(finalizedForm).subscribe(({ code, message }: any) => {
        if (code !== 200) {
          return alert(message);
        } else {
          alert(message);
          return this.restoreState.emit();
        }

      });
      return this.restoreState.emit();
    }
  }

  saveFormToDB() {

    const sanitizedFormElements = this.fetchValidatedElements();

    const finalizedForm: any = {
      project: this.formProject,
      name: this.formName,
      category: this.formCategory,
      elements: sanitizedFormElements,
      usersToView: this.selectedViewUsersToMapTickets,
      usersToAction: this.selectedUsersToMapTickets,
      usersToEdit: this.selectedEditUsersToMapTickets,
      isActive: true,
      team:this.team,
      sla: this.sla.toString(),
      stage: this.selectedStageId,
      stageId: this.selectedStageId,

    };

  if (this.formCategory !== "hierarchyElement") {
      if (!finalizedForm.sla || !finalizedForm.stageId || !finalizedForm.name) {
        return;
       }
    } 

    // elements has a select dropdown with url prop then populated options from
    // dyanmic url must not be sent over on network

    if (finalizedForm.elements) {
      finalizedForm.elements = finalizedForm.elements.map((e) => {
        if (e.html === 'select') {
          if ('url' in e && e.url) {
            const enew = {
              ...e,
            };
            delete enew.options;
            return enew;
          }
        }
        return e;
      });
    }




    if (!this.isUpdateFormActive) { 
      return this.dynamicFormService.saveDynamicForm(finalizedForm).subscribe(({ code, message }: any) => {
        if (code !== 200) {
          return alert(message);
        } else {
          alert(message);
          return this.restoreState.emit();
        }

      });
    } else {
      if (this.dynamicFormBasicConfiguration.formId) {
        finalizedForm.formId = this.dynamicFormBasicConfiguration.formId;
      }
      if (this.dynamicFormBasicConfiguration.project === finalizedForm.project) {
        delete finalizedForm.project;
      }
      if (this.dynamicFormBasicConfiguration.name === finalizedForm.name) {
        delete finalizedForm.name;
      }
      return this.dynamicFormService.updateDynamicForm(finalizedForm).subscribe(({ code, message }: any) => {
        if (code !== 200) {
          return alert(message);
        } else {
          alert(message);
          return this.restoreState.emit();
        }

      });
    }


  }

  accordionTabUp(elementPosition) {
    if (elementPosition != 0) {
      let tempFormElement = this.formElements[elementPosition];
      this.formElements[elementPosition] = this.formElements[elementPosition - 1];
      this.formElements[elementPosition - 1] = tempFormElement;
      this.forceReRender();
    }
  }

  accordionTabDown(elementPosition) {
    let accordionLength = this.formElements.length;
    if (elementPosition != accordionLength - 1) {
      let tempFormElement = this.formElements[elementPosition];
      this.formElements[elementPosition] = this.formElements[elementPosition + 1];
      this.formElements[elementPosition + 1] = tempFormElement;
      this.forceReRender();
    }
  }

  deleteAccordionTab(elementPosition) {
    this.formElements.splice(elementPosition, 1);
    this.forceReRender();
  }

  forceReRender() {
    this.isFormVisible = false;
    let sanitizedFormElements = this.fetchValidatedElements();

    const forms = {
      name: this.formName,
      project: this.formProject,
      elements: sanitizedFormElements,
      usersToAction: this.usersList,
      usersToView: this.usersListView,
      category: this.formCategory,
    };
    this.formConfig = null;
    setTimeout(() => {

      this.formConfig = forms;
      this.isFormVisible = true;

    }, 0);
  }

  fetchValidatedElements() {
    let sanitizedFormElements = [];
    for (let element in this.formElements) {
      if (this.formElements[element]['isConfigActive'] == true) {
        sanitizedFormElements.push(this.formElements[element]);
      }
    }
    return sanitizedFormElements;
  }
}
