import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TatIssuesService } from 'src/app/services/tat-issues.service';
import { TicketViewService } from 'src/app/services/ticket-view.service';

@Component({
  selector: 'app-current-tickets',
  templateUrl: './current-tickets.component.html',
  styleUrls: ['./current-tickets.component.css']
})
export class CurrentTicketsComponent implements OnInit {

  showCsvText = true;
  showCsvLoader = false;
  showCsvSuccess = false;
  showCsvFailed = false;
  loading:boolean= false;
  data = [];
  issues = [];
  selectedIssues;
  hubs = [];
  selectedHubs;
  workshops = [];
  selectedWorkshops;
  categories = [
    {
      value: "Unplanned Non Incident Repair",
      label: "Unplanned Non Incident Repair"
    },
    {
      value: "Unplanned Incident Repair",
      label: "Unplanned Incident Repair"
    }
  ];
  selectedCategories;
  colours = [
    {
      value: "red",
      label: "Red",
    },
    {
      value: "yellow",
      label: "Yellow",
    },
    {
      value: "orange",
      label: "Orange",
    }
  ];
  selectedColours;
  hideFlag: boolean = false;
  expandedIndex: number | null = null;
  subData = [];
  constructor(private tatIssuesService: TatIssuesService, private _cookieService: CookieService, private ticketViewService: TicketViewService) { }

  ngOnInit() {
    this.loading =  true;
    this.getIssues();
    this.getWorkshops();
    this.getHubs();
    this.getData();
  }

  async getIssues() {
    this.issues = [];
    try {
      const res = await this.tatIssuesService.getIssues().toPromise();
      this.issues = res.response.map(item => ({
        value: item.issueId,
        label: item.issueName
      }));
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  }

  async getHubs() {
    this.hubs = [];
    try {
      const ssoid = localStorage.getItem('ssoId')
      const zoneid = this._cookieService.get('selectedZone');
      const res: any = await this.tatIssuesService.getHubs(ssoid, zoneid).toPromise();
      this.hubs = Object.entries(res.response).map(([key, value]) => ({
        label: value,
        value: key
      }));
    } catch (error) {
      console.error('Error fetching hubs:', error);
    }
  }

  async getWorkshops() {
    this.workshops = [];
    try {
      const res = await this.tatIssuesService.getMaintenanceData().toPromise();
      this.workshops = res.response.workShopList.map(item => ({
        value: item.id ? item.id.toString() : '',
        label: item.workshopName
      }));
    } catch (error) {
      console.error('Error fetching workshops:', error);
    }
  }

  async getData() {
    this.data = [];
    this.subData = [];
    this.expandedIndex = null;
    try {
      let color: any = [];
      if (this.selectedColours && this.selectedColours.length > 0) {
        color.push(this.selectedColours)
      }
      let obj = {
        "hubIds": this.selectedHubs && this.selectedHubs.length > 0 ? this.selectedHubs : [],
        "colours": color ? color : [],
        "workshopIds": this.selectedWorkshops && this.selectedWorkshops.length > 0 ? this.selectedWorkshops : [],
        "issues": this.selectedIssues && this.selectedIssues.length > 0 ? this.selectedIssues : [],
        "category": this.selectedCategories && this.selectedCategories.length > 0 ? this.selectedCategories : []
      };
      const res: any = await this.tatIssuesService.getAllData(obj).toPromise();
      const colorMapping = {
        "1": "red",
        "2": "yellow",
        "3": "orange",
      };
      this.data = res.response.map(item => {
        const newItem = {
          title: item._id.issueName,
          issueId: item._id.issueId,
          tickets: item.total.toString(),
          color: colorMapping[item._id.colour],
          onetothree: 0,
          fourtoseven: 0,
          seventoten: 0,
          aboveten: 0
        };
        item.categories.forEach(category => {
          if (category.ageCategory === '1-3 days') {
            newItem.onetothree = parseFloat(category.percentage.toFixed(2));
          } else if (category.ageCategory === '4-7 days') {
            newItem.fourtoseven = parseFloat(category.percentage.toFixed(2));
          } else if (category.ageCategory === '7-10 days') {
            newItem.seventoten = parseFloat(category.percentage.toFixed(2));
          } else if (category.ageCategory === '> 10 days') {
            newItem.aboveten = parseFloat(category.percentage.toFixed(2));
          }
        });
        return newItem;
      });
      this.loading =  false;

    } catch (error) {
      this.loading =  false;
      console.error('Error fetching data:', error);
    }
  }

  async getSubIssues(data, index) {
    this.loading =  true;
    this.subData = [];
    let obj = {
      "hubIds": this.selectedHubs && this.selectedHubs.length > 0 ? this.selectedHubs : [],
      "workshopIds": this.selectedWorkshops && this.selectedWorkshops.length > 0 ? this.selectedWorkshops : [],
      "category": this.selectedCategories && this.selectedCategories.length > 0 ? this.selectedCategories : []
    };
    this.expandedIndex = this.expandedIndex === index ? null : index;
    const res: any = await this.tatIssuesService.getSubIssues(data.issueId, obj).toPromise();
    const colorMapping = {
      "1": "red",
      "2": "yellow",
      "3": "orange",
    };

    this.subData = res.response.map(item => {
      const newItem = {
        title: item._id.subIssueName,
        color: colorMapping[item._id.colour],
        days: item._id.TAT,
        tickets: item.total,
        onetothree: 0,
        fourtoseven: 0,
        seventoten: 0,
        aboveten: 0
      };
      item.categories.forEach(category => {
        if (category.ageCategory === '1-3 days') {
          newItem.onetothree = parseFloat(category.percentage.toFixed(2));
        } else if (category.ageCategory === '4-7 days') {
          newItem.fourtoseven = parseFloat(category.percentage.toFixed(2));
        } else if (category.ageCategory === '7-10 days') {
          newItem.seventoten = parseFloat(category.percentage.toFixed(2));
        } else if (category.ageCategory === '> 10 days') {
          newItem.aboveten = parseFloat(category.percentage.toFixed(2));
        }
      });
      this.loading =  false;
      return newItem;
    });
  }
  getHoursAndDays(days) {
    const [integerPart, fractionalPart = '0'] = days.toString().split('.');
    if (parseInt(integerPart) === 0) {
      return fractionalPart == 1 ? fractionalPart + ' hour' : fractionalPart + ' hours';
    }
    else if (parseInt(integerPart) > 0 && parseInt(fractionalPart) === 0) {
      return integerPart == 1 ? integerPart + ' day' : integerPart + ' days';
    }
    else {
      return integerPart == 1 ? integerPart + ' day ' + fractionalPart + ' hour' : integerPart + ' days ' + fractionalPart + ' hours';
    }
  }
  closeExpanded(index) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }
  sendAsCSV() {
    this.showCsvLoader = true;
    this.showCsvText = false;
    const query =
    {
      "isActive": true,
      "role": [],
      "filters": {},
      "projection": "min",
      "skip": 0,
      "limit": 20,
      "sortingOrder": -1
    }
    this.ticketViewService.postCsvTicketsForEmail(query).subscribe(res => {
      if (res['code'] == 200) {
        this.showCsvLoader = false
        this.showCsvSuccess = true
        setTimeout(() => {
          this.showCsvText = true;
          this.showCsvFailed = false;
          this.showCsvSuccess = false;
        }, 5000);
      }
      else {
        this.showCsvLoader = false
        this.showCsvFailed = true
        setTimeout(() => {
          this.showCsvText = true;
          this.showCsvFailed = false;
          this.showCsvSuccess = false;
        }, 5000);
      }
    })
  }

  changeStyle(item) {
    this.hideFlag = true
    let styles;
    if (item) {
      styles = {
        'color': item
      };
    } else {
      styles = {
        'display': 'none'
      };
    }
    return styles;
  }
}
