<div class="container" *ngIf="selectFormConfiguration">
  <div class="form">
    <p-card header="New Form" subheader="Generate new form" styleClass="ui-card-shadow">
      <div class="card-content">
        <p-dropdown [options]="formProject" [style]="{'width':'100%'}" [(ngModel)]="selectedNewFormProject"
          placeholder="Select Project"></p-dropdown>
      </div>
      <div class="card-content">
        <p-dropdown [options]="formCategory" [style]="{'width':'100%'}" [(ngModel)]="selectedNewFormCategory"
          placeholder="Select Category">
        </p-dropdown>
      </div>
      <div class="card-content">
        <input id="float-input" type="text" style="width: 100%;" [(ngModel)]="selectedNewFormName" pInputText
          placeholder="Enter Form Name">
      </div>
      <p-footer>
        <button pButton type="button" label="Create New Form" icon="pi pi-check" (click)="createNewForm()"></button>
      </p-footer>
    </p-card>

  </div>
  <div class="form">
    <p-card header="Update Form" subheader="Update an exisiting form" styleClass="ui-card-shadow">
      <div class="card-content">
        <p-dropdown [options]="formProject" [style]="{'width':'100%'}" [(ngModel)]="selectedUpdateFormProject"
          placeholder="Select Project" (onChange)="onChangeDropdown()"></p-dropdown>
      </div>
      <div class="card-content">
        <p-dropdown [options]="formCategory" [style]="{'width':'100%'}" [(ngModel)]="selectedUpdateFormCategory"
          placeholder="Select Category" (onChange)="onChangeDropdown()">
        </p-dropdown>
      </div>
      <div class="card-content">
        <p-dropdown [options]="formNames" [style]="{'width':'100%'}" [(ngModel)]="selectedUpdateFormName"
          placeholder="Select Form Name" optionLabel="name" [showClear]="true">
          <!-- <ng-template let-form pTemplate="selectedItem">
                <span [class]=" form.isActive ? 'pi pi-check': 'pi pi-trash' " style="width:16px;vertical-align:middle" ></span>
                <span style="vertical-align:middle">{{form.label}}</span>
            </ng-template> -->
          <ng-template let-form pTemplate="item">
            <div class="ui-helper-clearfix" style="position: relative;height:35px;display:flex;flex-direction:row;padding-bottom:10px;border-bottom: 1px solid white;">
              <span [class]=" form.value.isActive ? 'checkbox pi pi-check': 'checkbox pi pi-trash' "></span>
              <div style="font-size:14px;margin-top:4px">{{form.label}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-footer>
        <button pButton type="button" label="Update Form" icon="pi pi-check" (click)="updateForm()"></button>
      </p-footer>
    </p-card>
  </div>
</div>

<app-dynamic-form-elements [isUpdateFormActive]=" activeFormNow === FORMS.UPDATE_FORM "
  [usersList]="usersList"
  [dynamicFormBasicConfiguration]="dynamicFormBasicConfiguration" *ngIf="!selectFormConfiguration"
  (restoreState)="restoreState($event)">
</app-dynamic-form-elements>