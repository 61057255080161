import { Component, OnInit, ViewChild } from '@angular/core';
import { TicketsViewComponent } from '../tickets-view/tickets-view.component'
import { TicketViewService } from 'src/app/services/ticket-view.service';
import { Router } from '@angular/router';

interface Ticket {
  created_timestamp: number
  issues: String[]
  ticket_number: string,
  ticket_type: string
}

@Component({
  selector: 'app-maintenace-tickets-list',
  templateUrl: './maintenace-tickets-list.component.html',
  styleUrls: ['./maintenace-tickets-list.component.css']
})
export class MaintenaceTicketsListComponent implements OnInit {

  ticketsList : Ticket[];
  cardHeading: string ;
  vehicleNumber: string;
  @ViewChild(TicketsViewComponent, {static: false}) ticketViewComp:TicketsViewComponent;
  constructor(private ticketViewService: TicketViewService, private _router: Router) { }

  ngOnInit() {
    this.getOpenTicketsByVehicle(this.vehicleNumber);
  }

  getOpenTicketsByVehicle(vehicleNumber){
    this.cardHeading = 'Open Tickets for ';
    this.vehicleNumber = vehicleNumber;
    this.cardHeading += this.vehicleNumber
    this.ticketViewService.getOpenTicketsByVehicle(vehicleNumber)
    .subscribe(res => {
      this.ticketsList = res['response'];  
    })
  }

  showTicketDetails(ticketId){
    localStorage.setItem('ticketId',ticketId)
    this._router.navigate(['/ticket/view'])
  }

}
