import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TatIssuesService {
    constructor(http) {
        this.http = http;
    }
    getAllData(obj) {
        return this.http.post(Urls.APP_URL + '/api/v1/tickets/ops-tat', obj, { headers: Headers.headers });
    }
    getIssues() {
        return this.http.get(Urls.HUB_URL + "/api/v1/issues", { headers: Headers.headers });
    }
    getMaintenanceData() {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/get-maintenance-dp", { headers: Headers.headers });
    }
    getHubs(ssoId, zoneId) {
        return this.http.get(Urls.HUB_URL + '/api/v1/hub/permission/' + ssoId + '/' + zoneId, { headers: Headers.headers });
    }
    getSubIssues(issueId, obj) {
        return this.http.post(Urls.APP_URL + '/api/v1/tickets/ops-tat-subIssue?issueId=' + issueId, obj, { headers: Headers.headers });
    }
}
TatIssuesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TatIssuesService_Factory() { return new TatIssuesService(i0.ɵɵinject(i1.HttpClient)); }, token: TatIssuesService, providedIn: "root" });
