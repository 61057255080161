import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { sanitizeHtmlInput } from '../dynamic-form-config.utils';


@Component({
  selector: 'app-input-element',
  templateUrl: './input-element.component.html',
  styleUrls: ['./input-element.component.css']
})
export class InputElementComponent implements OnInit {

  @Output() outputElementData: EventEmitter<any> = new EventEmitter<any>();

  optionsForSelectedFields: any[] = [];
  dynamicFieldCurrentLabel = '';
  dynamicFieldCurrentValue = '';
  selectedInputType = '';
  selectedFieldOptionValues: string[] = [];
  allowedTypeForInputElement = [
    { label: 'Text', value: 'text' },
    { label: 'Email', value: 'email' },
    { label: 'Password', value: 'password' },
    { label: 'Number', value: 'number' }
  ];
  constructor() {
  }
  @Input() inputElementData: any;
  dataElement: any = {};

  ngOnInit() {
    if (this.inputElementData) {
      this.dataElement = this.inputElementData;
      if (this.dataElement.disabled) {
        this.selectedFieldOptionValues.push('disabled');
      }
      if (this.dataElement.validations && this.dataElement.validations.required) {
        this.selectedFieldOptionValues.push('required');
      }
      if (this.dataElement.options) {
        this.optionsForSelectedFields =
        this.dataElement.options.map((o) => ( {...o, isSaved: true} ));
   
      }
      this.dataElement.optionsForSelectedFields = this.optionsForSelectedFields;
    }
  }

  configElementInputSubmitHandler(form: NgForm) {
    const { value } = form;
    if (value && Object.keys(value).length > 0) {
      const element: any = {
        html: 'input',
        fieldOptions: this.selectedFieldOptionValues,
        ...value,
        isConfigActive: true
      };
          
      if ( this.optionsForSelectedFields && this.optionsForSelectedFields.length > 0 ) {
        element.options = this.optionsForSelectedFields;
      }
      this.outputElementData.emit(sanitizeHtmlInput(element));
    }
  }

  selectedInputTypeHandler({value}) {
    this.selectedInputType = value;
  }
  addOption() {
    if (!this.dynamicFieldCurrentLabel || !this.dynamicFieldCurrentValue) {
      return;
    }
    const el = {
      label: this.dynamicFieldCurrentLabel,
      value: this.dynamicFieldCurrentValue,
      isSaved: true,
      isEditing: false,
    };
    this.optionsForSelectedFields.push(el);
  }

  deleteThisOption(i) {
    this.optionsForSelectedFields.splice(i, 1);
  }

  editThisOption(i) {
    this.optionsForSelectedFields[i].isEditing = true;
    this.optionsForSelectedFields[i].isSaved = false;
  }

  saveThisOption(i) {
    this.optionsForSelectedFields[i].isSaved = true;
    this.optionsForSelectedFields[i].isEditing = false;
  }

  labelChangeHandler(e, i) {
    const value = e.target.value;
    this.optionsForSelectedFields[i].label = value;
  }

  valueChangeHandler(e, i) {
    this.optionsForSelectedFields[i].value = e && e.target ? e.target.value : e.value;
  }

}
