<div class="calendar-container">

    <div class="data-container">
        <div class="container-fluid">
            <h2 *ngIf="calendarFor == 'driverTMS'">Driver Ticket Calendar</h2>
            <h2 *ngIf="calendarFor != 'driverTMS'">Service Ticket Calendar</h2>
            <br/>
            
        </div>

        <br/>
         <br/>

        <div>
            <div class="container-fluid custom-calendar">
                <p-fullCalendar [events]="events" [options]="options"  *ngIf="selectedDate==null"></p-fullCalendar>
                <app-daywise-tickets *ngIf="selectedDate!=null" [selectedDate]="selectedDate" (showCalander)="showCalander($event)"></app-daywise-tickets>
            </div>
        </div>
    </div>
</div>