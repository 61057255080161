import { UserService } from './../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {

  LocaleUrlMapping = {
    IN: {
      "MENU_TICKETS": "/ticket/view",
      "MENU_CREATE_TICKETS": "/ticket/heirarchy",
      "MENU_CONFIGURE_TICKETS": "/dynamic/form/create",
      "MENU_DASHBOARD": "/dashboard",
      "MENU_CHARGER": "/charger",
      "MENU_KEY_HANDOVER": "/keyhandover",
      "MENU_VEHICLES_HUB": "/vehicles",
      "MENU_DRIVERS": "/drivers/ALL_DRIVERS",
      "MENU_CASHBOOK": "/cash/ALL_TRANSACTIONS",
      "MENU_REPORT_HUB": "/reports/CASH_REPORT",
      "MENU_ATTENDANCE": "/attendance",
      "MENU_LIVE_ATTENDANCE": "/current_attendance",
      "MENU_SETTLEMENT": "/settlement/SETTLEMENT",
      "MENU_LIVE_MAP_HUB": "/live-map",
      "MENU_LEASE": "/lease",
      "MENU_NOTIFICATIONS": "/notification",
      "MENU_DRIVER_ISSUES":"/driver/issues/view",
      "MENU_ISSUES_TAT": "/issues/tat",
    },
    AE: {
      "MENU_TICKETS": "/ticket/view",
      "MENU_CREATE_TICKETS": "/ticket/heirarchy",
      "MENU_CONFIGURE_TICKETS": "/dynamic/form/create",
    }
  };

  constructor(private cookieService: CookieService, private router: Router, private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this will be passed from the route config
    // on the data property
    //const expectedRole = route.data;
    const country = localStorage.getItem('country');
    const accessToken = this.cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);
    if (!Headers.headers)
      Headers.addHeaders({ key: 'Authorization', value: 'Bearer ' + accessToken });
    const ssoid = this.cookieService.get('ssoId');

    let userMenuRoles = {}
    let rolesURL = this.LocaleUrlMapping[country];

    //this.userService.getUserRole(ssoid)
    let lastRole
    localStorage.setItem('ssoId', ssoid)
    if (ssoid) {
      this.userService.getUserRole(ssoid).subscribe(
        (res: any) => {
          localStorage.setItem("userRoles", JSON.stringify(res.response))
          res.response.forEach(role => {
            if (JSON.stringify(role).indexOf("MENU") !== -1) {
            userMenuRoles[rolesURL[role]] = role
              lastRole = rolesURL[role]
            }
          });
          if (!userMenuRoles[next['_routerState'].url]) {
            this.router.navigateByUrl(lastRole);
            return false;
          }
        }
      )
    }
    return true;

  }
}


