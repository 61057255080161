 <div
    [class]="config.className ? config.className : 'dynamicForm'"
    [formGroup]="group"
  >
    <label *ngIf="config.label" for="config.htmlId"
      >{{ config.label }}
      <span
        *ngIf="config.validations && config.validations.required"
        class="required_field"
        >*</span
      >
    </label>
    <ng-container *ngIf="config.multiple">
      <p-multiSelect
        appendTo="body"
        [options]="config.options"
        [formControlName]="config.name"
        [defaultLabel]="config.optionLabel"
        filter="true"
        filterBy="label, value"
        [styleClass]="
          config.styleClass ? config.styleClass : 'dynamicForm singleSelect'
        "
        [panelStyleClass]="config.styleClass ? config.styleClass : ''"
      >
      </p-multiSelect>
    </ng-container>
    <ng-container *ngIf="!config.multiple">
      <p-dropdown
        appendTo="body"
        [options]="config.options"
        [formControlName]="config.name"
        filter="true"
        filterBy="label, value"
        (onChange)="onVehicleChange($event)"
        [placeholder]="config.placeholder ? config.placeholder : config.label"
        [styleClass]="config.styleClass ? config.styleClass : ''"
        [panelStyleClass]="
          config.styleClass ? config.styleClass : 'dynamicForm multiSelect'
        "
      >
      </p-dropdown>

      <div *ngIf="isOdometer && config.label == 'Vehicle Number'" class="mt-2">
        <label for=""
          >Odometer Reading <span class="required_field">*</span></label
        >
        <input
          pInputText
          [(ngModel)]="odometerReading"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Odometer Reading"
          class="inputField"
          required
          (input)="setOdometerReading($event)"
          (keydown)="validateOdometerReading($event)"
        />
        <div
          *ngIf="group.controls['odometerReading']?.invalid"
          class="text-danger mt-2"
        >
          Please enter valid odometer reading
        </div>
      </div>

      <app-maintenace-tickets-list
        *ngIf="isShowTickets"
        (onVehicleChange)="onVehicleChange($event)"
      ></app-maintenace-tickets-list>
    </ng-container>
  </div>