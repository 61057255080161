<div class="old-ticket-list" *ngIf="vehicleNumber && vehicleNumber != '' && ticketsList.length > 0">
    <p-card header="{{cardHeading}}">
      <table style="width: 100%;border-collapse: separate;border-spacing: 0 1rem;">
        <tr style="width: 100%;font-weight: 600;" (click)="showTicketDetails(ticket.ticket_number)" *ngFor="let ticket of ticketsList">
          <td style="padding: 10px 5px 5px 5px;display: flex;">
            <div style="width: 40%;">{{ticket.ticket_number}}</div>
            <div style="width: 60%;">
              <span style="float: right;background: #336DB9; padding:2px 10px;border-radius: 5px;">{{ticket.ticket_type}}</span>
               </div>
          </td>
          <td style="padding: 0px 5px 10px 5px;display: flex;">
            <div style="width: 50%;position:relative;top:5px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;"  class="ui-g-1">
                <b pTooltip="{{ticket.issues}}" tooltipPosition="bottom">{{ticket.issues}}</b>
                
            </div>
            <div style="width: 50%;"> <span style="float: right;position:relative;top:5px;">{{ticket.created_timestamp | amTimeAgo}}</span></div>
          </td>
        </tr>
      </table>
    </p-card>
  </div>