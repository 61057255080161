import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Headers } from "../models/api.headers";
import { Urls } from "../models/api.constants";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class IncidentHeirarchyService {
  constructor(
    private _http: HttpClient,
    private cookieService: CookieService
  ) {}
  getHeirarchyRootForm(project, name): Observable<any> {
    return this._http.post<{}>(
      Urls.APP_URL + "/api/v1/form/get-active-form",
      { name, project },
      { headers: Headers.headers }
    );
  }
  getFormById(formId): Observable<any> {
    let url;
    this.cookieService.get("selectedProject") == "techSupportTMS"
      ? (url =
          "/api/v1/form/get-active-form-by-id?isUserPermissionCheck=true&formId=")
      : (url = "/api/v1/form/get-active-form-by-id?formId=");
    return this._http.get<{}>(Urls.APP_URL + url + formId, {
      headers: Headers.headers,
    });
  }
  submitTheForm(formData): Observable<any> {
    return this._http.post<{}>(
      Urls.APP_URL + "/api/v1/form/get-active-form",
      { name, project: "tms" },
      { headers: Headers.headers }
    );
    // return this._http.get(Urls.APP_URL + `/api/v1/heirarchy/fetch-form?formId=${formData}`);
  }
  getPaginatedDrivers(searchKey) {
    return this._http.get<{}>(
      Urls.HUB_URL +
        "/api/v1/drivers/from-all-hubs?page=0&size=100&searchTerm=" +
        searchKey,
      { headers: Headers.headers }
    );
  }

  getIssues(): Observable<any> {
    return this._http.get<any>(Urls.HUB_URL + "/api/v1/issues",{ headers: Headers.headers });
  }

  getSubIssusesList(
    zoneId: number | string,
    issueId: number | string
  ): Observable<any> {
    const params = new HttpParams()
      .set("zoneId", zoneId.toString())
      .set("issueId", issueId.toString());

    return this._http.get<any>(Urls.APP_URL + "/api/v1/sub-issue-by-id",{
      headers: Headers.headers,
      params,
    }
    );
  }
}
