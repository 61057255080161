import { Urls } from '../models/api.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class IncidentsService {
    constructor(http) {
        this.http = http;
    }
    getAllIncidents() {
        return this.http.get(Urls.APP_URL + '/api/v1/incident');
    }
}
IncidentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IncidentsService_Factory() { return new IncidentsService(i0.ɵɵinject(i1.HttpClient)); }, token: IncidentsService, providedIn: "root" });
