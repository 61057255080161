import { Component, OnInit } from '@angular/core';
import { ServiceTicketService } from '../../../services/service-ticket.service'
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionForUsers } from '../../../guards/checkRoles'

@Component({
    selector: 'app-ticket-settings',
    templateUrl: './ticket-settings.component.html',
    styleUrls: ['./ticket-settings.component.css']
})
export class TicketSettingsComponent implements OnInit {

    constructor(private _serviceTicketService: ServiceTicketService, private messageService: MessageService, private confirmationService: ConfirmationService,private spinner: NgxSpinnerService) { }
    cols: any[];
    data: any[];
    productDialog: boolean = false;
    selectedData: any = { 'manufacturer': '','model':'','variant':'','range':0, 'serviceKms': 0, 'dailyAvgKms': 0, serviceType: '' };
    selectedIndex: any;
    submitted: boolean = false;
    isLoadComplete: boolean = false;
    isLoadMessage: any;
    dataLength: Number;
    manufacturerList: any[];
    serviceKmsList: any[];
    dailyAvgKmsList: any[];
    modelRange:any[];
    serviceTypeList: any[];
    modelName: any[];
    modelList: any[];
    variantName: any[];
    variantList: any[];
    rangeList:any[];

    ngOnInit() {
        this.cols = [
            { field: 'manufacturer', header: 'Manufacturer' },
            { field: 'serviceKms', header: 'Service Kms' },
            { field: 'dailyAvgKms', header: 'Daily Avg Kms' },
            { field: 'serviceType', header: 'Service Type' },

        ];

        this.getConfig();
        this.manufacturerList = [{ label: 'Tata', value: 'Tata' }, { label: 'Mahindra', value: 'Mahindra' }, { label: 'Hyuandai', value: 'Hyuandai' }]
        this.serviceKmsList = [{ label: '2000 Kms', value: 2000 }, { label: '1000 Kms', value: 1000 }, { label: '500 Kms', value: 500 }, { label: '250 Kms', value: 250 }, { label: '100 Kms', value: 100 }];
        this.dailyAvgKmsList = [{ label: '2000 Kms', value: 2000 }, { label: '1000 Kms', value: 1000 }, { label: '500 Kms', value: 500 }, { label: '250 Kms', value: 250 }, { label: '100 Kms', value: 100 }];
        this.serviceTypeList = [{ label: 'Deep Cleaning', value: 'Deep Cleaning' },{ label:'Allignment Balancing', value:'Allignment Balancing'},{ label:'Preventive Service', value:'Preventive Service'}]
        this.modelName = [
            {manufacturer: 'Mahindra', model: 'E-verito'},
            {manufacturer: 'Mahindra', model: 'E Verito'}, 
            {manufacturer: 'Mahindra', model: 'Verito EV'},
            {manufacturer: 'Tata', model: 'Tata Tigor'},
            {manufacturer: 'Tata', model: 'Tigor EV'},
            {manufacturer: 'Hyuandai', model: 'HYUANDAI KONAEV'}
          ];
          this.modelList =[{label:'', value:''}];
        this.variantName = [
            {model: 'Tata Tigor', variant: 'Tata Tigor'}, 
            {model: 'Tata Tigor', variant: 'XM'}, 
            {model: 'Tata Tigor', variant: 'XM+'}, 
            {model: 'Tigor EV', variant: 'XM'}, 
            {model: 'Tigor EV', variant: 'XM+'}, 
            {model: 'E Verito', variant: 'C2'},
            {model: 'Verito EV', variant: 'C2'}, 
            {model: 'E-verito', variant: 'D6'}, 
            {model: 'HYUANDAI KONAEV', variant: 'HYUANDAI KONAEV'}
        ];
        this.variantList =[{label:'', value:''}];
   
    this.modelRange = [
        {variant:'HYUANDAI KONAEV',range:300},
        {variant:'D6',range:160},
        {variant:'C2',range:110},
        {variant:'XM',range:110},
        {variant:'XM+',range:213},
        {variant:'Tata Tigor',range:90},
    ];
    this.rangeList =[{label:'',value:''}]


    }

    getConfig() {
        this.spinner.show();
        this._serviceTicketService.getAllServiceTicketConfig().subscribe(
            (responseServer: any) => {
                this.spinner.hide();
                this.isLoadComplete = true;
                const { status: code, errorMessage: message, } = responseServer;
                if (code != 200) {
                    this.isLoadMessage = message;
                    return alert(message);
                } else {

                    this.data = responseServer.response;
                    this.dataLength = this.data.length;
                }
            }
        );
    }

    openNew() {
        this.selectedData = {};
        this.submitted = false;
        this.productDialog = true;
    }



    editProduct(dataitem: any, index: any) {
        this.populateModel(dataitem.manufacturer);
        this.populateVariant(dataitem.model);
        this.selectedData = { ...dataitem };
        this.selectedIndex = index;
        this.productDialog = true;
    }

    deleteProduct(dataitem: any, index: any) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + this.selectedData.manufacturer + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.spinner.show();
                this._serviceTicketService.deleteServiceTicketConfiguration(dataitem._id).subscribe((responseServer: any) => {
                    this.isLoadComplete = true;
                    this.spinner.hide();
                    const { status: code, errorMessage: message, } = responseServer;
                    if (code != 200) {
                        this.isLoadMessage = message;
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error deleting record', life: 3000 });
                        return ;
                    } else {
                        // this.data = this.data.filter(val => val.id !== this.selectedData._id);
                        this.data.splice(index,1)
                        this.dataLength = this.data.length;
                        this.selectedData = {};
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Record deleted successfully', life: 3000 });

                    }
                },
                    err => {
                        this.isLoadComplete = true;
                        this.spinner.hide();
                        this.productDialog = false;
                        this.isLoadMessage = 'Some error occured';
                        alert('Some error occured');
                    }
                );
               
            }
        });

    }

    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    }

    saveProduct() {
        this.submitted = true;
        // console.log("Selected data "+JSON.stringify(this.selectedData))
        if (this.selectedData.manufacturer) {
            if (this.selectedData._id) {
                if(this.selectedData && this.selectedData.manufacturer && this.selectedData.serviceKms && this.selectedData.serviceType && this.selectedData.dailyAvgKms 
                    && this.selectedData.model && this.selectedData.range && this.selectedData.variant && this.selectedData.range != '' && this.selectedData.dailyAvgKms !='' && this.selectedData.serviceKms!=''){
                    this.data[this.selectedIndex] = this.selectedData;
                // this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
                //API Call and refresh p-table 
                this.spinner.show();
                this._serviceTicketService.updateServiceTicketConfiguration(this.selectedData).subscribe((responseServer: any) => {
                    this.isLoadComplete = true;
                    this.spinner.hide();
                    const { status: code, errorMessage: message, } = responseServer;
                    if (code != 200) {
                        this.isLoadMessage = message;
                        return alert(message);
                    } else {
                        this.productDialog = false;
                        this.data = [...this.data];
                        this.selectedData = {};
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });

                    }
                },
                    err => {
                        this.spinner.hide();
                        this.isLoadComplete = true;
                        this.productDialog = false;
                        this.isLoadMessage = 'Some error occured';
                        alert('Some error occured');
                    }
                );
                }else{
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter the data', life: 3000 });  
                }
            }
            else {
                if (this.selectedData && this.selectedData.manufacturer && this.selectedData.serviceKms && this.selectedData.serviceType && this.selectedData.dailyAvgKms 
                    && this.selectedData.model && this.selectedData.range && this.selectedData.variant && this.selectedData.range != '' && this.selectedData.dailyAvgKms !='' && this.selectedData.serviceKms!=''
                    ) {
                    // this.selectedData.id = this.createId();
                    //API call and refresh p-table
                    this.spinner.show();
                    this._serviceTicketService.createServiceTicketConfiguration(this.selectedData).subscribe((responseServer: any) => {
                        this.isLoadComplete = true;
                        this.spinner.hide();
                        const { status: code, errorMessage: message, } = responseServer;
                        if (code != 200) {
                            this.isLoadMessage = message;
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.isLoadMessage, life: 3000 });
                            return;
                        } else {
                            this.selectedData._id = responseServer.response._id;
                            this.data.push(this.selectedData);
                            this.productDialog = false;
                            this.data = [...this.data];
                            this.dataLength = this.data.length;
                            this.selectedData = {};
                            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });

                        }
                    },
                        err => {
                            this.spinner.hide();
                            this.isLoadComplete = true;
                            this.productDialog = false;
                            this.isLoadMessage = 'Some error occured';
                            alert('Some error occured');
                        }
                    );

                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter the data', life: 3000 });
                }
            }


        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter the data', life: 3000 });
        }

    }

    populateModel(model){
        this.selectedData.model =null;
        this.selectedData.variant =null;
        console.log(model);
        this.modelList.splice(0,this.modelList.length)
        let models =this.modelName.map(async (element)=>{
             if(element.manufacturer == model){
              this.modelList.push({ label: element.model, value: element.model }) 
            }
        })
       
    }

    populateVariant(variant){
        console.log(variant);
        this.selectedData.variant =null;
        this.selectedData.range =null;
        this.variantList.splice(0,this.variantList.length)
        let variants =this.variantName.map(element=>{
            if(element.model == variant){
                this.variantList.push({ label: element.variant, value: element.variant }) 
            }
        })
       
    }

    populateRange(variant){
        console.log(variant);
        this.selectedData.range =null;
        this.rangeList.splice(0,this.rangeList.length)
        let ranges =this.modelRange.map(element=>{
            if(element.variant == variant){
                this.rangeList.push({ label: element.range, value: element.range }) 
            }
        })
       
    }

    checkPermission(permissionString: String) {
        return PermissionForUsers.checkPermission(permissionString);
      }




}
