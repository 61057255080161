import { Component, OnInit, OnChanges, DoCheck, EventEmitter, Output, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { sanitizeHtmlInputGroupRadio } from '../dynamic-form-config.utils';

@Component({
  selector: 'app-radio-element',
  templateUrl: './radio-element.component.html',
  styleUrls: ['./radio-element.component.css']
})

export class RadioElementComponent implements OnInit {

  @Output() outputElementData: EventEmitter<any> = new EventEmitter<any>();



  selectedFieldOptionValues: string[] = [];
  optionsForSelectedFields: any[] = [];
  dynamicFieldCurrentLabel = '';
  dynamicFieldCurrentValue = '';

  constructor() {
  }

  @Input() inputElementData: any;
  dataElement: any = {};

  ngOnInit() {
    if (this.inputElementData) {
      this.dataElement = this.inputElementData;
      if (this.dataElement.disabled) {
        this.selectedFieldOptionValues.push('disabled');
      }
      if (this.dataElement.validations && this.dataElement.validations.required) {
        this.selectedFieldOptionValues.push('required');
      }
    }
    this.optionsForSelectedFields =
      !this.dataElement.options ?
        this.optionsForSelectedFields :
          this.dataElement.options.map((o) => ({...o, isSaved: true}));
    this.dataElement.optionsForSelectedFields = this.optionsForSelectedFields;
  }
  configElementInputGroupRadioSubmitHandler(form: NgForm) {
    const { value } = form;
    if (value && Object.keys(value).length > 0 && this.optionsForSelectedFields && this.optionsForSelectedFields.length > 0) {
      const element: any = {
        fieldOptions: this.selectedFieldOptionValues,
        ...value,
        options: this.optionsForSelectedFields,
        isConfigActive: true
      };
      this.outputElementData.emit(sanitizeHtmlInputGroupRadio(element));
    }
  }

  addOption() {
    if (!this.dynamicFieldCurrentLabel || !this.dynamicFieldCurrentValue) {
      return;
    }
    const el = {
      label: this.dynamicFieldCurrentLabel,
      value: this.dynamicFieldCurrentValue,
      isSaved: true,
      isEditing: false,
    };
    this.optionsForSelectedFields.push(el);
  }

  deleteThisOption(i) {
    this.optionsForSelectedFields.splice(i, 1);
  }

  editThisOption(i) {
    this.optionsForSelectedFields[i].isEditing = true;
    this.optionsForSelectedFields[i].isSaved = false;
  }

  saveThisOption(i) {
    this.optionsForSelectedFields[i].isSaved = true;
    this.optionsForSelectedFields[i].isEditing = false;
  }

  labelChangeHandler(e, i) {
    const value = e.target.value;
    this.optionsForSelectedFields[i].label = value;
  }

  valueChangeHandler(e, i) {
    this.optionsForSelectedFields[i].value = e && e.target ? e.target.value : e.value;
  }
}
