import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class StageViewService {

  constructor(private http: HttpClient,private cookieService: CookieService) { }

  getStageDataFromStageId(ticketStageId): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/linked/stages?stageId=' + ticketStageId, { headers: Headers.headers });
  }

  getAllStages(): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/linked/stages/all', { headers: Headers.headers });
  }
  getVehicleSeviceRegionList(): Observable<{}>{
    return this.http.get<{}>(Urls.APP_URL+'/api/v1/vehicle/service/region/'+this.cookieService.get('selectedZone'),{ headers: Headers.headers });
  }
}