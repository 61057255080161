<div class="heirarchy">
    <div *ngIf="currentlyActiveForm" class="container" style="padding: 10px;">
      <div class="background">
        <div class="steps">
          <p-steps
            [model]="items"
            [readonly]="false"
            [activeIndex]="activeIndex"
            (activeIndexChange)="activeIndexChangeHandler($event)"
          ></p-steps>
        </div>
        <div class="renderedForm">
          <!-- <p style="padding-left:20%;">{{ currentlyActiveForm.name }}</p> -->
          <app-form-renderer
            [formConfig]="currentlyActiveForm"
            (formSubmitHandler)="submitCurrentForm($event)"
          >
          </app-form-renderer>
          
          <button
            [disabled]="activeIndex <= 0"
            (click)="activeIndexChangeHandler(activeIndex - 1)"
            label="Back"
            class="ui-button-secondary backButton"
            pButton
          ></button>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoadComplete" style="padding-left: 40%; padding-top: 150px;"> 
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>

  <p-toast>
    <ng-template let-message pTemplate="message">
      <div class="stack">
        <p class="head">
          <ng-container>
            <i class="pi pi-check" *ngIf="message.severity == 'success' "></i>
            <i class="pi pi-info-circle" *ngIf="message.severity == 'info' "></i>
          </ng-container>
           &nbsp;
           {{ message.summary }}
        </p>
        <p *ngIf="message.detail" class="detail">
          {{ message.detail }}
        </p>
        <ng-container *ngIf="message.data">
          <p *ngFor="let detail of message.data" class="detail">
            {{ detail }}
          </p>
        </ng-container>
      </div>
    </ng-template>
  </p-toast>