<div class="create-ticket-container">
    <div class="form-container">
        <div class="row-table">
            <div style="width:50%;margin:5px;">
                <div class="inside-cell">
                    <span class="ui-float-label">
                        <p-dropdown [style]="{'width':'100%'}" [options]="carList" [(ngModel)]="carRegistrationNumber"
                            optionLabel="label" [showClear]="true" placeholder="Select Car Registration Number"
                            [filter]="true" required>
                        </p-dropdown>
                    </span>
                </div>
                <div class="inside-cell">
                    <span class="ui-float-label">
                        <p-dropdown [style]="{'width':'100%'}" [options]="driverList" [(ngModel)]="driverPunchId"
                            optionLabel="label" [showClear]="true" placeholder="Select Driver (Optional)"
                            [filter]="true" required>
                        </p-dropdown>
                    </span>
                </div>
                <div class="inside-cell">
                    <span class="ui-float-label">
                        <p-dropdown [style]="{'width':'100%'}" [options]="hubList" [(ngModel)]="hub" optionLabel="label"
                            [showClear]="true" placeholder="Select Hub" required>
                        </p-dropdown>
                    </span>
                </div>
                <div class="inside-cell">
                    <span class="ui-float-label">
                        <p-dropdown [style]="{'width':'100%'}" [options]="incidentCategories"
                            (onChange)='selectedCategory($event.value)' [(ngModel)]="selectedIncidentCategorySubmit"
                            placeholder="Select Incident Type" optionLabel="label" [showClear]="true" required>
                        </p-dropdown>
                    </span>
                </div>
            </div>
            <div style="width:50%;margin:5px;">
                <div class="inside-cell" *ngIf="selectedIncidentCategory=='Challan'">
                    <div class="inside-cell">
                        <span class="ui-float-label">
                            <input id="float-input" type="text" size="30" [(ngModel)]="challanDetails" pInputText
                                required>
                            <label for="float-input">Challan Number</label>
                        </span>
                    </div>
                </div>
                <div class="inside-cell" *ngIf="selectedIncidentCategory=='Missing_Items'" multiple>
                    <span class="ui-float-label">
                        <p-multiSelect [style]="{'width':'100%'}" [options]="missing_items" optionLabel="label"
                            [(ngModel)]="selectedMissingItems" (onChange)="missingItemsOthers($event)" placeholder="Select Missing Items" required>
                        </p-multiSelect>
                    </span>
                </div>
                <div class="inside-cell" *ngIf="selectedMissingOthers=='Others' && selectedIncidentCategory=='Missing_Items'">
                    <div class="inside-cell">
                        <span class="ui-float-label">
                            <input id="float-input" type="text" size="30" [(ngModel)]="selectedMissingOthersText" pInputText
                                required>
                            <label for="float-input">Other Missing Items</label>
                        </span>
                    </div>
                </div>
                <div class="inside-cell" *ngIf="selectedIncidentCategory=='Not_Working_Properly'">
                    <span class="ui-float-label">
                        <p-multiSelect [style]="{'width':'100%'}" [options]="not_working_properly" optionLabel="label"
                            [(ngModel)]="selectedNotWorkingProperly" (onChange)="notWorkingProperlyOthers($event)" placeholder="Select Not Working Items" required>
                        </p-multiSelect>
                    </span>
                </div>
                <div class="inside-cell" *ngIf="selectedNotWorkingProperlyOthers=='Others' && selectedIncidentCategory=='Not_Working_Properly'">
                    <div class="inside-cell">
                        <span class="ui-float-label">
                            <input id="float-input" type="text" size="30" [(ngModel)]="selectedNotWorkingProperlyOthersText" pInputText
                                required>
                            <label for="float-input">Other Not Working Properly Items</label>
                        </span>
                    </div>
                </div>
                <div class="inside-cell" *ngIf="selectedIncidentCategory=='Accident'">
                    <div class="inside-cell">
                        <span class="ui-float-label">
                            <input id="float-input" type="text" size="30" [(ngModel)]="accidentLocation" pInputText
                                required>
                            <label for="float-input">Accident Location</label>
                        </span>
                    </div>
                </div>
                <div class="inside-cell" *ngIf="selectedIncidentCategory=='Accident'">
                    <div class="inside-cell">
                        <span class="ui-float-label">
                            <p-calendar [style]="{'width':'100%'}" [(ngModel)]="accidentDate"></p-calendar>
                            <label for="float-input">Accident Date</label>
                        </span>
                    </div>
                </div>
                <div class="inside-cell" *ngIf="selectedIncidentCategory=='Accident'">
                    <div class="inside-cell">
                        <span class="ui-float-label">
                            <p-calendar [style]="{'width':'100%'}" [timeOnly]="true" hourFormat="12"
                                [(ngModel)]="accidentTime"></p-calendar>
                            <label for="float-input">Accident Time</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="button-container">
        <div style="margin:auto">
            <button type="button" class="submit" (click)="submit()">Submit</button>
            <button type="button" class="submit" (click)="reset()">Reset</button>
        </div>
    </div>
</div>

<p-toast></p-toast>
