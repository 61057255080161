<form #configElementInputDropdown="ngForm" (ngSubmit)="
              configElementInputDropdownSubmitHandler(
                configElementInputDropdown)
            " class="column" autocomplete="off">
  <div class="field">
    <label for="label">Label</label>
    <input type="text" placeholder="Label" id="label" [ngModel]="dataElement.label" required #label="ngModel" name="label" pInputText />
  </div>
  <div class="field">
    <label for="name">Name</label>
    <input type="text" placeholder="Name" id="name" [ngModel]="dataElement.name" required #name="ngModel" name="name" pInputText />
  </div>
 <!-- Removed Items -->
 <div class="field">
  <label for="form">Form</label>
  <div>
    <p-multiSelect [options]="listOfActiveForms" [style]="{width:'100%'}"  [(ngModel)]="optionsForSelectedFields" name="forms" inputId="form"
      placeholder="Forms" required filter="true" filterBy="label, value.name" >
    </p-multiSelect>
  </div>
</div>
  <div class="field submit-btn">
    <button pButton type="button" type="submit" label="Save Element" class="ui-button-warning" [disabled]="configElementInputDropdown.invalid">
    </button>
  </div>
</form>








<!-- Removed items -->

 <!-- <div class="field">
    <label for="value">Value</label>
    <input type="text" placeholder="value" id="value" ngModel #value="ngModel" name="value" pInputText />
  </div> -->

  <!-- <p-fieldset legend="Options">
    <div style="width:100%">
      <div style="padding:2%; text-align:center; width:35%; float:left;">
        <span class="ui-float-label">
          <input id="float-input" style="width:100%" type="text" [(ngModel)]="dynamicFieldCurrentLabel"
            [ngModelOptions]="{ standalone: true }" pInputText>
          <label for="float-input">Label</label>
        </span>
      </div>
      <div style="padding:2%; text-align:center; width:45%; float:left;">
        <span>
          <p-dropdown [style]="{'width':'100%'}" [autoWidth]="false" [options]="listOfActiveForms" inputId="listOfActiveForms"
            placeholder="Select a form" (onChange)="valueChangeHandler($event, i)" required></p-dropdown>
        </span>
      </div>
      <div style="padding:2%;width:20%; float:left;">
        <button pButton type="button" style="width:100%;" (click)="addOption()" label="Add"></button>
      </div>
    </div>
    <div>
      <ng-container *ngFor="let option of optionsForSelectedFields; index as i">
        <div *ngIf="option.isSaved" style="width: 100%;">
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" [value]="option.label" disabled pInputText />
          </div>
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" [value]="option.value" disabled pInputText />
          </div>
          <div style="padding:2%;width:30%; float:left;">
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-info" icon="pi pi-pencil"
                (click)="editThisOption(i)">
              </button>
            </div>
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-danger" icon="pi pi-times"
                (click)="deleteThisOption(i)">
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="option.isEditing" style="width: 100%;">
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" (change)="labelChangeHandler($event, i)" [value]="option.label"
              pInputText />
          </div>
          <div style="padding:2%; text-align:center; width:35%; float:left;">
            <input style="width:100%" type="text" (change)="valueChangeHandler($event, i)" [value]="option.value"
              pInputText />
          </div>
          <div style="padding:2%;width:30%; float:left;">
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-success" icon="pi pi-save"
                (click)="saveThisOption(i)">
              </button>
            </div>
            <div style="padding:2%;float: left;">
              <button pButton style="width:100%;" class="ui-button-danger" icon="pi pi-times"
                (click)="deleteThisOption(i)">
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </p-fieldset> -->
