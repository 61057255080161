import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
export class TicketsService {
    constructor(http, cookieService) {
        this.http = http;
        this.cookieService = cookieService;
    }
    requestThisUrl(url) {
        return this.http.get(url, { headers: Headers.headers }).toPromise();
    }
    getAllTickets() {
        return this.http.get(Urls.APP_URL + '/api/v1/ticket', { headers: Headers.headers });
    }
    getOneTickets(ticketId) {
        return this.http.get(Urls.APP_URL + '/api/v1/ticket/' + ticketId);
    }
    closeTicket(ticketId) {
        return this.http.delete(Urls.APP_URL + '/api/v1/ticket/' + ticketId);
    }
    saveTicket(ticket) {
        return this.http.post(Urls.APP_URL + '/api/v1/ticket', ticket, { headers: Headers.headers });
    }
    updateTicket(ticket) {
        return this.http.put(Urls.APP_URL + '/api/v1/ticket', ticket, { headers: Headers.headers });
    }
    formConfigTicket(name) {
        return this.http.post(Urls.APP_URL + '/api/v1/form/get-active-form', { name, project: 'ticket-management-system' }, { headers: Headers.headers });
    }
    pushFormData(formData) {
        return this.http.post('https://api.imgbb.com/1/upload?key=5ac778a7f45ba948009d311608f89213', formData);
    }
    updateRepplaceThisForm(formData) {
        return this.http.put(Urls.APP_URL + '/api/v1/form/update-replace', formData);
    }
    getFormById(formId) {
        let url = '/api/v1/form/get-active-form-by-id?formId=';
        return this.http.get(Urls.APP_URL + url + formId, { headers: Headers.headers });
    }
    createTicket(ticketFormData) {
        let url = '/api/v1/tickets/details/create';
        return this.http.post(Urls.APP_URL + url, ticketFormData);
    }
    fetchCurrentlyActiveForm(project, category, name, isRoot) {
        const query = { project, category, name };
        if (typeof isRoot === 'boolean') {
            query.isRoot = isRoot;
        }
        return this.http.post(Urls.APP_URL + '/api/v1/form/get-active-form', query);
    }
}
TicketsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketsService_Factory() { return new TicketsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: TicketsService, providedIn: "root" });
