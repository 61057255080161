import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';

@Injectable({
  providedIn: 'root'
})
export class TatIssuesService {

  constructor(private http: HttpClient) { }

  getAllData(obj): Observable<{}> {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/tickets/ops-tat', obj, { headers: Headers.headers });
  }

  getIssues(): Observable<any> {
    return this.http.get<any>(Urls.HUB_URL + "/api/v1/issues", { headers: Headers.headers });
  }

  getMaintenanceData(): Observable<any> {
    return this.http.get(Urls.APP_URL + "/api/v1/tickets/get-maintenance-dp", { headers: Headers.headers });
  }

  getHubs(ssoId, zoneId): Observable<any> {
    return this.http.get<any>(Urls.HUB_URL + '/api/v1/hub/permission/' + ssoId + '/' + zoneId, { headers: Headers.headers });
  }

  getSubIssues(issueId,obj): Observable<{}> 
  {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/tickets/ops-tat-subIssue?issueId=' + issueId,obj, { headers: Headers.headers });

  }
}

