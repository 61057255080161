import { FormModule } from '../form/form.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormUpdateConfigComponent } from './form-config.component';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {InputElementConfigComponent, CheckboxElementConfigComponent, RadioElementConfigComponent, CreateFormElementsComponent, UpdateFormElementsComponent, DropDownElementConfigComponent, MultiselectElementConfigComponent} from './elements-config.component';
@NgModule({
  declarations: [FormUpdateConfigComponent, InputElementConfigComponent, CheckboxElementConfigComponent, RadioElementConfigComponent, CreateFormElementsComponent, UpdateFormElementsComponent, DropDownElementConfigComponent, MultiselectElementConfigComponent],
  imports: [
    CommonModule,
    ListboxModule,
    FormModule,
    DropdownModule,
    FormsModule,
    CheckboxModule,
    DialogModule,
    MultiSelectModule
  ],
  exports: [FormUpdateConfigComponent],
  providers: []
})
export class FormConfigModule { }
