
    <div style="position: relative;">
      <p style="color: white; font-size: 12px;padding-left: 2%;">Upload File</p>
      <!-- <label [attr.for]="inputId"> {{label}} </label> <br/>
      <input
      class="file-input"
        type="file"
        [attr.id]="inputId"
        [attr.accept]="accept"
        [attr.multiple]="multiple"
      />-->
      <span class="btn btn-primary btn-file">
        <input
          type="file"
          [attr.id]="inputId"
          [attr.accept]="accept"
          [attr.multiple]="multiple"
        />
      </span>
    </div>
  