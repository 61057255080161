<div class="heirarchy">
  <div *ngIf="currentlyActiveForm" class="container" style="padding: 10px">
    <div class="steps">
      <p-steps
        [model]="items"
        [readonly]="false"
        [activeIndex]="activeIndex"
        (activeIndexChange)="activeIndexChangeHandler($event)"
      ></p-steps>
    </div>
    <div class="background">
      <div class="renderedForm">
        <p>{{ currentlyActiveForm.name }}</p>
        <div
          *ngIf="
            currentlyActiveForm.name === 'Unplanned Non Incident Repair' ||
            currentlyActiveForm.name === 'Unplanned Incident Repair'
          "
          style="display: flex; flex-direction: column"
        >
          <div *ngIf="currentlyActiveForm.name === 'Unplanned Incident Repair'">
            <label
              *ngIf="isLoadComplete"
              style="
                color: white;
                font-size: 13px;
                margin-left: 10px;
                display: flex;
              "
              >Driver
              <div style="color: red; margin-left: 5px">*</div></label
            >
          </div>
          <div
            *ngIf="currentlyActiveForm.name === 'Unplanned Non Incident Repair'"
          >
            <label
              *ngIf="isLoadComplete"
              style="color: white; font-size: 13px; margin-left: 10px"
              >Driver
            </label>
          </div>
          <div #divClick>
            <form #selectDriverForm="ngForm" id="selectDriverForm">
              <p-autoComplete
                [suggestions]="driverListData"
                ngModel
                name="selectedDriver"
                [size]="20"
                [minLength]="1"
                [dropdown]="true"
                [style]="{ width: '400px' }"
                placeholder="Search by punchId or driver's name"
                field="label"
                (keydown)="removeEvent($event)"
                (onSelect)="onSelect()"
                (completeMethod)="getDriver($event)"
              >
              </p-autoComplete>
              <div
                *ngIf="selectDriverForm.dirty"
                style="color: white; font-size: 12px"
              >
                No Drivers found with this name/ punch ID
              </div>
              <div
                *ngFor="let issue of subIssue; let customIndex = index"
                class="issue-container"
              >
                <div>
                  <label class="label"
                    >Issue
                    <div class="asterisk">*</div></label
                  >
                  <p-dropdown
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="issueNgModel[customIndex]"
                    [options]="issueList"
                    optionLabel="label"
                    name="issueName"
                    styleClass="custom-dropdown"
                    placeholder="Select an issue"
                    [autoWidth]="false"
                    [style]="{ width: '400px' }"
                    (onChange)="
                      handleSelectedIssue($event.value, customIndex, issue)
                    "
                  ></p-dropdown>
                </div>
                <div *ngIf="issue.issueName" style="margin-top: 3px">
                  <label class="label">Sub Issues</label>
                  <p-multiSelect
                    #subIssueMultiSelects
                    [options]="subIssueListMap[issue.issueId]"
                    optionLabel="label"
                    placeholder="Select sub issues"
                    [style]="{
                      width: '400px',
                      'background-color': '#4A4848',
                      color: '#ffffff'
                    }"
                    (onChange)="
                      handleSelectedSubIssues($event.value, customIndex)
                    "
                    >Select sub issue</p-multiSelect
                  >
                </div>
                <div
                  *ngIf="customIndex > 0"
                  (click)="removeIssue(customIndex)"
                  style="cursor: pointer"
                >
                <i class="fa fa-times img-src" style="color: red;" alt="remove"></i>
                </div>
              </div>
              <div class="add-issue" (click)="addIssue(customIndex)">
                <i class="fa fa-plus img-src" alt="add"></i>
                <div>Add Issue</div>
              </div>

             
            </form>
          </div>
        </div>

        <app-form-renderer
          [formConfig]="currentlyActiveForm"
          (formSubmitHandler)="submitCurrentForm($event)"
        >
        </app-form-renderer>

        <button
          [disabled]="activeIndex <= 0"
          (click)="activeIndexChangeHandler(activeIndex - 1)"
          label="Back"
          class="ui-button-secondary backButton"
          pButton
        ></button>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoadComplete">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="stack">
      <p class="head">
        <ng-container>
          <i class="pi pi-check" *ngIf="message.severity == 'success'"></i>
          <i class="pi pi-info-circle" *ngIf="message.severity == 'info'"></i>
        </ng-container>
        &nbsp;
        {{ message.summary }}
      </p>
      <p *ngIf="message.detail" class="detail">
        {{ message.detail }}
      </p>
      <ng-container *ngIf="message.data">
        <p *ngFor="let detail of message.data" class="detail">
          {{ detail }}
        </p>
      </ng-container>
    </div>
  </ng-template>
</p-toast>
<p-dialog
  style="text-align: center"
  [(visible)]="showPopup"
  [modal]="true"
  [responsive]="true"
  [style]="{
    maxWidth: '30%',
    maxHeight: '80%',
    border: '2px solid black',
    borderRadius: '20px'
  }"
>
  <p style="text-align: center">
    {{ ticketCategory }}
  </p>
  <li
    style="list-style-type: none"
    *ngFor="let data of ticketList; let i = index"
  >
    <span>{{ data }}</span>
  </li>
  <p>already exists for {{ vehicleNumber }}</p>
  <br />
  <p>{{ showMessage }}</p>
  <br />

  <button
    label="Go to latest ticket"
    (click)="showTicketDetails()"
    style="text-align: center"
    class="popUpButton"
  >
    Go to latest ticket
  </button>
</p-dialog>
<p-dialog
  style="text-align: center"
  [(visible)]="showSecondPopup"
  [modal]="true"
  [responsive]="true"
  [style]="{
    maxWidth: '30%',
    maxHeight: '80%',
    border: '2px solid black',
    borderRadius: '20px'
  }"
>
  <p style="text-align: center">
    {{ ticketCategory }}<br /><br />
    <li
      style="list-style-type: none"
      *ngFor="let data of vehicleList; let i = index"
    >
      <span> {{ i + 1 }}. {{ data }}</span>
    </li>
  </p>
</p-dialog>
<p-dialog
  header="Select another issue!"
  [(visible)]="displayDialog"
  [modal]="true"
  [responsive]="true"
  [transitionOptions]="'90ms'"
  [style]="{
    maxWidth: '20%',
    maxHeight: '80%',
    border: '2px solid black',
    borderRadius: '20px'
  }"
>
  <p>{{ issueOptionSelected }} is already selected</p>
</p-dialog>
