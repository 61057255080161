import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../models/api.constants';
import { Headers } from '../models/api.headers';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private http: HttpClient,private cookieService: CookieService) { }
  requestThisUrl(url): Promise<{}> {
    return this.http.get<{}>(url, { headers: Headers.headers }).toPromise();
  }
  getAllTickets(): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/ticket',{ headers: Headers.headers });
  }

  getOneTickets(ticketId): Observable<{}> {
    return this.http.get<{}>(Urls.APP_URL + '/api/v1/ticket/'+ticketId);
  }

  closeTicket(ticketId): Observable<{}> {
    return this.http.delete<{}>(Urls.APP_URL + '/api/v1/ticket/'+ticketId);
  }

  saveTicket(ticket): Observable<{}> {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/ticket',ticket, { headers: Headers.headers });
  }

  updateTicket(ticket): Observable<{}> {
    return this.http.put<{}>(Urls.APP_URL + '/api/v1/ticket', ticket, {headers: Headers.headers});
  }
  formConfigTicket(name): Observable<{}> {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/form/get-active-form',
    { name, project: 'ticket-management-system' },
      {headers: Headers.headers}
    );
  }
  pushFormData(formData): Observable<{}>{
    return this.http.post('https://api.imgbb.com/1/upload?key=5ac778a7f45ba948009d311608f89213', formData);
  }
  updateRepplaceThisForm(formData): Observable<any> {
    return this.http.put(Urls.APP_URL + '/api/v1/form/update-replace', formData);
  }
  getFormById(formId): Observable<any> {
    let url='/api/v1/form/get-active-form-by-id?formId=';
    return this.http.get<{}>(Urls.APP_URL + url + formId, {headers: Headers.headers} );
   }
   createTicket(ticketFormData): Observable<{}> {
    let url='/api/v1/tickets/details/create'
    return this.http.post(Urls.APP_URL + url, ticketFormData);
  }
  fetchCurrentlyActiveForm(project, category, name, isRoot?: boolean): Observable<any> {
    const query: any = {project, category, name};
    if (typeof isRoot === 'boolean') {
      query.isRoot = isRoot;
    }
    return this.http.post(Urls.APP_URL+ '/api/v1/form/get-active-form', query);
  }

}
