export function pick(oldObject, propsArray) {
  const newObject = {};
  for (let key of propsArray) {
    if (key in oldObject) {
      newObject[key] = oldObject[key];
    }
  }
  return newObject;
}
function sanitizeFieldOption(config, fieldOptions) {
  config.required = false;
  config.multiple = false;
  config.disabled = false;
  config.validations = {};
  for (let field of fieldOptions) {
    config[field] = true;
    if (field == 'required') {
      config.validations = {
        required: true,
      };
    }
    if (field === 'multiple' && config.html === 'select') {
      config.htmlType = 'multiselect';
    }
  }
  delete config.fieldOptions;
}
const sanitizeHtmlInputFields = ['htmlType', 'name', 'label', 'placeholder', 'value', 'fieldOptions', 'options','elementType', 'isConfigActive'];
export function sanitizeHtmlInput(config) {
  const props: any = pick(config, sanitizeHtmlInputFields);
  props.html = 'input';
  if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  }
  if (props.options && Array.isArray(props.options)) {
    props.options = constructOption(props.options);
  }
  return props;
}
const sanitizeHtmlInputGroupCheckboxFields = ['name', 'label', 'placeholder', 'options', 'fieldOptions', 'elementType', 'isConfigActive'];
export function sanitizeHtmlInputGroupCheckbox(config) {
  const props: any = pick(config, sanitizeHtmlInputGroupCheckboxFields);
  props.html = 'inputGroup';
  props.htmlType = 'checkbox';
  if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  }
  if (props.options && Array.isArray(props.options)) {
    props.options = constructOption(props.options);
  }
  return props;
}
const sanitizeHtmlInputGroupRadioFields = ['name', 'label', 'placeholder', 'options', 'fieldOptions', 'elementType', 'isConfigActive'];
export function sanitizeHtmlInputGroupRadio(config) {
  const props: any = pick(config, sanitizeHtmlInputGroupRadioFields);
  props.html = 'inputGroup';
  props.htmlType = 'radio';
  if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  }
  if (props.options && Array.isArray(props.options)) {
    props.options = constructOption(props.options);
  }
  return props;
}
const sanitizeHtmlInputFileFields = ['name', 'label' ,'options', 'fieldOptions','elementType','isConfigActive'];
export function sanitizeHtmlInputFile(config) {
  const props: any = pick(config, sanitizeHtmlInputFileFields);
  props.html = 'inputFile';
  props.htmlType = 'file';
  console.log(props.options);
  
  if (props.options) {
    sanitizeFieldOption(props, props.options);
  } 
 if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  } 

 console.log(props);
 
  return props;
}
function constructOption(options) {
  return options.map(({ value, label, className, }) => {
    return { label, value };
  });
}
const sanitizeHtmlInputSelectDropdownFields = ['name', 'label', 'fieldOptions', 'options', 'elementType','isConfigActive', 'url', 'optionFieldLabel', 'optionFieldValue'];
export function sanitizeHtmlInputSelectDropdown(config) {
  const props: any = pick(config, sanitizeHtmlInputSelectDropdownFields);
  props.html = 'select';
  props.htmlType = 'dropdown';
  if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  }
  if (!props.url) {
    if (props.options && Array.isArray(props.options)) {
      props.options = constructOption(props.options);
    }
  }
  return props;
}
const sanitizeHtmlInputSelectMultiSelectFields = ['name', 'label', 'fieldOptions', 'options', 'elementType','isConfigActive', 'url', 'optionFieldLabel', 'optionFieldValue'];
export function sanitizeHtmlInputSelectMultiSelect(config) {
  const props: any = pick(config, sanitizeHtmlInputSelectMultiSelectFields);
  props.html = 'select';
  props.htmlType = 'multiselect';
  props.multiple = true;
  if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  }
  if (!props.url) {
    if (props.options && Array.isArray(props.options)) {
      props.options = constructOption(props.options);
    }
  }

  return props;
}
const sanitizeHtmlInputDateTimeFields = ['name', 'label', 'fieldOptions', 'options', 'htmlType', 'elementType', 'isConfigActive'];
export function sanitizeHtmlInputDateTime(config) {
  const props: any = pick(config, sanitizeHtmlInputDateTimeFields);
  props.html = 'dateTiem';
  const type = !props.htmlType ? 'datetime' : props.htmlType;
  props.htmlType = type;
  if (props.fieldOptions) {
    sanitizeFieldOption(props, props.fieldOptions);
  }
  if (props.options && Array.isArray(props.options)) {
    props.options = constructOption(props.options);
  }
  console.log(props, "pro");
  
  return props;
}

export function sanitizeElementConfig(html, element) {
  switch (html) {
    case 'input': {
      return sanitizeHtmlInput(element);
    }
    case 'inputGroupCheckbox': {
      return sanitizeHtmlInputGroupCheckbox(element);
    }
    case 'inputGroupRadio': {
      return sanitizeHtmlInputGroupRadio(element);
    }
    case 'inputFile': {
      return sanitizeHtmlInputFile(element);
    }
    case 'selectDropdown':
      return sanitizeHtmlInputSelectDropdown(element);
    case 'selectMultiselect':
      return sanitizeHtmlInputSelectMultiSelect(element);
    case 'dateTime':
      return sanitizeHtmlInputSelectMultiSelect(element);
  }
}
