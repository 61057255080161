import { DbUsersService } from 'src/app/services/db-users.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { TicketViewService } from 'src/app/services/ticket-view.service';
import { SelectItem } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Urls } from '../../../models/api.constants';
import { HubService } from 'src/app/services/hub.service';
import { LocalService } from 'src/app/services/local.service';


@Component({
  selector: 'app-daywise-tickets',
  templateUrl: './daywise-tickets.component.html',
  styleUrls: ['./daywise-tickets.component.css']
})
export class DaywiseTicketsComponent implements OnInit {
  @Output() showCalander: EventEmitter<boolean> =   new EventEmitter();
  @Input()  selectedDate:any;
  skip = 0;
  limit = 10;
 
  isLoadComplete = false;
  allTicketsBasicData = [
  ];
  isDetailViewVisible;
  ticketId;
  totalRecords = 0;
  ticketType: SelectItem[];
  selectedActiveTicketState;
  projection = 'min';
  currentUserSsoId = this.cookieService.get('ssoId');
  query = null;
  // filters
  // active state

  // Action
  selectedAssignedForAction: any[] = [];
  assignedForAction: SelectItem[] = [
    { label: 'Abcd', value: '119' }
  ];
  // View
  selectedAssignedForView: any[] = [];
  assignedForView: SelectItem[] = [];
  usersList: SelectItem[];
  isActive = null;
  activeState = [
    { label: 'All', value: null },
    { label: 'Open', value: true },
    { label: 'Close', value: false }
  ];
  search = '';
  hubList = [];

  timezone;

  searchWithFilters(e = { first: 0, rows: 10 }) {
    this.skip = e.first;
    this.limit = e.rows;
    this.isLoadComplete = false;
    const query = this.makequery();
    return this.fetchSearchQuery(query);
  }
  fetchSearchQuery(query = { skip: 0, limit: 10 }) {
    
    this.ticketViewService.loadTicketsWithSpecificDateOfService(query)
      .subscribe(({ code, response = { count: 0, results: [] }, message }: any) => {
        const results = response.results;
        for (let ticket of results) {
          const { assignedForAction } = ticket;
          if (assignedForAction != undefined)
            ticket.assignedForActionAlias = assignedForAction.filter(({ ssoId }) => ssoId != '119');
        }
        this.allTicketsBasicData = results;
        this.totalRecords = response.count;
        if (code !== 200) {
          return alert(message);
        }
        this.isLoadComplete = true;
      });
  }
  constructor(
    private ticketViewService: TicketViewService,
    private cookieService: CookieService,
    private router: Router,
    private userService: DbUsersService,
    private hubService: HubService,
    private localeService: LocalService,
  ) {
    this.isActive = null;
    this.timezone = localeService.getTimeZone();
  }
  makequery() {
    let query: any = {};
    query.date = this.localeService.getDate(this.selectedDate).getTime()/1000;
    if (typeof this.isActive === 'boolean') {
      query.isActive = this.isActive;
    }
    if (this.selectedAssignedForAction && this.selectedAssignedForAction.length > 0) {
      query.assignedForAction = this.selectedAssignedForAction;
    }
    if (this.selectedAssignedForView && this.selectedAssignedForView.length > 0) {
      query.assignedForView = this.selectedAssignedForView;
    }
    const search = this.search && this.search.trim();
    if (search) {
      query.filters = {
        search,
      };
    }
    if (this.projection) {
      query.projection = this.projection || 'min';
    }
    query.skip = this.skip;
    query.limit = this.limit;
    
    return query;
  }
  ngOnInit() {
    
    let currentURL = this.router.url;
    this.cookieService.set('routeLocation', currentURL, null, '/', Urls.COOKIE_URL,null,"Lax");
    this.isDetailViewVisible = false;
    this.userService.getAllUsers().subscribe(({ code, response = [], message }: any) => {
      if (code !== 200) {
        return alert(message);
      }
      this.usersList = response.map(({ name: label, ssoId: value }) => ({ label, value }));
    });
    this.hubService.getAllHubs().subscribe(({ response = [] }: any) => {
      this.hubList = response;
    })
   
  }

  ngOnDestroy() {
    this.cookieService.delete('routeLocation', '/', Urls.COOKIE_URL);
  }

  getDetailViewForTicket(ticketId) {
    this.ticketId = ticketId;
    this.isDetailViewVisible = true;
  }

  showCal(){
    
    this.showCalander.emit(true);
  }

}
